import React, { useState, useContext } from 'react'
import { GlobalContext } from '../../context/Global'
import styles from './tabinputs.module.scss'

import { APIService, LocationService } from '../../services'
import BerlinCodes from '../../assets/BerlinZipCodes.json'

import { Errors } from '../../components/Errors'
import { Tabs } from '../../components/Tabs'
import { Button } from '../../components/Buttons'
import { LocationInput } from '../../components/Inputs'
import { DatePicker } from '../../components/Inputs'


export const TabInputs = () => {

  const {
    analysisType,
    setErrors,
    setNPGEOData,
    zipData,
    from, updateFrom,
    to, updateTo,
    setFilteredFrom,
    setFilteredTo,
    setSessionID,
    setTripID,
    bodyHeight,
    activeTab, setActiveTab,
  } = useContext(GlobalContext)

  const [loading, setLoading] = useState(false)

  const isTrip = analysisType === 'trip'

  const errorMessages = {
    noZipCode: 'Bitte gib eine gültige Postleitzahl ein!',
    invalidZipCode: 'Wir konnten einem Deiner Orte leider keine eindeutige Postleitzahl zuordnen. Überprüfe Deine Eingabe oder wähle einen anderen Ort in der Nähe!',
    invalidDate: 'Bitte gib ein korrektes Datum ein!',
  }

  const checkInputsAndGoNext = async () => {
    setLoading(true)
    let errors = []
    let rkiData = []

    try {
      rkiData = await APIService.getRKIData()
      setNPGEOData(rkiData)
    } catch (error) {
      console.error(error)
      errors.push('Fehler bei der RKI-Schnittstelle')
      setLoading(false)
    }

    if (isTrip) {
      if ((!from.zip.length > 0) || (!to.zip.length > 0)) {
        errors.push(errorMessages.invalidZipCode)
      }

      if ((!from.date) || (!to.date)) {
        errors.push(errorMessages.invalidDate)
      }
    } else {
      if ((!from.zip.length > 0)) {
        errors.push(errorMessages.invalidZipCode)
      }
    }

    if (errors.length > 0) {
      setErrors(errors)
      setLoading(false)
      return;
    }

    const zips = await LocationService.checkZips(isTrip, from, to, zipData, BerlinCodes, rkiData, setFilteredFrom, setFilteredTo, setErrors)
    const filteredFrom = zips[0]
    const filteredTo = zips[1]

    const partnerInfosFrom = await APIService.getPartnerInfoById(from.placeID, filteredFrom[0]?.attributes.BL, filteredFrom[0]?.attributes.county)
    let combinedInfosFrom = []

    if (partnerInfosFrom.data != null) {
      for (const partnerInfo of partnerInfosFrom.data) {
        if (partnerInfo.active) {
          combinedInfosFrom.push(partnerInfo.messages)
        }
      }
      updateFrom({ ...from, info: combinedInfosFrom })
    }

    if (isTrip) {
      const partnerInfosTo = await APIService.getPartnerInfoById(to.placeID, filteredTo[0]?.attributes.BL, filteredTo[0]?.attributes.county)
      let combinedInfosTo = []

      if (partnerInfosTo.data != null) {
        for (const partnerInfo of partnerInfosTo.data) {
          if (partnerInfo.active) {
            combinedInfosTo.push(partnerInfo.messages)
          }
        }
        updateTo({ ...to, info: combinedInfosTo })
      }
    }

    try {
      const res = await APIService.addTrip(analysisType, from, to, filteredFrom, filteredTo, setSessionID, setTripID)
      setLoading(false)
      if ((res.status === 201 || res.status === 200) && res.data._id != null) {
        setActiveTab(activeTab + 1)
      }
    } catch (error) {
      console.error(error)
      setErrors(['Irgendwas ist schiefgelaufen. Versuche es noch einmal.'])
      setLoading(false)
    }

  }

  return (
    <div className={styles.wrapper}>

      <Tabs />

      <Errors />

      <div className={styles.inputs}>
        <LocationInput />
        {isTrip && <DatePicker />}
      </div>

      <Button loading={loading} text={isTrip ? 'Reise analysieren' : 'Standort analysieren'} onClick={checkInputsAndGoNext} />

      <div className='expanding-iframe-fix' style={{ height: bodyHeight, pointerEvents: 'none', visibility: 'hidden' }}></div>

    </div>
  )
}