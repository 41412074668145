import { ACTIONS } from "./actions";

const GlobalReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_ANALYSIS_TYPE:
      return {
        ...state,
        analysisType: action.payload
      }


    case ACTIONS.SET_NPGEO_DATA:
      return {
        ...state,
        npgeoData: action.payload,
        npgeoDataLoading: false
      }


    case ACTIONS.UPDATE_FROM:
      return {
        ...state,
        from: action.payload
      }
    case ACTIONS.UPDATE_TO:
      return {
        ...state,
        to: action.payload
      }


    case ACTIONS.SET_FILTERED_FROM:
      return {
        ...state,
        filteredFrom: action.payload
      }
    case ACTIONS.SET_FILTERED_TO:
      return {
        ...state,
        filteredTo: action.payload
      }


    case ACTIONS.SET_EMAIL:
      return {
        ...state,
        email: action.payload
      }

    case ACTIONS.SET_SESSION_ID:
      return {
        ...state,
        sessionID: action.payload
      }
    case ACTIONS.SET_TRIP_ID:
      return {
        ...state,
        tripID: action.payload
      }


    case ACTIONS.SET_BODY_HEIGHT:
      return {
        ...state,
        bodyHeight: action.payload
      }

    case ACTIONS.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      }


    case ACTIONS.SET_ERRORS:
      return {
        ...state,
        errors: action.payload
      }

    default:
      return state;
  }
}

export default GlobalReducer