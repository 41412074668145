export const ACTIONS = {
  SET_ANALYSIS_TYPE: 'SET_ANALYSIS_TYPE',

  SET_NPGEO_DATA: 'SET_NPGEO_DATA',

  UPDATE_FROM: 'UPDATE_FROM',
  UPDATE_TO: 'UPDATE_TO',

  SET_FILTERED_FROM: 'SET_FILTERED_FROM',
  SET_FILTERED_TO: 'SET_FILTERED_TO',

  SET_EMAIL: 'SET_EMAIL',
  SET_SESSION_ID: 'SET_SESSION_ID',
  SET_TRIP_ID: 'SET_TRIP_ID',

  SET_BODY_HEIGHT: 'SET_BODY_HEIGHT',

  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',

  SET_ERRORS: 'SET_ERRORS',
}