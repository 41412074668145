import React from 'react'
import styles from './resultbox.module.scss'

export const ResultBox = ({ data }) => {

    return (
        <div className={styles.box} style={{
            backgroundColor:
                data.cases7_per_100k > 100 ? '#a5081e' :
                    data.cases7_per_100k > 50 ? '#d40035' :
                        data.cases7_per_100k > 35 ? '#da9e45' :
                            '#4eb769'
        }}>
            <h3>{data.location.name.split(',')[0]}</h3>

            <table className={styles.table}>
                <tbody>
                    <tr>
                        <th scope="row">PLZ:</th>
                        <td>{data.location.zip}</td>
                    </tr>
                    <tr>
                        <th scope="row">Kreis:</th>
                        <td>{data.county}</td>
                    </tr>
                    <tr>
                        <th scope="row">Bundesland:</th>
                        <td>{data.BL}</td>
                    </tr>
                    <tr>
                        <th scope="row">Inzidenzwert:</th>
                        <td><strong>{data.cases7_per_100k.toFixed(2)}</strong></td>
                    </tr>
                    <tr>
                        <th scope="row">Fälle insgesamt:</th>
                        <td>{data.cases}</td>
                    </tr>
                    <tr>
                        <th scope="row">Letztes Update:</th>
                        <td>{data.last_update.split(',')[0]}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
