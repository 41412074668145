export const NUTS = [
  {
    "NUTS3": "DEA2D",
    "CODE": "52156"
  },
  {
    "NUTS3": "DE121",
    "CODE": "76534"
  },
  {
    "NUTS3": "DE121",
    "CODE": "76532"
  },
  {
    "NUTS3": "DE121",
    "CODE": "76530"
  },
  {
    "NUTS3": "DE121",
    "CODE": "76532"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76189"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76187"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76185"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76149"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76135"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76133"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76199"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76137"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76131"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76139"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76227"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76228"
  },
  {
    "NUTS3": "DE122",
    "CODE": "76229"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76287"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76316"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76344"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76275"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76351"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76706"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76661"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76359"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76297"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76676"
  },
  {
    "NUTS3": "DE123",
    "CODE": "68794"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76337"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76307"
  },
  {
    "NUTS3": "DE123",
    "CODE": "68753"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76356"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76327"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76689"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76646"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76646"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76707"
  },
  {
    "NUTS3": "DE123",
    "CODE": "75045"
  },
  {
    "NUTS3": "DE124",
    "CODE": "77839"
  },
  {
    "NUTS3": "DE124",
    "CODE": "77836"
  },
  {
    "NUTS3": "DE124",
    "CODE": "77833"
  },
  {
    "NUTS3": "DE124",
    "CODE": "77815"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76547"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76549"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76473"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76437"
  },
  {
    "NUTS3": "DE124",
    "CODE": "77830"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76479"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76477"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76474"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76470"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76596"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76467"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76448"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76456"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76461"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76476"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76593"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76571"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76599"
  },
  {
    "NUTS3": "DE124",
    "CODE": "76597"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68804"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68766"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68782"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68775"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68809"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68799"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68723"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68549"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "76332"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75337"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75323"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "72226"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75335"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "72213"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75389"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75339"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75334"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75210"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75305"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75196"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72270"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "77776"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72250"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72275"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72290"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72297"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72293"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72280"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72285"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72294"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72178"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72296"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72296"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72160"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72299"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79395"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79424"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79206"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79379"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79235"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79395"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79258"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79423"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79426"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79427"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79241"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79423"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79189"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79410"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79291"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79295"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79282"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79227"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79219"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79268"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79356"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79288"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79238"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79224"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79292"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79232"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79244"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79285"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79283"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79294"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79299"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79249"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79280"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79289"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79194"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79254"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79199"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79286"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79252"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79256"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79868"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79271"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79874"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79856"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79274"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79859"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79853"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79822"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79871"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79877"
  },
  {
    "NUTS3": "DE132",
    "CODE": "79843"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79361"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79369"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79367"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79346"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79365"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79362"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79353"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79341"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79359"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79336"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79331"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79364"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79279"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79276"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79312"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79364"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79211"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79348"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79350"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79183"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79261"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79215"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79297"
  },
  {
    "NUTS3": "DE133",
    "CODE": "79263"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77977"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77966"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77963"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77975"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77974"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77743"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77955"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77972"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77933"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77971"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77694"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77948"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77731"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77746"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77866"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77749"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77656"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77960"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77978"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77652"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77767"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77654"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77791"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77799"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77871"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77855"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77797"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77770"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77723"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77781"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77790"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77704"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77716"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77736"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77787"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77880"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77796"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77876"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77794"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77887"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77784"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77886"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77728"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77756"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77883"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77889"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77793"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77709"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77740"
  },
  {
    "NUTS3": "DE134",
    "CODE": "77880"
  },
  {
    "NUTS3": "DE134",
    "CODE": "78132"
  },
  {
    "NUTS3": "DE134",
    "CODE": "72250"
  },
  {
    "NUTS3": "DE135",
    "CODE": "77761"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78144"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78730"
  },
  {
    "NUTS3": "DE135",
    "CODE": "77773"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78713"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78733"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78739"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78737"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78664"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78655"
  },
  {
    "NUTS3": "DE135",
    "CODE": "72175"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78658"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78727"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78662"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78652"
  },
  {
    "NUTS3": "DE135",
    "CODE": "72172"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78628"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78667"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78736"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78148"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78136"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78120"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78141"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78147"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78098"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78199"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78112"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78052"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78166"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78089"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78089"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78052"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78166"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78126"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78089"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78089"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78089"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78087"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78048"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78050"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78086"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78052"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78183"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78078"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78176"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78054"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78073"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78083"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78056"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79588"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79415"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79418"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79576"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79400"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79591"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79592"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79589"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79597"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79595"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79595"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79539"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79639"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79599"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79541"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79540"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79594"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79429"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79618"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79585"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79692"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79689"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79650"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79669"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79688"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79739"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79677"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79695"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79686"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79674"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79685"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79694"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79664"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79713"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79736"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79737"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79682"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79730"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79872"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79733"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79725"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79837"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79875"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79774"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79761"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79809"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79862"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79804"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79865"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79777"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79848"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79790"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79787"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79780"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79793"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79801"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79771"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79805"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79879"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79802"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79798"
  },
  {
    "NUTS3": "DE13A",
    "CODE": "79807"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68307"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68169"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68159"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68199"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68305"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68163"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68161"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68167"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68165"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68309"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68219"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68259"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68239"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79112"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79111"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79110"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79108"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79114"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79115"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79100"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79106"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79110"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79115"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79098"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79104"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79102"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79117"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79106"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79106"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79106"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79106"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79117"
  },
  {
    "NUTS3": "DE131",
    "CODE": "79108"
  },
  {
    "NUTS3": "DE502",
    "CODE": "27572"
  },
  {
    "NUTS3": "DE502",
    "CODE": "27580"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64347"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64319"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64331"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64665"
  },
  {
    "NUTS3": "DE736",
    "CODE": "35116"
  },
  {
    "NUTS3": "DE736",
    "CODE": "35088"
  },
  {
    "NUTS3": "DE922",
    "CODE": "49356"
  },
  {
    "NUTS3": "DE922",
    "CODE": "49448"
  },
  {
    "NUTS3": "DE922",
    "CODE": "49459"
  },
  {
    "NUTS3": "DE922",
    "CODE": "49457"
  },
  {
    "NUTS3": "DE922",
    "CODE": "49459"
  },
  {
    "NUTS3": "DE922",
    "CODE": "49406"
  },
  {
    "NUTS3": "DE922",
    "CODE": "49453"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27239"
  },
  {
    "NUTS3": "DE922",
    "CODE": "49419"
  },
  {
    "NUTS3": "DE942",
    "CODE": "26723"
  },
  {
    "NUTS3": "DE942",
    "CODE": "26721"
  },
  {
    "NUTS3": "DE942",
    "CODE": "26725"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49767"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49733"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49744"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49762"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49716"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49779"
  },
  {
    "NUTS3": "DE949",
    "CODE": "26907"
  },
  {
    "NUTS3": "DE949",
    "CODE": "26906"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49808"
  },
  {
    "NUTS3": "DE949",
    "CODE": "26899"
  },
  {
    "NUTS3": "DE949",
    "CODE": "26892"
  },
  {
    "NUTS3": "DE949",
    "CODE": "48488"
  },
  {
    "NUTS3": "DE949",
    "CODE": "48499"
  },
  {
    "NUTS3": "DE949",
    "CODE": "26871"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49811"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49809"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49777"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49740"
  },
  {
    "NUTS3": "DE949",
    "CODE": "26909"
  },
  {
    "NUTS3": "DE949",
    "CODE": "48480"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49844"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49838"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49832"
  },
  {
    "NUTS3": "DE949",
    "CODE": "26909"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49751"
  },
  {
    "NUTS3": "DE949",
    "CODE": "26903"
  },
  {
    "NUTS3": "DE949",
    "CODE": "26904"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49774"
  },
  {
    "NUTS3": "DE949",
    "CODE": "26897"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49770"
  },
  {
    "NUTS3": "DE949",
    "CODE": "49757"
  },
  {
    "NUTS3": "DE949",
    "CODE": "26901"
  },
  {
    "NUTS3": "DE94F",
    "CODE": "49434"
  },
  {
    "NUTS3": "DE94F",
    "CODE": "49413"
  },
  {
    "NUTS3": "DE94F",
    "CODE": "49451"
  },
  {
    "NUTS3": "DE94F",
    "CODE": "49456"
  },
  {
    "NUTS3": "DE94F",
    "CODE": "49401"
  },
  {
    "NUTS3": "DE94F",
    "CODE": "49393"
  },
  {
    "NUTS3": "DE94F",
    "CODE": "49439"
  },
  {
    "NUTS3": "DE94F",
    "CODE": "49377"
  },
  {
    "NUTS3": "DE94F",
    "CODE": "49429"
  },
  {
    "NUTS3": "DE94F",
    "CODE": "49424"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41179"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41169"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41189"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41069"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41068"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41063"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41239"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41069"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41061"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41061"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41199"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41066"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41065"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41236"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41238"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41061"
  },
  {
    "NUTS3": "DEA15",
    "CODE": "41063"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47559"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47574"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47533"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47652"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "46446"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47551"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47624"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47623"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47608"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47638"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47589"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47546"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47625"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47627"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47626"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47669"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "46459"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47647"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47661"
  },
  {
    "NUTS3": "DEA1B",
    "CODE": "47509"
  },
  {
    "NUTS3": "DEA24",
    "CODE": "51371"
  },
  {
    "NUTS3": "DEA24",
    "CODE": "51373"
  },
  {
    "NUTS3": "DEA24",
    "CODE": "51379"
  },
  {
    "NUTS3": "DEA24",
    "CODE": "51381"
  },
  {
    "NUTS3": "DEA24",
    "CODE": "51377"
  },
  {
    "NUTS3": "DEA24",
    "CODE": "51375"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "42799"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "51399"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "51467"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "51469"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "51427"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "51519"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "51465"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "51429"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "51503"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "42929"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "51515"
  },
  {
    "NUTS3": "DEA2B",
    "CODE": "51491"
  },
  {
    "NUTS3": "DEA35",
    "CODE": "48720"
  },
  {
    "NUTS3": "DEA35",
    "CODE": "48653"
  },
  {
    "NUTS3": "DEA35",
    "CODE": "48249"
  },
  {
    "NUTS3": "DEA35",
    "CODE": "48727"
  },
  {
    "NUTS3": "DEA35",
    "CODE": "48301"
  },
  {
    "NUTS3": "DEA35",
    "CODE": "59348"
  },
  {
    "NUTS3": "DEA35",
    "CODE": "59399"
  },
  {
    "NUTS3": "DEA35",
    "CODE": "48329"
  },
  {
    "NUTS3": "DEA35",
    "CODE": "48308"
  },
  {
    "NUTS3": "DEA35",
    "CODE": "59394"
  },
  {
    "NUTS3": "DEA35",
    "CODE": "59387"
  },
  {
    "NUTS3": "DEA43",
    "CODE": "32139"
  },
  {
    "NUTS3": "DEA43",
    "CODE": "32289"
  },
  {
    "NUTS3": "DEA43",
    "CODE": "32257"
  },
  {
    "NUTS3": "DEA43",
    "CODE": "32130"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58135"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58089"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58091"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58099"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58095"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58097"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58089"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58093"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58095"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58093"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58099"
  },
  {
    "NUTS3": "DEA53",
    "CODE": "58119"
  },
  {
    "NUTS3": "DEA59",
    "CODE": "57489"
  },
  {
    "NUTS3": "DEA59",
    "CODE": "57482"
  },
  {
    "NUTS3": "DEA59",
    "CODE": "57439"
  },
  {
    "NUTS3": "DEA59",
    "CODE": "57462"
  },
  {
    "NUTS3": "DEA59",
    "CODE": "57413"
  },
  {
    "NUTS3": "DEA59",
    "CODE": "57399"
  },
  {
    "NUTS3": "DEA59",
    "CODE": "57368"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55608"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55606"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55619"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55606"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55608"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55629"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55618"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55627"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55566"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55621"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55566"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55569"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55606"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55592"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55568"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55595"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55596"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55590"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55444"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "67827"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "67829"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55571"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55442"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55595"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55452"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55585"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "67824"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "67826"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55593"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55583"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55585"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55545"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55450"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55543"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55452"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55546"
  },
  {
    "NUTS3": "DEB14",
    "CODE": "55559"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56766"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56826"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56825"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56864"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56828"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56759"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56761"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56823"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56814"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56761"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56859"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56858"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56814"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56814"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56812"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56862"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56850"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56867"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56856"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56818"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56820"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56821"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56814"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56754"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56858"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56829"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56867"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56865"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56859"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56814"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56253"
  },
  {
    "NUTS3": "DEB1C",
    "CODE": "56254"
  },
  {
    "NUTS3": "DEB31",
    "CODE": "67227"
  },
  {
    "NUTS3": "DEB37",
    "CODE": "66954"
  },
  {
    "NUTS3": "DEB37",
    "CODE": "66955"
  },
  {
    "NUTS3": "DEB37",
    "CODE": "66953"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76744"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76779"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76872"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76870"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76768"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76770"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76767"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76776"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "67378"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "67363"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "67361"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76751"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76764"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76761"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76756"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "67366"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76773"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76774"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76771"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "67368"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76777"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "67365"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "67360"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76726"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66500"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66484"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66497"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66501"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66484"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66503"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66507"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66957"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66917"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66509"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66989"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66504"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66957"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66506"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66987"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66919"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66989"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66987"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66969"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66976"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66978"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66996"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "67714"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66981"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "67715"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66957"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "67716"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "67718"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66994"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66999"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "76891"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66999"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "76848"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "66996"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "76887"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "76846"
  },
  {
    "NUTS3": "DEB3K",
    "CODE": "76848"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28777"
  },
  {
    "NUTS3": "DE501",
    "CODE": "27568"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28779"
  },
  {
    "NUTS3": "DE600",
    "CODE": "27499"
  },
  {
    "NUTS3": "DE600",
    "CODE": "27499"
  },
  {
    "NUTS3": "DE712",
    "CODE": "65931"
  },
  {
    "NUTS3": "DE712",
    "CODE": "65929"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60549"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60529"
  },
  {
    "NUTS3": "DE712",
    "CODE": "65936"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65201"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65199"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65195"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65197"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65193"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65187"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65203"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65183"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65185"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65207"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65189"
  },
  {
    "NUTS3": "DE714",
    "CODE": "55252"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65205"
  },
  {
    "NUTS3": "DE714",
    "CODE": "65191"
  },
  {
    "NUTS3": "DE714",
    "CODE": "55246"
  },
  {
    "NUTS3": "DE715",
    "CODE": "68647"
  },
  {
    "NUTS3": "DE715",
    "CODE": "68623"
  },
  {
    "NUTS3": "DE715",
    "CODE": "68642"
  },
  {
    "NUTS3": "DE715",
    "CODE": "68649"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64683"
  },
  {
    "NUTS3": "DE715",
    "CODE": "68519"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64653"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64625"
  },
  {
    "NUTS3": "DE717",
    "CODE": "65462"
  },
  {
    "NUTS3": "DE717",
    "CODE": "65474"
  },
  {
    "NUTS3": "DE717",
    "CODE": "65468"
  },
  {
    "NUTS3": "DE717",
    "CODE": "65428"
  },
  {
    "NUTS3": "DE717",
    "CODE": "64560"
  },
  {
    "NUTS3": "DE717",
    "CODE": "64589"
  },
  {
    "NUTS3": "DE717",
    "CODE": "64521"
  },
  {
    "NUTS3": "DE717",
    "CODE": "64584"
  },
  {
    "NUTS3": "DE717",
    "CODE": "64569"
  },
  {
    "NUTS3": "DE717",
    "CODE": "65479"
  },
  {
    "NUTS3": "DE717",
    "CODE": "64579"
  },
  {
    "NUTS3": "DE717",
    "CODE": "65451"
  },
  {
    "NUTS3": "DE717",
    "CODE": "64572"
  },
  {
    "NUTS3": "DE717",
    "CODE": "64546"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61276"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61479"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61279"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61389"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61462"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61250"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61440"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61267"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61476"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61350"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61273"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61449"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65817"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65239"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65719"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65439"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65779"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65830"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65795"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65812"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65835"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65843"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65824"
  },
  {
    "NUTS3": "DE71A",
    "CODE": "65760"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65391"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65385"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65321"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65366"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65375"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65388"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65307"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65347"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65329"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65326"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65346"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65399"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65343"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65345"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65232"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65344"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65396"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65510"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65527"
  },
  {
    "NUTS3": "DE71D",
    "CODE": "65529"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "35510"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35444"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35428"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35759"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35767"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35708"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35753"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35745"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35686"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35716"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35685"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35683"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35687"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35638"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35764"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35684"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35713"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35690"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35630"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35689"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35619"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35688"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35756"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35606"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35768"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35614"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35644"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35647"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35649"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35641"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35579"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35576"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35578"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35586"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35580"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35585"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35625"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35583"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35584"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35633"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35581"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65604"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65599"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65556"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65589"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65549"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65627"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65555"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65554"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65550"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65620"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65597"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65553"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65594"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65552"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65551"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65614"
  },
  {
    "NUTS3": "DE723",
    "CODE": "35794"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65611"
  },
  {
    "NUTS3": "DE723",
    "CODE": "35799"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65606"
  },
  {
    "NUTS3": "DE723",
    "CODE": "35781"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65618"
  },
  {
    "NUTS3": "DE723",
    "CODE": "35792"
  },
  {
    "NUTS3": "DE723",
    "CODE": "65520"
  },
  {
    "NUTS3": "DE723",
    "CODE": "35796"
  },
  {
    "NUTS3": "DE723",
    "CODE": "35789"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35236"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35719"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35239"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35216"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35080"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35232"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35075"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35102"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27639"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27612"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27628"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27607"
  },
  {
    "NUTS3": "DE936",
    "CODE": "28790"
  },
  {
    "NUTS3": "DE943",
    "CODE": "26131"
  },
  {
    "NUTS3": "DE943",
    "CODE": "26129"
  },
  {
    "NUTS3": "DE943",
    "CODE": "26127"
  },
  {
    "NUTS3": "DE943",
    "CODE": "26122"
  },
  {
    "NUTS3": "DE943",
    "CODE": "26121"
  },
  {
    "NUTS3": "DE943",
    "CODE": "26133"
  },
  {
    "NUTS3": "DE943",
    "CODE": "26125"
  },
  {
    "NUTS3": "DE943",
    "CODE": "26135"
  },
  {
    "NUTS3": "DE943",
    "CODE": "26123"
  },
  {
    "NUTS3": "DE944",
    "CODE": "49076"
  },
  {
    "NUTS3": "DE944",
    "CODE": "49078"
  },
  {
    "NUTS3": "DE944",
    "CODE": "49090"
  },
  {
    "NUTS3": "DE944",
    "CODE": "49082"
  },
  {
    "NUTS3": "DE944",
    "CODE": "49080"
  },
  {
    "NUTS3": "DE944",
    "CODE": "49088"
  },
  {
    "NUTS3": "DE944",
    "CODE": "49074"
  },
  {
    "NUTS3": "DE944",
    "CODE": "49084"
  },
  {
    "NUTS3": "DE944",
    "CODE": "49086"
  },
  {
    "NUTS3": "DE945",
    "CODE": "26388"
  },
  {
    "NUTS3": "DE945",
    "CODE": "26389"
  },
  {
    "NUTS3": "DE945",
    "CODE": "26386"
  },
  {
    "NUTS3": "DE945",
    "CODE": "26382"
  },
  {
    "NUTS3": "DE945",
    "CODE": "26384"
  },
  {
    "NUTS3": "DE946",
    "CODE": "26689"
  },
  {
    "NUTS3": "DE946",
    "CODE": "26655"
  },
  {
    "NUTS3": "DE946",
    "CODE": "26188"
  },
  {
    "NUTS3": "DE946",
    "CODE": "26160"
  },
  {
    "NUTS3": "DE946",
    "CODE": "26215"
  },
  {
    "NUTS3": "DE946",
    "CODE": "26180"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26571"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26571"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26736"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26506"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26759"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26548"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26529"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26524"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26624"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26632"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26532"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26553"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26579"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26605"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26607"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26603"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26629"
  },
  {
    "NUTS3": "DE947",
    "CODE": "26639"
  },
  {
    "NUTS3": "DE948",
    "CODE": "26683"
  },
  {
    "NUTS3": "DE948",
    "CODE": "49624"
  },
  {
    "NUTS3": "DE948",
    "CODE": "26676"
  },
  {
    "NUTS3": "DE948",
    "CODE": "49699"
  },
  {
    "NUTS3": "DE948",
    "CODE": "26169"
  },
  {
    "NUTS3": "DE948",
    "CODE": "49696"
  },
  {
    "NUTS3": "DE948",
    "CODE": "49688"
  },
  {
    "NUTS3": "DE948",
    "CODE": "49632"
  },
  {
    "NUTS3": "DE948",
    "CODE": "26219"
  },
  {
    "NUTS3": "DE948",
    "CODE": "49681"
  },
  {
    "NUTS3": "DE948",
    "CODE": "49661"
  },
  {
    "NUTS3": "DE948",
    "CODE": "49692"
  },
  {
    "NUTS3": "DE948",
    "CODE": "49685"
  },
  {
    "NUTS3": "DE94A",
    "CODE": "26434"
  },
  {
    "NUTS3": "DE94A",
    "CODE": "26441"
  },
  {
    "NUTS3": "DE94A",
    "CODE": "26419"
  },
  {
    "NUTS3": "DE94A",
    "CODE": "26486"
  },
  {
    "NUTS3": "DE94A",
    "CODE": "26340"
  },
  {
    "NUTS3": "DE94A",
    "CODE": "26452"
  },
  {
    "NUTS3": "DE94A",
    "CODE": "26345"
  },
  {
    "NUTS3": "DE94A",
    "CODE": "26316"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "49847"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "49824"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "49843"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "49849"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "49846"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "49828"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "48527"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "48455"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "48529"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "48531"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "49835"
  },
  {
    "NUTS3": "DE94B",
    "CODE": "48465"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26757"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26831"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26844"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26826"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26802"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26810"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26789"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26817"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26847"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26835"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26845"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26842"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26849"
  },
  {
    "NUTS3": "DE94C",
    "CODE": "26670"
  },
  {
    "NUTS3": "DE94D",
    "CODE": "26203"
  },
  {
    "NUTS3": "DE94D",
    "CODE": "26197"
  },
  {
    "NUTS3": "DE94D",
    "CODE": "26209"
  },
  {
    "NUTS3": "DE94D",
    "CODE": "27798"
  },
  {
    "NUTS3": "DE94D",
    "CODE": "27793"
  },
  {
    "NUTS3": "DE94D",
    "CODE": "27801"
  },
  {
    "NUTS3": "DE94D",
    "CODE": "27777"
  },
  {
    "NUTS3": "DE94D",
    "CODE": "27243"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49584"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49626"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49599"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49637"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49586"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49577"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49638"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49565"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49635"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49593"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49219"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49594"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49170"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49610"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49205"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49134"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49597"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49596"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49124"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49186"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49196"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49191"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49176"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49143"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49179"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49214"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49201"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49163"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49326"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49324"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49152"
  },
  {
    "NUTS3": "DE94E",
    "CODE": "49328"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "26349"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "26969"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "26939"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "26931"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "26936"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "26937"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "26954"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "27804"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "26935"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "26919"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "26954"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "27809"
  },
  {
    "NUTS3": "DE94H",
    "CODE": "26556"
  },
  {
    "NUTS3": "DE94H",
    "CODE": "26465"
  },
  {
    "NUTS3": "DE94H",
    "CODE": "26487"
  },
  {
    "NUTS3": "DE94H",
    "CODE": "26427"
  },
  {
    "NUTS3": "DE94H",
    "CODE": "26489"
  },
  {
    "NUTS3": "DE94H",
    "CODE": "26409"
  },
  {
    "NUTS3": "DE94H",
    "CODE": "26474"
  },
  {
    "NUTS3": "DE94H",
    "CODE": "26446"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40549"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40474"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40489"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40547"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40221"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40545"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40219"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40468"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40223"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40213"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40479"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40217"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40476"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40477"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40225"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40215"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40212"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40211"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40472"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40210"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40227"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40589"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40470"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40239"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40233"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40211"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40237"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40591"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40235"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40231"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40229"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40629"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40625"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40627"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40599"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40593"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40597"
  },
  {
    "NUTS3": "DEA11",
    "CODE": "40595"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47239"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47199"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47259"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47228"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47229"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47198"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47178"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47139"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47179"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47226"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47059"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47166"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47119"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47169"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47053"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47249"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47137"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47138"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47055"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47051"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47269"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47167"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47057"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47058"
  },
  {
    "NUTS3": "DEA12",
    "CODE": "47279"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45359"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45357"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45219"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45355"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45149"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45356"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45133"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45144"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45145"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45143"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45147"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45239"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45131"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45141"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45326"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45329"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45127"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45130"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45128"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45134"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45139"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45327"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45136"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45138"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45259"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45257"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45309"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45307"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45277"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45276"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45279"
  },
  {
    "NUTS3": "DEA13",
    "CODE": "45289"
  },
  {
    "NUTS3": "DEA14",
    "CODE": "47839"
  },
  {
    "NUTS3": "DEA14",
    "CODE": "47804"
  },
  {
    "NUTS3": "DEA14",
    "CODE": "47803"
  },
  {
    "NUTS3": "DEA14",
    "CODE": "47802"
  },
  {
    "NUTS3": "DEA14",
    "CODE": "47807"
  },
  {
    "NUTS3": "DEA14",
    "CODE": "47805"
  },
  {
    "NUTS3": "DEA14",
    "CODE": "47798"
  },
  {
    "NUTS3": "DEA14",
    "CODE": "47799"
  },
  {
    "NUTS3": "DEA14",
    "CODE": "47800"
  },
  {
    "NUTS3": "DEA14",
    "CODE": "47809"
  },
  {
    "NUTS3": "DEA14",
    "CODE": "47829"
  },
  {
    "NUTS3": "DEA16",
    "CODE": "45478"
  },
  {
    "NUTS3": "DEA16",
    "CODE": "45479"
  },
  {
    "NUTS3": "DEA16",
    "CODE": "45481"
  },
  {
    "NUTS3": "DEA16",
    "CODE": "45476"
  },
  {
    "NUTS3": "DEA16",
    "CODE": "45473"
  },
  {
    "NUTS3": "DEA16",
    "CODE": "45475"
  },
  {
    "NUTS3": "DEA16",
    "CODE": "45468"
  },
  {
    "NUTS3": "DEA16",
    "CODE": "45470"
  },
  {
    "NUTS3": "DEA16",
    "CODE": "45472"
  },
  {
    "NUTS3": "DEA17",
    "CODE": "46147"
  },
  {
    "NUTS3": "DEA17",
    "CODE": "46049"
  },
  {
    "NUTS3": "DEA17",
    "CODE": "46149"
  },
  {
    "NUTS3": "DEA17",
    "CODE": "46145"
  },
  {
    "NUTS3": "DEA17",
    "CODE": "46045"
  },
  {
    "NUTS3": "DEA17",
    "CODE": "46117"
  },
  {
    "NUTS3": "DEA17",
    "CODE": "46047"
  },
  {
    "NUTS3": "DEA17",
    "CODE": "46119"
  },
  {
    "NUTS3": "DEA18",
    "CODE": "42857"
  },
  {
    "NUTS3": "DEA18",
    "CODE": "42855"
  },
  {
    "NUTS3": "DEA18",
    "CODE": "42853"
  },
  {
    "NUTS3": "DEA18",
    "CODE": "42859"
  },
  {
    "NUTS3": "DEA18",
    "CODE": "42899"
  },
  {
    "NUTS3": "DEA18",
    "CODE": "42897"
  },
  {
    "NUTS3": "DEA19",
    "CODE": "42697"
  },
  {
    "NUTS3": "DEA19",
    "CODE": "42699"
  },
  {
    "NUTS3": "DEA19",
    "CODE": "42719"
  },
  {
    "NUTS3": "DEA19",
    "CODE": "42657"
  },
  {
    "NUTS3": "DEA19",
    "CODE": "42655"
  },
  {
    "NUTS3": "DEA19",
    "CODE": "42653"
  },
  {
    "NUTS3": "DEA19",
    "CODE": "42659"
  },
  {
    "NUTS3": "DEA19",
    "CODE": "42651"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42327"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42329"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42113"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42115"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42111"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42117"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42349"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42109"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42105"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42103"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42119"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42107"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42285"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42369"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42283"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42281"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42287"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42275"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42289"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42279"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42277"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42389"
  },
  {
    "NUTS3": "DEA1A",
    "CODE": "42399"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40880"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40885"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40878"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40882"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40789"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40883"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40699"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40721"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40764"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40724"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "42579"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40723"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "40822"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "42781"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "42489"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "42549"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "42551"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "42553"
  },
  {
    "NUTS3": "DEA1C",
    "CODE": "42555"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41363"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41352"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41517"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41515"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41564"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41516"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "40670"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41472"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "40668"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41569"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41464"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41462"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "40667"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41466"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41460"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41469"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41542"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41468"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41470"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41540"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41541"
  },
  {
    "NUTS3": "DEA1D",
    "CODE": "41539"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41372"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41379"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41334"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41366"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41751"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "47929"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41749"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "47906"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41747"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41748"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "47918"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41747"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41748"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41748"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "47877"
  },
  {
    "NUTS3": "DEA1E",
    "CODE": "41747"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "47665"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46509"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46519"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "47475"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46487"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46499"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "47506"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "47495"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "47447"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "47445"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "47441"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46562"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46483"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46485"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "47443"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46569"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46535"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46514"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46537"
  },
  {
    "NUTS3": "DEA1F",
    "CODE": "46539"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53123"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53125"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53121"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53119"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53117"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53127"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53177"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53115"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53225"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53111"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53175"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53113"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53129"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53227"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53229"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53173"
  },
  {
    "NUTS3": "DEA22",
    "CODE": "53179"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50769"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50859"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50858"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50829"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50765"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50933"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50767"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50827"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50935"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50937"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50825"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50739"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50931"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50939"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50737"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50823"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50997"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50969"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50735"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50939"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50674"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50672"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50670"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50735"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50676"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50667"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50677"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50733"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50968"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50667"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50668"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50678"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51061"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50679"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50996"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51105"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51063"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51143"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51103"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "50999"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51065"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51149"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51069"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51107"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51067"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51109"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51147"
  },
  {
    "NUTS3": "DEA23",
    "CODE": "51145"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52441"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52457"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52428"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52393"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52379"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52459"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52445"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52385"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52396"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52353"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52355"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52382"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52372"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52349"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52351"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52399"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52391"
  },
  {
    "NUTS3": "DEA26",
    "CODE": "52388"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50181"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50189"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50170"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50126"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50127"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50171"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50129"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50170"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50374"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50169"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50259"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50226"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50354"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50321"
  },
  {
    "NUTS3": "DEA27",
    "CODE": "50389"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53940"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53937"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53949"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53925"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53894"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53947"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53909"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53945"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53902"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53881"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53879"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53919"
  },
  {
    "NUTS3": "DEA28",
    "CODE": "53879"
  },
  {
    "NUTS3": "DEA29",
    "CODE": "52538"
  },
  {
    "NUTS3": "DEA29",
    "CODE": "52525"
  },
  {
    "NUTS3": "DEA29",
    "CODE": "52531"
  },
  {
    "NUTS3": "DEA29",
    "CODE": "52511"
  },
  {
    "NUTS3": "DEA29",
    "CODE": "41849"
  },
  {
    "NUTS3": "DEA29",
    "CODE": "41844"
  },
  {
    "NUTS3": "DEA29",
    "CODE": "41836"
  },
  {
    "NUTS3": "DEA29",
    "CODE": "41812"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51789"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "42499"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51688"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "42477"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51766"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51674"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51647"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51709"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51588"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51645"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51643"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51545"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51580"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51702"
  },
  {
    "NUTS3": "DEA2A",
    "CODE": "51597"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53913"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53332"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53359"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53340"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53347"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53859"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53343"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53844"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53842"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53757"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53840"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53639"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53721"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53797"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53604"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53773"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53819"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53804"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53809"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "53783"
  },
  {
    "NUTS3": "DEA2C",
    "CODE": "51570"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52074"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52072"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52134"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52076"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52064"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52070"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52066"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52062"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52146"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52068"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52080"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52078"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52477"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52499"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52159"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52159"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52156"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52222"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52159"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52156"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52223"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52249"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52156"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52224"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52222"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52152"
  },
  {
    "NUTS3": "DEA2D",
    "CODE": "52152"
  },
  {
    "NUTS3": "DEA31",
    "CODE": "46244"
  },
  {
    "NUTS3": "DEA31",
    "CODE": "46242"
  },
  {
    "NUTS3": "DEA31",
    "CODE": "46240"
  },
  {
    "NUTS3": "DEA31",
    "CODE": "46236"
  },
  {
    "NUTS3": "DEA31",
    "CODE": "46238"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45896"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45899"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45894"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45897"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45883"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45881"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45891"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45884"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45897"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45892"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45879"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45889"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45886"
  },
  {
    "NUTS3": "DEA32",
    "CODE": "45888"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48161"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48163"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48159"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48149"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48151"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48153"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48165"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48147"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48143"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48157"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48155"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48145"
  },
  {
    "NUTS3": "DEA33",
    "CODE": "48167"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "46419"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "46399"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "46395"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "46397"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "46414"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "48691"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "46325"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "46354"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "46348"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "48703"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "48683"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "46342"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "46359"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "48712"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "48599"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "48734"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "48619"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "48739"
  },
  {
    "NUTS3": "DEA34",
    "CODE": "48624"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "46282"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "46284"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "46286"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45966"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45964"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45968"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45768"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45721"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45772"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45701"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45770"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45699"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45659"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45661"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45657"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45739"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45665"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45663"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45711"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "44579"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "44581"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "44575"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "44577"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "44577"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "44577"
  },
  {
    "NUTS3": "DEA36",
    "CODE": "45731"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48607"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48629"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48493"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48612"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48565"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48366"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48485"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48432"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48431"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48341"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48356"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48429"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48282"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48477"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48432"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48268"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48496"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "48369"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "49479"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "49509"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "49477"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "49545"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "49549"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "49525"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "49497"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "49492"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "49536"
  },
  {
    "NUTS3": "DEA37",
    "CODE": "49504"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "48317"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "48324"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "48291"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "48346"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "48351"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "59227"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "48231"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "59229"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "59320"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "59269"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "48336"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "48361"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "59302"
  },
  {
    "NUTS3": "DEA38",
    "CODE": "59329"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33649"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33619"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33647"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33739"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33659"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33615"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33617"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33613"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33602"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33689"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33611"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33604"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33607"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33609"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33605"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33775"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33428"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33442"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33378"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33829"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33790"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33449"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33334"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33803"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33397"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33330"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33824"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33332"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33330"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33335"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33333"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33415"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32351"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32361"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32339"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32369"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32312"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32609"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33129"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33154"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33142"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44866"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44867"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44869"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44879"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44867"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44793"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44795"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44797"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44809"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44807"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44789"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44791"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44787"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44799"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44801"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44803"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44801"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44805"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44799"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44894"
  },
  {
    "NUTS3": "DEA51",
    "CODE": "44892"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44388"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44359"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44357"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44379"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44149"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44369"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44227"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44147"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44339"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44225"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44139"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44137"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44229"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44145"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44135"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44265"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44263"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44141"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44143"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44329"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44269"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44267"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44328"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44309"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44287"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44319"
  },
  {
    "NUTS3": "DEA52",
    "CODE": "44289"
  },
  {
    "NUTS3": "DEA54",
    "CODE": "59077"
  },
  {
    "NUTS3": "DEA54",
    "CODE": "59075"
  },
  {
    "NUTS3": "DEA54",
    "CODE": "59067"
  },
  {
    "NUTS3": "DEA54",
    "CODE": "59065"
  },
  {
    "NUTS3": "DEA54",
    "CODE": "59073"
  },
  {
    "NUTS3": "DEA54",
    "CODE": "59069"
  },
  {
    "NUTS3": "DEA54",
    "CODE": "59063"
  },
  {
    "NUTS3": "DEA54",
    "CODE": "59071"
  },
  {
    "NUTS3": "DEA55",
    "CODE": "44653"
  },
  {
    "NUTS3": "DEA55",
    "CODE": "44649"
  },
  {
    "NUTS3": "DEA55",
    "CODE": "44651"
  },
  {
    "NUTS3": "DEA55",
    "CODE": "44652"
  },
  {
    "NUTS3": "DEA55",
    "CODE": "44625"
  },
  {
    "NUTS3": "DEA55",
    "CODE": "44629"
  },
  {
    "NUTS3": "DEA55",
    "CODE": "44623"
  },
  {
    "NUTS3": "DEA55",
    "CODE": "44628"
  },
  {
    "NUTS3": "DEA55",
    "CODE": "44623"
  },
  {
    "NUTS3": "DEA55",
    "CODE": "44623"
  },
  {
    "NUTS3": "DEA55",
    "CODE": "44627"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "45529"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "45525"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "45549"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "45527"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "58456"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "58332"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "58455"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "58300"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "58256"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "58285"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "58452"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "58453"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "58454"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "58313"
  },
  {
    "NUTS3": "DEA56",
    "CODE": "58339"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59757"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59846"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59755"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59759"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59821"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59823"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59889"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59872"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "57392"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59909"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59939"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59955"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59929"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59969"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58553"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58579"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58566"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58515"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58769"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58513"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58509"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58642"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58540"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58507"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58640"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58511"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58762"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58644"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58638"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58849"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58636"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58791"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58708"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58675"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58809"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58706"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58840"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58802"
  },
  {
    "NUTS3": "DEA58",
    "CODE": "58710"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57258"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57223"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57078"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57080"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57072"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57290"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57076"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57074"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57299"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57271"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57250"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57234"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57339"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57319"
  },
  {
    "NUTS3": "DEA5A",
    "CODE": "57334"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59457"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "58739"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59469"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59514"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59510"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59494"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59519"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59505"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59556"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59597"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59581"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59609"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59555"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59557"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59602"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59558"
  },
  {
    "NUTS3": "DEA5B",
    "CODE": "59590"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "59379"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "44536"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "44534"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "44532"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "58239"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "59368"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "59192"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "59174"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "59439"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "59427"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "59425"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "59425"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "58730"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "59423"
  },
  {
    "NUTS3": "DEA5C",
    "CODE": "59199"
  },
  {
    "NUTS3": "DEB11",
    "CODE": "56072"
  },
  {
    "NUTS3": "DEB11",
    "CODE": "56075"
  },
  {
    "NUTS3": "DEB11",
    "CODE": "56073"
  },
  {
    "NUTS3": "DEB11",
    "CODE": "56070"
  },
  {
    "NUTS3": "DEB11",
    "CODE": "56070"
  },
  {
    "NUTS3": "DEB11",
    "CODE": "56068"
  },
  {
    "NUTS3": "DEB11",
    "CODE": "56076"
  },
  {
    "NUTS3": "DEB11",
    "CODE": "56077"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53520"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53533"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53520"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53534"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53518"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53505"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53520"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53506"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53501"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53508"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53507"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "56746"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53474"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53426"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "56651"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53424"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53489"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "56653"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "53498"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "56659"
  },
  {
    "NUTS3": "DEB12",
    "CODE": "56656"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57635"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57632"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "56593"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57641"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "56594"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57638"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57612"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57589"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57610"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57539"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57636"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57577"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57539"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57537"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57587"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "51598"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57581"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57580"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57548"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57520"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57572"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57584"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57518"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57583"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57578"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57520"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57520"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57520"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57562"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57586"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57555"
  },
  {
    "NUTS3": "DEB13",
    "CODE": "57567"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55767"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55758"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55765"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55765"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55768"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55767"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55779"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55776"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55483"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55743"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55624"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55777"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55774"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55756"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55608"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55626"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55608"
  },
  {
    "NUTS3": "DEB15",
    "CODE": "55758"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56729"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56745"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56727"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56743"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56736"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56751"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56645"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56753"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56642"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56753"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56626"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56294"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56299"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56630"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56295"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56332"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56637"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56330"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56648"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56220"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56575"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56332"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56283"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56218"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56333"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56220"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56323"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56170"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56321"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56179"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56191"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56182"
  },
  {
    "NUTS3": "DEB17",
    "CODE": "56322"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53572"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53619"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53579"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53547"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53545"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53560"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53547"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53557"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56598"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53578"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53562"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56599"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53567"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "53577"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56567"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56588"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56589"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56581"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56564"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56587"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56579"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56566"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56584"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56317"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56305"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56307"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56271"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "57614"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56307"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56269"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56316"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56276"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "57639"
  },
  {
    "NUTS3": "DEB18",
    "CODE": "56269"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56341"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56340"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56112"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56338"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56348"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56337"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56132"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56346"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56133"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56348"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56130"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56132"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56357"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56338"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56355"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56348"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56349"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56379"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56346"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56132"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56377"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56355"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56346"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56346"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56379"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56379"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56379"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56355"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56355"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56355"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "65558"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56370"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56355"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56368"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56370"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "65624"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "65582"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "56370"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "65626"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "65558"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "65558"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "65629"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "65558"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "65623"
  },
  {
    "NUTS3": "DEB1A",
    "CODE": "65558"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56237"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56203"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56206"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56271"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56204"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56244"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56242"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56244"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56235"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56335"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56242"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57644"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56249"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57629"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56424"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56410"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57629"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56428"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56424"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57627"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56422"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56427"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56412"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56244"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56379"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57629"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57627"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56424"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57645"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57642"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56412"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56414"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57642"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57648"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56459"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57629"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "57647"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56472"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56470"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56457"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56462"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56472"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56479"
  },
  {
    "NUTS3": "DEB1B",
    "CODE": "56477"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55491"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55483"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "56858"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55487"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "56290"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55481"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "56869"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55483"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "56288"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "56288"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55471"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55490"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55483"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55483"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "56288"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "56283"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55483"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55469"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55471"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "56154"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "56291"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "56281"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55499"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55496"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55497"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55494"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55432"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "55430"
  },
  {
    "NUTS3": "DEB1D",
    "CODE": "56329"
  },
  {
    "NUTS3": "DEB21",
    "CODE": "54294"
  },
  {
    "NUTS3": "DEB21",
    "CODE": "54293"
  },
  {
    "NUTS3": "DEB21",
    "CODE": "54296"
  },
  {
    "NUTS3": "DEB21",
    "CODE": "54290"
  },
  {
    "NUTS3": "DEB21",
    "CODE": "54292"
  },
  {
    "NUTS3": "DEB21",
    "CODE": "54295"
  },
  {
    "NUTS3": "DEB21",
    "CODE": "54295"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54533"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54518"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54531"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54526"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54523"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54534"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54528"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54533"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54516"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54524"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54426"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54347"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54498"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54538"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54497"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54484"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54487"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54424"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54492"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54472"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54426"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54539"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54470"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54486"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54426"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54411"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "56841"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54536"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "56861"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "56843"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "56843"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54483"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "56843"
  },
  {
    "NUTS3": "DEB22",
    "CODE": "54472"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54689"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54617"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54673"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54619"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54616"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54608"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54675"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54597"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54687"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54597"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54649"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54595"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54669"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54646"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54636"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54668"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54597"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54614"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54612"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54666"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54310"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54634"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54655"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54610"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54657"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54657"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54647"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54664"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54662"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54298"
  },
  {
    "NUTS3": "DEB23",
    "CODE": "54529"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54611"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54589"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54584"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54587"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54586"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54570"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54574"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54568"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54570"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54585"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54578"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54576"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54578"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54579"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54550"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54552"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54578"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "53539"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54558"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "56767"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "54552"
  },
  {
    "NUTS3": "DEB24",
    "CODE": "56769"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54439"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54457"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54453"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54441"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54441"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54456"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54298"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54331"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54441"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54456"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54308"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54332"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54450"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54329"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54298"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54311"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54455"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54309"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54298"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54451"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54459"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54306"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54314"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54313"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54316"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54331"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54318"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54338"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54317"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54344"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54429"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54317"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54320"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54340"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54340"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54343"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54341"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54427"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54346"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54340"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54421"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54413"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54413"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54349"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54411"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54413"
  },
  {
    "NUTS3": "DEB25",
    "CODE": "54422"
  },
  {
    "NUTS3": "DEB32",
    "CODE": "67661"
  },
  {
    "NUTS3": "DEB32",
    "CODE": "67661"
  },
  {
    "NUTS3": "DEB32",
    "CODE": "67659"
  },
  {
    "NUTS3": "DEB32",
    "CODE": "67663"
  },
  {
    "NUTS3": "DEB32",
    "CODE": "67655"
  },
  {
    "NUTS3": "DEB32",
    "CODE": "67657"
  },
  {
    "NUTS3": "DEB33",
    "CODE": "76829"
  },
  {
    "NUTS3": "DEB34",
    "CODE": "67071"
  },
  {
    "NUTS3": "DEB34",
    "CODE": "67067"
  },
  {
    "NUTS3": "DEB34",
    "CODE": "67069"
  },
  {
    "NUTS3": "DEB34",
    "CODE": "67065"
  },
  {
    "NUTS3": "DEB34",
    "CODE": "67063"
  },
  {
    "NUTS3": "DEB34",
    "CODE": "67067"
  },
  {
    "NUTS3": "DEB34",
    "CODE": "67059"
  },
  {
    "NUTS3": "DEB34",
    "CODE": "67061"
  },
  {
    "NUTS3": "DEB35",
    "CODE": "55126"
  },
  {
    "NUTS3": "DEB35",
    "CODE": "55127"
  },
  {
    "NUTS3": "DEB35",
    "CODE": "55124"
  },
  {
    "NUTS3": "DEB35",
    "CODE": "55120"
  },
  {
    "NUTS3": "DEB35",
    "CODE": "55128"
  },
  {
    "NUTS3": "DEB35",
    "CODE": "55122"
  },
  {
    "NUTS3": "DEB35",
    "CODE": "55129"
  },
  {
    "NUTS3": "DEB35",
    "CODE": "55118"
  },
  {
    "NUTS3": "DEB35",
    "CODE": "55131"
  },
  {
    "NUTS3": "DEB35",
    "CODE": "55116"
  },
  {
    "NUTS3": "DEB35",
    "CODE": "55130"
  },
  {
    "NUTS3": "DEB36",
    "CODE": "67434"
  },
  {
    "NUTS3": "DEB36",
    "CODE": "67435"
  },
  {
    "NUTS3": "DEB36",
    "CODE": "67433"
  },
  {
    "NUTS3": "DEB38",
    "CODE": "67346"
  },
  {
    "NUTS3": "DEB39",
    "CODE": "67551"
  },
  {
    "NUTS3": "DEB39",
    "CODE": "67550"
  },
  {
    "NUTS3": "DEB39",
    "CODE": "67549"
  },
  {
    "NUTS3": "DEB39",
    "CODE": "67547"
  },
  {
    "NUTS3": "DEB3A",
    "CODE": "66482"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55599"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55597"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55234"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55599"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55237"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55578"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55288"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55232"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55288"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55234"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55286"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55232"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55291"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55288"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67591"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "55239"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67598"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67592"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67590"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67593"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67596"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67591"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67599"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67591"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67595"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67574"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67577"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67582"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67578"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67575"
  },
  {
    "NUTS3": "DEB3B",
    "CODE": "67580"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67471"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67319"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67475"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67098"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67472"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67466"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67468"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67316"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67273"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67310"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67317"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67256"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67157"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67473"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67271"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67273"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67311"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67280"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67256"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67271"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67269"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67146"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67271"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67152"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67278"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67169"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67147"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67283"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67281"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67251"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67271"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67149"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67167"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67150"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67159"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67229"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67454"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67246"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67161"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67256"
  },
  {
    "NUTS3": "DEB3C",
    "CODE": "67158"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67744"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67822"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67806"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67823"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67759"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67724"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67808"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67822"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67822"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67808"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67821"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67811"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67722"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67727"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67813"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67808"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67817"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67728"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67819"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67814"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67724"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67725"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67729"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67808"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67292"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67295"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67808"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67294"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67305"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67304"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67307"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67816"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67297"
  },
  {
    "NUTS3": "DEB3D",
    "CODE": "67308"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "66894"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "66892"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "66882"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "66879"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "66851"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "66877"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "66849"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "66851"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67685"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67686"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "66862"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67734"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67737"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67688"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67700"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67706"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67732"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67707"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67701"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67735"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67705"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67731"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67697"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67699"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67678"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67693"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67681"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67677"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67691"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67693"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67680"
  },
  {
    "NUTS3": "DEB3F",
    "CODE": "67468"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66916"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66871"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66903"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66914"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66909"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66901"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66904"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66869"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66903"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66907"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66871"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66909"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66887"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66885"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66887"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67744"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "66909"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67746"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67742"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67744"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67754"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67749"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67756"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67745"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67753"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67753"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67745"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67752"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67742"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67757"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67748"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67753"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67742"
  },
  {
    "NUTS3": "DEB3G",
    "CODE": "67759"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76855"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76889"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76857"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76887"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76855"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76835"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76887"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67480"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67482"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67489"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67377"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76831"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67487"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76831"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67483"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76833"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76835"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76829"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67377"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67480"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76887"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67482"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67483"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76865"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67489"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76879"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67482"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76863"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "76877"
  },
  {
    "NUTS3": "DEB3H",
    "CODE": "67377"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67127"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67134"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67258"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67245"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67126"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67459"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67133"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67136"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67259"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67125"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67112"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67376"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67374"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67105"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67240"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67373"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67354"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67117"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67141"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67165"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67166"
  },
  {
    "NUTS3": "DEB3I",
    "CODE": "67122"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55422"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55413"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55425"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55424"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55411"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55411"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55459"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55457"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55576"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55437"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55459"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55218"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55435"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55437"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55270"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55262"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55263"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55271"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55257"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55268"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55278"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55270"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "67586"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55296"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "67585"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "67587"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55294"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55283"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55299"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "67583"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55276"
  },
  {
    "NUTS3": "DEB3J",
    "CODE": "55278"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66333"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66352"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66127"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66346"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66265"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66128"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66292"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66126"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66115"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66117"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66113"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66119"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66111"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66287"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66123"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66125"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66121"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66130"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66271"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66129"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66280"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66133"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66132"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66299"
  },
  {
    "NUTS3": "DEC01",
    "CODE": "66131"
  },
  {
    "NUTS3": "DEC02",
    "CODE": "66706"
  },
  {
    "NUTS3": "DEC02",
    "CODE": "66693"
  },
  {
    "NUTS3": "DEC02",
    "CODE": "66663"
  },
  {
    "NUTS3": "DEC02",
    "CODE": "66679"
  },
  {
    "NUTS3": "DEC02",
    "CODE": "66701"
  },
  {
    "NUTS3": "DEC02",
    "CODE": "66709"
  },
  {
    "NUTS3": "DEC02",
    "CODE": "66687"
  },
  {
    "NUTS3": "DEC03",
    "CODE": "66571"
  },
  {
    "NUTS3": "DEC03",
    "CODE": "66557"
  },
  {
    "NUTS3": "DEC03",
    "CODE": "66589"
  },
  {
    "NUTS3": "DEC03",
    "CODE": "66578"
  },
  {
    "NUTS3": "DEC03",
    "CODE": "66564"
  },
  {
    "NUTS3": "DEC03",
    "CODE": "66540"
  },
  {
    "NUTS3": "DEC03",
    "CODE": "66583"
  },
  {
    "NUTS3": "DEC03",
    "CODE": "66538"
  },
  {
    "NUTS3": "DEC03",
    "CODE": "66539"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66780"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66798"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66802"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66740"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66763"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66787"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66809"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66806"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66793"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66359"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66773"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66839"
  },
  {
    "NUTS3": "DEC04",
    "CODE": "66822"
  },
  {
    "NUTS3": "DEC05",
    "CODE": "66386"
  },
  {
    "NUTS3": "DEC05",
    "CODE": "66399"
  },
  {
    "NUTS3": "DEC05",
    "CODE": "66453"
  },
  {
    "NUTS3": "DEC05",
    "CODE": "66440"
  },
  {
    "NUTS3": "DEC05",
    "CODE": "66459"
  },
  {
    "NUTS3": "DEC05",
    "CODE": "66450"
  },
  {
    "NUTS3": "DEC05",
    "CODE": "66424"
  },
  {
    "NUTS3": "DEC06",
    "CODE": "66620"
  },
  {
    "NUTS3": "DEC06",
    "CODE": "66636"
  },
  {
    "NUTS3": "DEC06",
    "CODE": "66625"
  },
  {
    "NUTS3": "DEC06",
    "CODE": "66646"
  },
  {
    "NUTS3": "DEC06",
    "CODE": "66649"
  },
  {
    "NUTS3": "DEC06",
    "CODE": "66606"
  },
  {
    "NUTS3": "DEC06",
    "CODE": "66640"
  },
  {
    "NUTS3": "DEC06",
    "CODE": "66629"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25997"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25980"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25946"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25996"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25999"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25992"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25938"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25859"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25859"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25863"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25849"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25924"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "27498"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "27498"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70569"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70197"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70499"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70563"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70565"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70195"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70199"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70469"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70567"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70193"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70439"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70435"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70439"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70597"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70192"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70176"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70178"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70174"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70180"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70599"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70173"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70191"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70184"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70437"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70182"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70376"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70190"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70188"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70619"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70186"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70378"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70329"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70372"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70327"
  },
  {
    "NUTS3": "DE111",
    "CODE": "70374"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71131"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71159"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71149"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71083"
  },
  {
    "NUTS3": "DE112",
    "CODE": "75392"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71126"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71263"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71134"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71116"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71120"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71154"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71287"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71272"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71277"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71139"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71069"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71157"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71106"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71034"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71229"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71063"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71155"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71067"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71088"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71065"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71032"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71063"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71069"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71101"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71093"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71111"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71144"
  },
  {
    "NUTS3": "DE113",
    "CODE": "70771"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72631"
  },
  {
    "NUTS3": "DE113",
    "CODE": "70794"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72657"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72667"
  },
  {
    "NUTS3": "DE113",
    "CODE": "70629"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72657"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72654"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73760"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72666"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73765"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72658"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73733"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72655"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72649"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73734"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72622"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72663"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73728"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73770"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72663"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73732"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72669"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72664"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72639"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73257"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73730"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72636"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73773"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72644"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73240"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73779"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73776"
  },
  {
    "NUTS3": "DE113",
    "CODE": "72660"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73257"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73207"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73249"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73230"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73265"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73268"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73277"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73666"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73274"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73252"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73262"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73269"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73669"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73266"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73235"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73271"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73275"
  },
  {
    "NUTS3": "DE113",
    "CODE": "73272"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73061"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73278"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73066"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73095"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73101"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73110"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73119"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73349"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73117"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73099"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73087"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73344"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73035"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73104"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73098"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73105"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73092"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73033"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73347"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73345"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73108"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73037"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73102"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73116"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73342"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73107"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73107"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73054"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73114"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73326"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73079"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73337"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73084"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73113"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73312"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73333"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73072"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73329"
  },
  {
    "NUTS3": "DE114",
    "CODE": "73111"
  },
  {
    "NUTS3": "DE114",
    "CODE": "89558"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74343"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71665"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71735"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71254"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74372"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71282"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71739"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71706"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74357"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71701"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74392"
  },
  {
    "NUTS3": "DE115",
    "CODE": "70839"
  },
  {
    "NUTS3": "DE115",
    "CODE": "70825"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74391"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74369"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74321"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71732"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74366"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71696"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74354"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74399"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71679"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71634"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71636"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74376"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74379"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71691"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74321"
  },
  {
    "NUTS3": "DE115",
    "CODE": "70806"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74394"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71638"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74385"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74354"
  },
  {
    "NUTS3": "DE115",
    "CODE": "74395"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71642"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71640"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71711"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71726"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71672"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71686"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71723"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71729"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71720"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71672"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71672"
  },
  {
    "NUTS3": "DE115",
    "CODE": "71563"
  },
  {
    "NUTS3": "DE117",
    "CODE": "74078"
  },
  {
    "NUTS3": "DE117",
    "CODE": "74080"
  },
  {
    "NUTS3": "DE117",
    "CODE": "74081"
  },
  {
    "NUTS3": "DE117",
    "CODE": "74072"
  },
  {
    "NUTS3": "DE117",
    "CODE": "74076"
  },
  {
    "NUTS3": "DE117",
    "CODE": "74074"
  },
  {
    "NUTS3": "DE118",
    "CODE": "75031"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74374"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74930"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74397"
  },
  {
    "NUTS3": "DE118",
    "CODE": "75050"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74193"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74912"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74906"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74363"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74389"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74336"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74252"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74936"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74226"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74211"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74206"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74348"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74831"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74254"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74172"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74388"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74382"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74257"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74177"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74223"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74360"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74229"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74861"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74235"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74199"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74189"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74196"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74232"
  },
  {
    "NUTS3": "DE118",
    "CODE": "71717"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74219"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74246"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74251"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74248"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74255"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74182"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74245"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74239"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74243"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74259"
  },
  {
    "NUTS3": "DE118",
    "CODE": "71543"
  },
  {
    "NUTS3": "DE118",
    "CODE": "74249"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74626"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74613"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74670"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74214"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74639"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74629"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74632"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74670"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74676"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74238"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74679"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74638"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74653"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74635"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74677"
  },
  {
    "NUTS3": "DE119",
    "CODE": "74673"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74535"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74545"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74523"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74420"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74538"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74427"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74547"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74405"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74544"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74542"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74429"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74423"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74549"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74595"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74532"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74541"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74572"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74575"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74426"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74532"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74582"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74424"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74426"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74586"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74592"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74585"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74564"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74589"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74599"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74597"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74594"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74579"
  },
  {
    "NUTS3": "DE11A",
    "CODE": "74597"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97896"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97877"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97900"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "74744"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97953"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97956"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97944"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97922"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97941"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97959"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97950"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97980"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97947"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97957"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97999"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97996"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97990"
  },
  {
    "NUTS3": "DE11B",
    "CODE": "97993"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89547"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89555"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89551"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89542"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89518"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89522"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89520"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89542"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89537"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89168"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89564"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89568"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89567"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89168"
  },
  {
    "NUTS3": "DE11C",
    "CODE": "89561"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76694"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76669"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76698"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76709"
  },
  {
    "NUTS3": "DE123",
    "CODE": "75053"
  },
  {
    "NUTS3": "DE123",
    "CODE": "75015"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76703"
  },
  {
    "NUTS3": "DE123",
    "CODE": "76684"
  },
  {
    "NUTS3": "DE123",
    "CODE": "75038"
  },
  {
    "NUTS3": "DE123",
    "CODE": "75059"
  },
  {
    "NUTS3": "DE123",
    "CODE": "75057"
  },
  {
    "NUTS3": "DE123",
    "CODE": "75056"
  },
  {
    "NUTS3": "DE125",
    "CODE": "69123"
  },
  {
    "NUTS3": "DE125",
    "CODE": "69124"
  },
  {
    "NUTS3": "DE125",
    "CODE": "69121"
  },
  {
    "NUTS3": "DE125",
    "CODE": "69115"
  },
  {
    "NUTS3": "DE125",
    "CODE": "69120"
  },
  {
    "NUTS3": "DE125",
    "CODE": "69126"
  },
  {
    "NUTS3": "DE125",
    "CODE": "69117"
  },
  {
    "NUTS3": "DE125",
    "CODE": "69118"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74858"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74869"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74867"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74847"
  },
  {
    "NUTS3": "DE127",
    "CODE": "69439"
  },
  {
    "NUTS3": "DE127",
    "CODE": "69429"
  },
  {
    "NUTS3": "DE127",
    "CODE": "69437"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74928"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74862"
  },
  {
    "NUTS3": "DE127",
    "CODE": "69427"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74855"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74821"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74838"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74865"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74864"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74834"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74842"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74722"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74850"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74743"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74731"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74740"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74706"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74746"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74736"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74749"
  },
  {
    "NUTS3": "DE127",
    "CODE": "74747"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68789"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68526"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68542"
  },
  {
    "NUTS3": "DE128",
    "CODE": "68535"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69502"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69514"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69190"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69181"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69469"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69207"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69214"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69221"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69493"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69198"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69254"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69168"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69231"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69181"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69226"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69242"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69234"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69251"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74918"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69245"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69259"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69253"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69151"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69250"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74889"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69256"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74909"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74939"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69257"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69434"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74927"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74931"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74915"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74937"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74933"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74925"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69436"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74934"
  },
  {
    "NUTS3": "DE128",
    "CODE": "69412"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74921"
  },
  {
    "NUTS3": "DE128",
    "CODE": "74924"
  },
  {
    "NUTS3": "DE128",
    "CODE": "64754"
  },
  {
    "NUTS3": "DE129",
    "CODE": "75180"
  },
  {
    "NUTS3": "DE129",
    "CODE": "75179"
  },
  {
    "NUTS3": "DE129",
    "CODE": "75173"
  },
  {
    "NUTS3": "DE129",
    "CODE": "75172"
  },
  {
    "NUTS3": "DE129",
    "CODE": "75177"
  },
  {
    "NUTS3": "DE129",
    "CODE": "75181"
  },
  {
    "NUTS3": "DE129",
    "CODE": "75175"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75394"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "72227"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75328"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "72221"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75385"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75387"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "72224"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75378"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "72218"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75399"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75365"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "72229"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "72202"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75382"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75397"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75397"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75391"
  },
  {
    "NUTS3": "DE12A",
    "CODE": "75395"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75217"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75203"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75236"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75331"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75228"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75239"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75245"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75242"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75248"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75249"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75438"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75223"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75433"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75443"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75233"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75417"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "71299"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "71292"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75449"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75446"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75447"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "71297"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "71296"
  },
  {
    "NUTS3": "DE12B",
    "CODE": "75428"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72186"
  },
  {
    "NUTS3": "DE12C",
    "CODE": "72184"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78628"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78661"
  },
  {
    "NUTS3": "DE135",
    "CODE": "72189"
  },
  {
    "NUTS3": "DE135",
    "CODE": "78669"
  },
  {
    "NUTS3": "DE136",
    "CODE": "78609"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78250"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78266"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78247"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78234"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78244"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78262"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78259"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78239"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78224"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78267"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78253"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78269"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78337"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78256"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78359"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78315"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78345"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78333"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78343"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78333"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78357"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78351"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78479"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78476"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78479"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78355"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78479"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78465"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78479"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78479"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78467"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78479"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78462"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78464"
  },
  {
    "NUTS3": "DE138",
    "CODE": "78465"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72770"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72820"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72141"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72124"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72141"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72827"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72818"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72768"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72793"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72762"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72760"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72764"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72764"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72766"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72762"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72805"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72766"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72800"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72555"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72585"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72829"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72531"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72661"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72813"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72581"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72532"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72539"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72574"
  },
  {
    "NUTS3": "DE141",
    "CODE": "88529"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72584"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72534"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72525"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72582"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72587"
  },
  {
    "NUTS3": "DE141",
    "CODE": "72537"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72108"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72181"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72149"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72145"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72119"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72411"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72070"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72131"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72108"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72072"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72116"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72108"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72144"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72076"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72074"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72147"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72810"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72127"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72135"
  },
  {
    "NUTS3": "DE142",
    "CODE": "72138"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72348"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72369"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72355"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72356"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72401"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72351"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72367"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72358"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72359"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72336"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72365"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72361"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72364"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72414"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72469"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72415"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72362"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72145"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72406"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72459"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72379"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72461"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72458"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72417"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72393"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72479"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72475"
  },
  {
    "NUTS3": "DE143",
    "CODE": "72474"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89584"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89611"
  },
  {
    "NUTS3": "DE145",
    "CODE": "72589"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89601"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89150"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89617"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89597"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89607"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89604"
  },
  {
    "NUTS3": "DE145",
    "CODE": "72535"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89613"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89616"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89619"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89143"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89188"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89180"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89191"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89605"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89605"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89604"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89608"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89155"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89614"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89134"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89160"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89584"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89610"
  },
  {
    "NUTS3": "DE145",
    "CODE": "73340"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89173"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89198"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89185"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89179"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89197"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89195"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89194"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89174"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89183"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89171"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89182"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89186"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89129"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89189"
  },
  {
    "NUTS3": "DE145",
    "CODE": "88481"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89165"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89177"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89192"
  },
  {
    "NUTS3": "DE145",
    "CODE": "89176"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88515"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88499"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88521"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88527"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88525"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88422"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88524"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88427"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88448"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88400"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88456"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88441"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88433"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88447"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88454"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88436"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88471"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88444"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88437"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88487"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88480"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88416"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88483"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88430"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88484"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88477"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88471"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88489"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88453"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88450"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88486"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88457"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88459"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88451"
  },
  {
    "NUTS3": "DE146",
    "CODE": "88457"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88662"
  },
  {
    "NUTS3": "DE147",
    "CODE": "78354"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88696"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88690"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88699"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88662"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88682"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88709"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88633"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88718"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88719"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88709"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88697"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88677"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88090"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88693"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88048"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88045"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88094"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88046"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88097"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88074"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88085"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88069"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88079"
  },
  {
    "NUTS3": "DE147",
    "CODE": "88099"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88271"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88377"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88376"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88374"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88263"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88379"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88373"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88361"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88273"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88213"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88370"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88379"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88276"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88371"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88284"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88214"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88326"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88212"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88250"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88213"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88255"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88287"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88281"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88285"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88339"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88147"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88289"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88368"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88279"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88285"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88239"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88267"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88364"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88410"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88353"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88260"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88299"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88316"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88319"
  },
  {
    "NUTS3": "DE148",
    "CODE": "88317"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88637"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88631"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72477"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72510"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88605"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72488"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88639"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72514"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72419"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88634"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88630"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72519"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72505"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72501"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72513"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72511"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72517"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88512"
  },
  {
    "NUTS3": "DE149",
    "CODE": "72516"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88356"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88512"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88636"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88367"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88518"
  },
  {
    "NUTS3": "DE149",
    "CODE": "88348"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81249"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81245"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81243"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80999"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81247"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81241"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80997"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81377"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80689"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81375"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81475"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80995"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80638"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80687"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80639"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81476"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80992"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80686"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81477"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80993"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81379"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81479"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81373"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81369"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80634"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80637"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80339"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80636"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80809"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80935"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81371"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80335"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81545"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80337"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80336"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80797"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80933"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81543"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80469"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80333"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80798"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81547"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80796"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80937"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80331"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80804"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80807"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80799"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80801"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81541"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81539"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80803"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80939"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80539"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80538"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80802"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81549"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81669"
  },
  {
    "NUTS3": "DE212",
    "CODE": "80805"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81667"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81675"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81679"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81671"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81925"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81677"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81673"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81737"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81735"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81927"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81929"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81739"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81825"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81829"
  },
  {
    "NUTS3": "DE212",
    "CODE": "81827"
  },
  {
    "NUTS3": "DE213",
    "CODE": "83026"
  },
  {
    "NUTS3": "DE213",
    "CODE": "83024"
  },
  {
    "NUTS3": "DE213",
    "CODE": "83022"
  },
  {
    "NUTS3": "DE216",
    "CODE": "82432"
  },
  {
    "NUTS3": "DE216",
    "CODE": "82444"
  },
  {
    "NUTS3": "DE216",
    "CODE": "83676"
  },
  {
    "NUTS3": "DE216",
    "CODE": "82541"
  },
  {
    "NUTS3": "DE216",
    "CODE": "82431"
  },
  {
    "NUTS3": "DE216",
    "CODE": "83661"
  },
  {
    "NUTS3": "DE216",
    "CODE": "83671"
  },
  {
    "NUTS3": "DE216",
    "CODE": "82547"
  },
  {
    "NUTS3": "DE216",
    "CODE": "83673"
  },
  {
    "NUTS3": "DE216",
    "CODE": "82057"
  },
  {
    "NUTS3": "DE216",
    "CODE": "83670"
  },
  {
    "NUTS3": "DE216",
    "CODE": "82515"
  },
  {
    "NUTS3": "DE216",
    "CODE": "82549"
  },
  {
    "NUTS3": "DE216",
    "CODE": "82538"
  },
  {
    "NUTS3": "DE216",
    "CODE": "82544"
  },
  {
    "NUTS3": "DE216",
    "CODE": "83646"
  },
  {
    "NUTS3": "DE216",
    "CODE": "83623"
  },
  {
    "NUTS3": "DE216",
    "CODE": "83674"
  },
  {
    "NUTS3": "DE216",
    "CODE": "83677"
  },
  {
    "NUTS3": "DE216",
    "CODE": "83679"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85591"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85652"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85658"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85586"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85599"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85598"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85604"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85667"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85646"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85625"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85570"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85665"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85614"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85560"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85567"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85661"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85617"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85664"
  },
  {
    "NUTS3": "DE218",
    "CODE": "85643"
  },
  {
    "NUTS3": "DE218",
    "CODE": "83553"
  },
  {
    "NUTS3": "DE218",
    "CODE": "83550"
  },
  {
    "NUTS3": "DE219",
    "CODE": "91804"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85132"
  },
  {
    "NUTS3": "DE219",
    "CODE": "91809"
  },
  {
    "NUTS3": "DE219",
    "CODE": "91795"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85111"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85131"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85072"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85135"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85128"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85116"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85137"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85114"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85110"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85122"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85117"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85125"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85080"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85113"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85139"
  },
  {
    "NUTS3": "DE219",
    "CODE": "92339"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85134"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85095"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85101"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85120"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85092"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85098"
  },
  {
    "NUTS3": "DE219",
    "CODE": "93336"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85129"
  },
  {
    "NUTS3": "DE219",
    "CODE": "93349"
  },
  {
    "NUTS3": "DE219",
    "CODE": "85104"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85452"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85464"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85445"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85467"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85462"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85435"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85457"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85459"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85669"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85465"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85659"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85469"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85447"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85461"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85456"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "85656"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "84424"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "84435"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "84434"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "84416"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "84439"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "84405"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "84427"
  },
  {
    "NUTS3": "DE21A",
    "CODE": "84432"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85411"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85777"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85307"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85402"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85391"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85386"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85376"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85375"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85414"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85354"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "84072"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85395"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85399"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85406"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "84104"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85356"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85405"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85417"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85416"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85410"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85413"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85368"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85419"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85408"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82272"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82278"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82299"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82276"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82293"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82285"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82287"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82288"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82291"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82294"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82281"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82284"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82290"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82296"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82256"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82216"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82239"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82275"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82140"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82223"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82110"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82178"
  },
  {
    "NUTS3": "DE21C",
    "CODE": "82194"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86920"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86859"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86925"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86857"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86944"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86899"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86916"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86937"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86946"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86981"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86932"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86947"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86934"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86929"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86931"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86940"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86974"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86928"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86943"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "82269"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86935"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86492"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86911"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86923"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86949"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86922"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86941"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86919"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86926"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "86938"
  },
  {
    "NUTS3": "DE21E",
    "CODE": "82279"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83708"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83607"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83666"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83624"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83707"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83703"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83627"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83626"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83684"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83700"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83629"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83714"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83734"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83727"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83737"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83730"
  },
  {
    "NUTS3": "DE21F",
    "CODE": "83735"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "83558"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "83527"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "83562"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84437"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "83536"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84419"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84428"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "83567"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "83555"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "83546"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84431"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84544"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "83559"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84539"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84564"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84555"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82152"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82166"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82061"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82069"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82067"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82065"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82064"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82049"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85764"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82031"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85716"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82041"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "81545"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82054"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82024"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "82008"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85748"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85579"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85649"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85774"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85521"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85737"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85609"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85662"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85640"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85635"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85540"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85653"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85622"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85630"
  },
  {
    "NUTS3": "DE21H",
    "CODE": "85551"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86643"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86666"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86676"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86697"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86633"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86669"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86701"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86529"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86673"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86571"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86562"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86565"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86561"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86668"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86706"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86564"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "86579"
  },
  {
    "NUTS3": "DE21I",
    "CODE": "85123"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85302"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "86558"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85305"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85298"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85276"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85293"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85309"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85084"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85107"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85077"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85304"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85296"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85119"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85290"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85283"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85088"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85301"
  },
  {
    "NUTS3": "DE21J",
    "CODE": "85126"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82211"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82266"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82346"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82229"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82237"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82327"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82205"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82234"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82319"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82343"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82340"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82131"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82349"
  },
  {
    "NUTS3": "DE21L",
    "CODE": "82335"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83530"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83132"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83259"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83119"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "86975"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "86980"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "86987"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "86977"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "86984"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "86986"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "86989"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "86972"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "86971"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "86956"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "86978"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82409"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82401"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82389"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82383"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82401"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82405"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82380"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82386"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82399"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82362"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82398"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82407"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82436"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82396"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82390"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82395"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82402"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82347"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82387"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82392"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82393"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82404"
  },
  {
    "NUTS3": "DE21N",
    "CODE": "82377"
  },
  {
    "NUTS3": "DE221",
    "CODE": "84034"
  },
  {
    "NUTS3": "DE221",
    "CODE": "84028"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93339"
  },
  {
    "NUTS3": "DE226",
    "CODE": "84089"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93333"
  },
  {
    "NUTS3": "DE226",
    "CODE": "84048"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93309"
  },
  {
    "NUTS3": "DE226",
    "CODE": "84094"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93351"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93343"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93354"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93358"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93326"
  },
  {
    "NUTS3": "DE226",
    "CODE": "84091"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93346"
  },
  {
    "NUTS3": "DE226",
    "CODE": "84106"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93342"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93359"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93348"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93352"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93345"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93356"
  },
  {
    "NUTS3": "DE226",
    "CODE": "93077"
  },
  {
    "NUTS3": "DE226",
    "CODE": "84085"
  },
  {
    "NUTS3": "DE226",
    "CODE": "84097"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84076"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84101"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84056"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84095"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84079"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84107"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84172"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84174"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84098"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84032"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84184"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84186"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84030"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84088"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84036"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84051"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84169"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84061"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84171"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84181"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84144"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84149"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84166"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84103"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84092"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84137"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84100"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84189"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84178"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84109"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84187"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84155"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84175"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "84066"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "84082"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94333"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "84152"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "84183"
  },
  {
    "NUTS3": "DE231",
    "CODE": "92224"
  },
  {
    "NUTS3": "DE232",
    "CODE": "93049"
  },
  {
    "NUTS3": "DE232",
    "CODE": "93059"
  },
  {
    "NUTS3": "DE232",
    "CODE": "93051"
  },
  {
    "NUTS3": "DE232",
    "CODE": "93053"
  },
  {
    "NUTS3": "DE232",
    "CODE": "93047"
  },
  {
    "NUTS3": "DE232",
    "CODE": "93057"
  },
  {
    "NUTS3": "DE232",
    "CODE": "93055"
  },
  {
    "NUTS3": "DE233",
    "CODE": "92637"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93192"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93189"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93194"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93199"
  },
  {
    "NUTS3": "DE236",
    "CODE": "90602"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92342"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92353"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92361"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92334"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92348"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92360"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92318"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92369"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92283"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92364"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92367"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92345"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92363"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92358"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92355"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92331"
  },
  {
    "NUTS3": "DE236",
    "CODE": "92366"
  },
  {
    "NUTS3": "DE237",
    "CODE": "91281"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92655"
  },
  {
    "NUTS3": "DE237",
    "CODE": "95519"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92676"
  },
  {
    "NUTS3": "DE237",
    "CODE": "95514"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92724"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92700"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92690"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92729"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92720"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92702"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92708"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92711"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92665"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92694"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92706"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92670"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92712"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92660"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92718"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92715"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92721"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92699"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92685"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92705"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92648"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92727"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92723"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92696"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92697"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92709"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92714"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93155"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93176"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93164"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93183"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93152"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93180"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93182"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93161"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93188"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93195"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93186"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93080"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93128"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93138"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93107"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93197"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93083"
  },
  {
    "NUTS3": "DE238",
    "CODE": "84069"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93173"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93096"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93105"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93170"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93197"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93087"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93095"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93073"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93101"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93092"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93093"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93098"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93089"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93090"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93177"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93102"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93104"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93109"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93086"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93179"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93109"
  },
  {
    "NUTS3": "DE238",
    "CODE": "93099"
  },
  {
    "NUTS3": "DE239",
    "CODE": "93133"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92421"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92269"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92546"
  },
  {
    "NUTS3": "DE239",
    "CODE": "93158"
  },
  {
    "NUTS3": "DE239",
    "CODE": "93142"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92533"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92521"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92507"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92551"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92536"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92449"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92442"
  },
  {
    "NUTS3": "DE239",
    "CODE": "93149"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92548"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92439"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92436"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92555"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92431"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92543"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92540"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92447"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92545"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92552"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92445"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92542"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95505"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95505"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95478"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95508"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95506"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95682"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95683"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95700"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95704"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95679"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "92681"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "92703"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95688"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95689"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "92717"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95701"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95676"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95685"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95666"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95692"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95703"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95652"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95643"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95698"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95671"
  },
  {
    "NUTS3": "DE242",
    "CODE": "95445"
  },
  {
    "NUTS3": "DE242",
    "CODE": "95447"
  },
  {
    "NUTS3": "DE242",
    "CODE": "95448"
  },
  {
    "NUTS3": "DE242",
    "CODE": "95444"
  },
  {
    "NUTS3": "DE243",
    "CODE": "96450"
  },
  {
    "NUTS3": "DE244",
    "CODE": "95030"
  },
  {
    "NUTS3": "DE244",
    "CODE": "95032"
  },
  {
    "NUTS3": "DE244",
    "CODE": "95028"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96157"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96154"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96132"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96185"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96138"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96170"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96191"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96173"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96194"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96169"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96148"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96120"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96178"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96161"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96182"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96135"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96158"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96103"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96179"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96164"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96149"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96175"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96163"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96114"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96199"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96117"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96129"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96110"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96146"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96123"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96155"
  },
  {
    "NUTS3": "DE245",
    "CODE": "91332"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96196"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96167"
  },
  {
    "NUTS3": "DE245",
    "CODE": "96187"
  },
  {
    "NUTS3": "DE246",
    "CODE": "91347"
  },
  {
    "NUTS3": "DE246",
    "CODE": "96142"
  },
  {
    "NUTS3": "DE246",
    "CODE": "91344"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95515"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95491"
  },
  {
    "NUTS3": "DE246",
    "CODE": "91282"
  },
  {
    "NUTS3": "DE246",
    "CODE": "91278"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95490"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95488"
  },
  {
    "NUTS3": "DE246",
    "CODE": "91257"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95496"
  },
  {
    "NUTS3": "DE246",
    "CODE": "91287"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95503"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95503"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95496"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95511"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95500"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95473"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95494"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95463"
  },
  {
    "NUTS3": "DE246",
    "CODE": "91289"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95460"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95517"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95466"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95497"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95482"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95469"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95460"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95493"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95485"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95686"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95485"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95694"
  },
  {
    "NUTS3": "DE246",
    "CODE": "95694"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91352"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91336"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91353"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91301"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91330"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91094"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91099"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91090"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91077"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91361"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91365"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91320"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91369"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91356"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91358"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91364"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91359"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91338"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91362"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91322"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91338"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91346"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91349"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91327"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91367"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91355"
  },
  {
    "NUTS3": "DE248",
    "CODE": "91286"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95179"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95131"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95138"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95233"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95119"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95192"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95236"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95213"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95197"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95188"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95152"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95180"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95239"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95191"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95176"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95189"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95234"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95237"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95183"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95126"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95145"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95182"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95185"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95111"
  },
  {
    "NUTS3": "DE249",
    "CODE": "95194"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96277"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96268"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96328"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96355"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96342"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96332"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96317"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96361"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96369"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96337"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96352"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96364"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96358"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96349"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96367"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96346"
  },
  {
    "NUTS3": "DE24A",
    "CODE": "96365"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "96197"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95359"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95336"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95349"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95326"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95365"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95512"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95355"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95346"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95369"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95361"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95367"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95339"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95364"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95358"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95499"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95362"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95356"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95502"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95352"
  },
  {
    "NUTS3": "DE24B",
    "CODE": "95509"
  },
  {
    "NUTS3": "DE24C",
    "CODE": "96250"
  },
  {
    "NUTS3": "DE24C",
    "CODE": "96231"
  },
  {
    "NUTS3": "DE24C",
    "CODE": "96215"
  },
  {
    "NUTS3": "DE24C",
    "CODE": "96247"
  },
  {
    "NUTS3": "DE24C",
    "CODE": "96272"
  },
  {
    "NUTS3": "DE24C",
    "CODE": "96260"
  },
  {
    "NUTS3": "DE24C",
    "CODE": "96275"
  },
  {
    "NUTS3": "DE24C",
    "CODE": "96257"
  },
  {
    "NUTS3": "DE24C",
    "CODE": "96264"
  },
  {
    "NUTS3": "DE24C",
    "CODE": "96224"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95163"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95163"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95709"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95158"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95697"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95632"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95195"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95168"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95100"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95680"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95615"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95186"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95173"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95199"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95707"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95659"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95691"
  },
  {
    "NUTS3": "DE24D",
    "CODE": "95706"
  },
  {
    "NUTS3": "DE252",
    "CODE": "91056"
  },
  {
    "NUTS3": "DE252",
    "CODE": "91093"
  },
  {
    "NUTS3": "DE252",
    "CODE": "91058"
  },
  {
    "NUTS3": "DE252",
    "CODE": "91052"
  },
  {
    "NUTS3": "DE252",
    "CODE": "91054"
  },
  {
    "NUTS3": "DE253",
    "CODE": "90768"
  },
  {
    "NUTS3": "DE253",
    "CODE": "90766"
  },
  {
    "NUTS3": "DE253",
    "CODE": "90765"
  },
  {
    "NUTS3": "DE253",
    "CODE": "90762"
  },
  {
    "NUTS3": "DE253",
    "CODE": "90763"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90427"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90449"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90453"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90431"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90429"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90451"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90441"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90419"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90439"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90425"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90455"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90411"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90443"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90469"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90402"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90408"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90403"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90459"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90461"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90409"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90478"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90489"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90491"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90471"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90480"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90482"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90471"
  },
  {
    "NUTS3": "DE254",
    "CODE": "90473"
  },
  {
    "NUTS3": "DE255",
    "CODE": "91126"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91587"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91541"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91631"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91625"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91610"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91628"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91620"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91583"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91607"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91637"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91616"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91550"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91555"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91635"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91608"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91592"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91626"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91601"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91578"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91589"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91614"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91602"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91634"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91598"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91567"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91599"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91617"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91749"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91744"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91611"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91731"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91596"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91632"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91604"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91725"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91726"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91572"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91717"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91740"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91622"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91595"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91629"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91743"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91722"
  },
  {
    "NUTS3": "DE256",
    "CODE": "90599"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91746"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91737"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91623"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91586"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91732"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91580"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91590"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91639"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91560"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91575"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91564"
  },
  {
    "NUTS3": "DE256",
    "CODE": "91734"
  },
  {
    "NUTS3": "DE258",
    "CODE": "91452"
  },
  {
    "NUTS3": "DE258",
    "CODE": "90579"
  },
  {
    "NUTS3": "DE258",
    "CODE": "90613"
  },
  {
    "NUTS3": "DE258",
    "CODE": "90556"
  },
  {
    "NUTS3": "DE258",
    "CODE": "90617"
  },
  {
    "NUTS3": "DE258",
    "CODE": "90614"
  },
  {
    "NUTS3": "DE258",
    "CODE": "90574"
  },
  {
    "NUTS3": "DE258",
    "CODE": "90587"
  },
  {
    "NUTS3": "DE258",
    "CODE": "90513"
  },
  {
    "NUTS3": "DE258",
    "CODE": "90547"
  },
  {
    "NUTS3": "DE258",
    "CODE": "90522"
  },
  {
    "NUTS3": "DE259",
    "CODE": "90475"
  },
  {
    "NUTS3": "DE259",
    "CODE": "90537"
  },
  {
    "NUTS3": "DE259",
    "CODE": "90571"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91207"
  },
  {
    "NUTS3": "DE259",
    "CODE": "90552"
  },
  {
    "NUTS3": "DE259",
    "CODE": "90592"
  },
  {
    "NUTS3": "DE259",
    "CODE": "90607"
  },
  {
    "NUTS3": "DE259",
    "CODE": "90610"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91220"
  },
  {
    "NUTS3": "DE259",
    "CODE": "90559"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91227"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91245"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91233"
  },
  {
    "NUTS3": "DE259",
    "CODE": "90518"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91242"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91244"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91238"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91239"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91241"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91217"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91235"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91230"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91247"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91224"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91236"
  },
  {
    "NUTS3": "DE259",
    "CODE": "91284"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "97258"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "97215"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91605"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91465"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91478"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91593"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91484"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91477"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91613"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91438"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91471"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91477"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91483"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91443"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91619"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91472"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91413"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91463"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "96152"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91474"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91460"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91459"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91480"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "90619"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91456"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91481"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91468"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "90616"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91486"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91448"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91462"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91466"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91489"
  },
  {
    "NUTS3": "DE25A",
    "CODE": "91469"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "91174"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "91183"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "91189"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "91166"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "91187"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "91186"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "91154"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "91180"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "90530"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "90596"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "90455"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "91161"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "91177"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "90584"
  },
  {
    "NUTS3": "DE25B",
    "CODE": "91171"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91747"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91710"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91805"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91728"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91719"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91735"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91729"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91723"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91757"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91802"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91741"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91801"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91738"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91720"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91793"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91799"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91792"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91785"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91781"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91788"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91807"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91798"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91796"
  },
  {
    "NUTS3": "DE25C",
    "CODE": "91790"
  },
  {
    "NUTS3": "DE261",
    "CODE": "63741"
  },
  {
    "NUTS3": "DE261",
    "CODE": "63801"
  },
  {
    "NUTS3": "DE261",
    "CODE": "63743"
  },
  {
    "NUTS3": "DE261",
    "CODE": "63739"
  },
  {
    "NUTS3": "DE116",
    "CODE": "70736"
  },
  {
    "NUTS3": "DE116",
    "CODE": "70734"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71334"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71332"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71336"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71394"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71737"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71409"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71546"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71384"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71404"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71397"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71576"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71364"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71522"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71579"
  },
  {
    "NUTS3": "DE116",
    "CODE": "73630"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71570"
  },
  {
    "NUTS3": "DE116",
    "CODE": "73663"
  },
  {
    "NUTS3": "DE116",
    "CODE": "73650"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71573"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71397"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71560"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71554"
  },
  {
    "NUTS3": "DE116",
    "CODE": "73614"
  },
  {
    "NUTS3": "DE116",
    "CODE": "73635"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71549"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71577"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71566"
  },
  {
    "NUTS3": "DE116",
    "CODE": "71540"
  },
  {
    "NUTS3": "DE116",
    "CODE": "73660"
  },
  {
    "NUTS3": "DE116",
    "CODE": "73655"
  },
  {
    "NUTS3": "DE116",
    "CODE": "73642"
  },
  {
    "NUTS3": "DE116",
    "CODE": "73667"
  },
  {
    "NUTS3": "DE116",
    "CODE": "73553"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73547"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "74417"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73529"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73527"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73565"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73557"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73568"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73525"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73577"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73550"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73569"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73574"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73575"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73571"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73453"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73486"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73560"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73540"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73579"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73566"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73572"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73563"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73457"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73434"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73494"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73491"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73489"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73431"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73433"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73479"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73447"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73460"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73430"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73432"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73492"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73463"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73488"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73466"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73450"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73499"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73495"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73441"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73485"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73497"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73467"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73469"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73497"
  },
  {
    "NUTS3": "DE11D",
    "CODE": "73469"
  },
  {
    "NUTS3": "DE126",
    "CODE": "68229"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78187"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78647"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78607"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78554"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78194"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78591"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78549"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78594"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78606"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78665"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78532"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78588"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78595"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78559"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78573"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78604"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78586"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78582"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78564"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78583"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78589"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78585"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78601"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78576"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78570"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78592"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78598"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78600"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78603"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78580"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78579"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78567"
  },
  {
    "NUTS3": "DE137",
    "CODE": "78597"
  },
  {
    "NUTS3": "DE144",
    "CODE": "89079"
  },
  {
    "NUTS3": "DE144",
    "CODE": "89081"
  },
  {
    "NUTS3": "DE144",
    "CODE": "89081"
  },
  {
    "NUTS3": "DE144",
    "CODE": "89075"
  },
  {
    "NUTS3": "DE144",
    "CODE": "89077"
  },
  {
    "NUTS3": "DE144",
    "CODE": "89073"
  },
  {
    "NUTS3": "DE211",
    "CODE": "85049"
  },
  {
    "NUTS3": "DE211",
    "CODE": "85051"
  },
  {
    "NUTS3": "DE211",
    "CODE": "85057"
  },
  {
    "NUTS3": "DE211",
    "CODE": "85055"
  },
  {
    "NUTS3": "DE211",
    "CODE": "85053"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85235"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85250"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85254"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85259"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85253"
  },
  {
    "NUTS3": "DE217",
    "CODE": "86567"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85232"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85229"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85247"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85258"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85221"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85244"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85256"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85241"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85238"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85757"
  },
  {
    "NUTS3": "DE217",
    "CODE": "85778"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82467"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82442"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82488"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82491"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82435"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82475"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82497"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82487"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82433"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82490"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82449"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82496"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82445"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82438"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82418"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82481"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82493"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82441"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82447"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82494"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82499"
  },
  {
    "NUTS3": "DE21D",
    "CODE": "82439"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83620"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83052"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83075"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83104"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83043"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83059"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83098"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83064"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83080"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83109"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83088"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83126"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83539"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83544"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83135"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83543"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83533"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83561"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83115"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83101"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83131"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83569"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83071"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83134"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83122"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83556"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83564"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83512"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83083"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83549"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83139"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83137"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83547"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83128"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83093"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83112"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83229"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83123"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83253"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83209"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83129"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83233"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83125"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83254"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92275"
  },
  {
    "NUTS3": "DE234",
    "CODE": "91249"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92268"
  },
  {
    "NUTS3": "DE234",
    "CODE": "91275"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92262"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92281"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92259"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92280"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92278"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92265"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92237"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92265"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92289"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92277"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92249"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92256"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92260"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92284"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92245"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92274"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92271"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92286"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92266"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92287"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92242"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92266"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92272"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92263"
  },
  {
    "NUTS3": "DE234",
    "CODE": "92253"
  },
  {
    "NUTS3": "DE241",
    "CODE": "96049"
  },
  {
    "NUTS3": "DE241",
    "CODE": "96052"
  },
  {
    "NUTS3": "DE241",
    "CODE": "96047"
  },
  {
    "NUTS3": "DE241",
    "CODE": "96050"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96476"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96145"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96484"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96479"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96274"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96482"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96269"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96486"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96253"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96489"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96487"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96472"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96271"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96237"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96465"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96242"
  },
  {
    "NUTS3": "DE247",
    "CODE": "96279"
  },
  {
    "NUTS3": "DE251",
    "CODE": "91522"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91487"
  },
  {
    "NUTS3": "DE257",
    "CODE": "96193"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91475"
  },
  {
    "NUTS3": "DE257",
    "CODE": "96172"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91315"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91085"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91097"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91086"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91074"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91350"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91093"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91091"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91325"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91341"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91334"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91096"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91341"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91083"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91088"
  },
  {
    "NUTS3": "DE257",
    "CODE": "91080"
  },
  {
    "NUTS3": "DE257",
    "CODE": "90562"
  },
  {
    "NUTS3": "DE257",
    "CODE": "90411"
  },
  {
    "NUTS3": "DE257",
    "CODE": "90542"
  },
  {
    "NUTS3": "DE262",
    "CODE": "97424"
  },
  {
    "NUTS3": "DE262",
    "CODE": "97421"
  },
  {
    "NUTS3": "DE262",
    "CODE": "97422"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97318"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97337"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97340"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97320"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97320"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97342"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97332"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97334"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97348"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97350"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97359"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97320"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97348"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97355"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97346"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97353"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97348"
  },
  {
    "NUTS3": "DE268",
    "CODE": "97357"
  },
  {
    "NUTS3": "DE268",
    "CODE": "96160"
  },
  {
    "NUTS3": "DE273",
    "CODE": "87439"
  },
  {
    "NUTS3": "DE273",
    "CODE": "87435"
  },
  {
    "NUTS3": "DE273",
    "CODE": "87437"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89231"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89233"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89250"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89275"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89269"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89257"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89287"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89291"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89278"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89281"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89284"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89293"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89264"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89290"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89299"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89296"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89294"
  },
  {
    "NUTS3": "DE279",
    "CODE": "89297"
  },
  {
    "NUTS3": "DE401",
    "CODE": "14774"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14828"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14793"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14827"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14789"
  },
  {
    "NUTS3": "DE502",
    "CODE": "27576"
  },
  {
    "NUTS3": "DE502",
    "CODE": "27570"
  },
  {
    "NUTS3": "DE502",
    "CODE": "27578"
  },
  {
    "NUTS3": "DE502",
    "CODE": "27574"
  },
  {
    "NUTS3": "DE502",
    "CODE": "27578"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64404"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64390"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64342"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64367"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64372"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64397"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64380"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64409"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64846"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64859"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64807"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64839"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64401"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64405"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64354"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64823"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64853"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64832"
  },
  {
    "NUTS3": "DE716",
    "CODE": "64850"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63263"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63225"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63329"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63303"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63322"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63128"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63150"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63165"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63110"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63179"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63512"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63500"
  },
  {
    "NUTS3": "DE71C",
    "CODE": "63533"
  },
  {
    "NUTS3": "DE725",
    "CODE": "35315"
  },
  {
    "NUTS3": "DE725",
    "CODE": "35325"
  },
  {
    "NUTS3": "DE725",
    "CODE": "35329"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36320"
  },
  {
    "NUTS3": "DE725",
    "CODE": "63679"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36325"
  },
  {
    "NUTS3": "DE725",
    "CODE": "35327"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36329"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36326"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36304"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36369"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36318"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36358"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36355"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36341"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36399"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36323"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36367"
  },
  {
    "NUTS3": "DE725",
    "CODE": "36110"
  },
  {
    "NUTS3": "DE736",
    "CODE": "34508"
  },
  {
    "NUTS3": "DE736",
    "CODE": "35108"
  },
  {
    "NUTS3": "DE736",
    "CODE": "34519"
  },
  {
    "NUTS3": "DE736",
    "CODE": "35066"
  },
  {
    "NUTS3": "DE736",
    "CODE": "35104"
  },
  {
    "NUTS3": "DE736",
    "CODE": "35099"
  },
  {
    "NUTS3": "DE736",
    "CODE": "35119"
  },
  {
    "NUTS3": "DE736",
    "CODE": "34497"
  },
  {
    "NUTS3": "DE736",
    "CODE": "34516"
  },
  {
    "NUTS3": "DE736",
    "CODE": "35110"
  },
  {
    "NUTS3": "DE736",
    "CODE": "34477"
  },
  {
    "NUTS3": "DE736",
    "CODE": "35114"
  },
  {
    "NUTS3": "DE736",
    "CODE": "35285"
  },
  {
    "NUTS3": "DE736",
    "CODE": "34474"
  },
  {
    "NUTS3": "DE736",
    "CODE": "34454"
  },
  {
    "NUTS3": "DE736",
    "CODE": "34513"
  },
  {
    "NUTS3": "DE736",
    "CODE": "34537"
  },
  {
    "NUTS3": "DE736",
    "CODE": "34549"
  },
  {
    "NUTS3": "DE736",
    "CODE": "34471"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23923"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "19217"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23942"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "19205"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23936"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23948"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "19209"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23946"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23946"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "19071"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23968"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23966"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "19069"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23996"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23999"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23972"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23966"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23970"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23966"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23974"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "19417"
  },
  {
    "NUTS3": "DE80M",
    "CODE": "23992"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38536"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38539"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38543"
  },
  {
    "NUTS3": "DE914",
    "CODE": "29367"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38542"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38530"
  },
  {
    "NUTS3": "DE914",
    "CODE": "29393"
  },
  {
    "NUTS3": "DE914",
    "CODE": "29369"
  },
  {
    "NUTS3": "DE914",
    "CODE": "29365"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38179"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38528"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38518"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38551"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38533"
  },
  {
    "NUTS3": "DE914",
    "CODE": "29386"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38559"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38531"
  },
  {
    "NUTS3": "DE914",
    "CODE": "29392"
  },
  {
    "NUTS3": "DE914",
    "CODE": "29399"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38527"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38550"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38524"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38553"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38547"
  },
  {
    "NUTS3": "DE914",
    "CODE": "29396"
  },
  {
    "NUTS3": "DE914",
    "CODE": "29379"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38557"
  },
  {
    "NUTS3": "DE914",
    "CODE": "29378"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38556"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38554"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38468"
  },
  {
    "NUTS3": "DE914",
    "CODE": "29396"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38477"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38479"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38476"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38473"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38465"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38471"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38467"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38474"
  },
  {
    "NUTS3": "DE914",
    "CODE": "38470"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27259"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27211"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27248"
  },
  {
    "NUTS3": "DE922",
    "CODE": "28816"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27232"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27251"
  },
  {
    "NUTS3": "DE922",
    "CODE": "28857"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27245"
  },
  {
    "NUTS3": "DE922",
    "CODE": "28844"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27252"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27257"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27249"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27305"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27330"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27330"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27246"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27254"
  },
  {
    "NUTS3": "DE922",
    "CODE": "27327"
  },
  {
    "NUTS3": "DE929",
    "CODE": "31535"
  },
  {
    "NUTS3": "DE929",
    "CODE": "31515"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30890"
  },
  {
    "NUTS3": "DE929",
    "CODE": "31832"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30974"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30826"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30926"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30823"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30989"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30900"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30827"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30419"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30952"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30855"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30455"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30453"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30457"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30966"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30669"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30167"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30982"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30459"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30451"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30165"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30449"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30851"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30179"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30169"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30853"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30159"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30161"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30163"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30519"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30173"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30171"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30175"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30161"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30159"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30177"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30657"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30880"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30916"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30625"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30655"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30938"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30559"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30659"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30521"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30539"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30627"
  },
  {
    "NUTS3": "DE929",
    "CODE": "30629"
  },
  {
    "NUTS3": "DE929",
    "CODE": "31319"
  },
  {
    "NUTS3": "DE929",
    "CODE": "31275"
  },
  {
    "NUTS3": "DE929",
    "CODE": "31303"
  },
  {
    "NUTS3": "DE929",
    "CODE": "31311"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27432"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27442"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27412"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27446"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27404"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27367"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27356"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27386"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27356"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27383"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27419"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27374"
  },
  {
    "NUTS3": "DE937",
    "CODE": "27389"
  },
  {
    "NUTS3": "DEA43",
    "CODE": "32051"
  },
  {
    "NUTS3": "DEA43",
    "CODE": "32278"
  },
  {
    "NUTS3": "DEA43",
    "CODE": "32120"
  },
  {
    "NUTS3": "DEA43",
    "CODE": "32052"
  },
  {
    "NUTS3": "DEA43",
    "CODE": "32584"
  },
  {
    "NUTS3": "DEA43",
    "CODE": "32049"
  },
  {
    "NUTS3": "DEA43",
    "CODE": "32602"
  },
  {
    "NUTS3": "DEE01",
    "CODE": "06847"
  },
  {
    "NUTS3": "DEE01",
    "CODE": "06862"
  },
  {
    "NUTS3": "DEE01",
    "CODE": "06846"
  },
  {
    "NUTS3": "DEE01",
    "CODE": "06861"
  },
  {
    "NUTS3": "DEE01",
    "CODE": "06862"
  },
  {
    "NUTS3": "DEE01",
    "CODE": "06842"
  },
  {
    "NUTS3": "DEE01",
    "CODE": "06844"
  },
  {
    "NUTS3": "DEE01",
    "CODE": "06842"
  },
  {
    "NUTS3": "DEE01",
    "CODE": "06849"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39646"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39393"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39356"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39365"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39359"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39397"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39343"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39387"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39345"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39164"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39340"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39167"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39171"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39326"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39343"
  },
  {
    "NUTS3": "DEE07",
    "CODE": "39179"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06868"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06785"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06869"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06772"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06773"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21521"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21529"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21502"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23847"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21526"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "22958"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "22929"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21524"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "22929"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "22946"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21481"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21493"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "22959"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21527"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21483"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23898"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21502"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23881"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21516"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23896"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21514"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23919"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "21516"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23628"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23881"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23879"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23911"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23883"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23909"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23627"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23899"
  },
  {
    "NUTS3": "DEF06",
    "CODE": "23911"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "25563"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24576"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24632"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24649"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24568"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "25486"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "25479"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24623"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24558"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "22848"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24640"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "22846"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "22844"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24598"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "22850"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24629"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "22851"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24626"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24643"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24628"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23812"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24641"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23826"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23845"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23866"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24635"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24610"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23816"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23867"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23795"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23829"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "24638"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23824"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23813"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23827"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23845"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23823"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23818"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23821"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23815"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23820"
  },
  {
    "NUTS3": "DEF0D",
    "CODE": "23719"
  },
  {
    "NUTS3": "DEG04",
    "CODE": "98529"
  },
  {
    "NUTS3": "DEG04",
    "CODE": "98527"
  },
  {
    "NUTS3": "DEG04",
    "CODE": "98528"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99880"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99891"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99869"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99894"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99897"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99887"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99867"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99897"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99885"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99958"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99100"
  },
  {
    "NUTS3": "DEG0C",
    "CODE": "99192"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "98746"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "07426"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "98744"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "07429"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "07427"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "07407"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "07422"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "07318"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "98743"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "07330"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "07333"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "07338"
  },
  {
    "NUTS3": "DEG0I",
    "CODE": "07349"
  },
  {
    "NUTS3": "DE263",
    "CODE": "97082"
  },
  {
    "NUTS3": "DE263",
    "CODE": "97080"
  },
  {
    "NUTS3": "DE263",
    "CODE": "97084"
  },
  {
    "NUTS3": "DE263",
    "CODE": "97070"
  },
  {
    "NUTS3": "DE263",
    "CODE": "97072"
  },
  {
    "NUTS3": "DE263",
    "CODE": "97078"
  },
  {
    "NUTS3": "DE263",
    "CODE": "97074"
  },
  {
    "NUTS3": "DE263",
    "CODE": "97076"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63791"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63796"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63811"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63762"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63755"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63801"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63814"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63867"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63776"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63864"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63773"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63826"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63768"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63829"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63808"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63825"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63856"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63877"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63872"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63846"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63828"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63875"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63857"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63874"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63869"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63831"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63871"
  },
  {
    "NUTS3": "DE264",
    "CODE": "97833"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63879"
  },
  {
    "NUTS3": "DE264",
    "CODE": "63860"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97799"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97797"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97769"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97786"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97772"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97789"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97795"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97762"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97792"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97723"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97779"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97705"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97727"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97725"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97688"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97717"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97708"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97729"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97720"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97714"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97702"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97517"
  },
  {
    "NUTS3": "DE265",
    "CODE": "97711"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97657"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97653"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97656"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97659"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97647"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97650"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97618"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97645"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97724"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97654"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97616"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97618"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97638"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97640"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97640"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97638"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97633"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97631"
  },
  {
    "NUTS3": "DE266",
    "CODE": "97528"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97503"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97519"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97531"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97491"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97539"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97437"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97486"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97478"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97494"
  },
  {
    "NUTS3": "DE267",
    "CODE": "96181"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97461"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97514"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97475"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97522"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97496"
  },
  {
    "NUTS3": "DE267",
    "CODE": "96126"
  },
  {
    "NUTS3": "DE267",
    "CODE": "96106"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97483"
  },
  {
    "NUTS3": "DE267",
    "CODE": "97500"
  },
  {
    "NUTS3": "DE267",
    "CODE": "96166"
  },
  {
    "NUTS3": "DE267",
    "CODE": "96151"
  },
  {
    "NUTS3": "DE267",
    "CODE": "96176"
  },
  {
    "NUTS3": "DE267",
    "CODE": "96188"
  },
  {
    "NUTS3": "DE267",
    "CODE": "96184"
  },
  {
    "NUTS3": "DE267",
    "CODE": "96190"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63853"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63931"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63785"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63916"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63937"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63843"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63868"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63939"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63897"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63911"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63906"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63924"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63925"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63834"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63820"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63839"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63920"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63849"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63840"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63933"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63934"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63863"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63936"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63916"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63927"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63936"
  },
  {
    "NUTS3": "DE269",
    "CODE": "97903"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63928"
  },
  {
    "NUTS3": "DE269",
    "CODE": "97904"
  },
  {
    "NUTS3": "DE269",
    "CODE": "97901"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63930"
  },
  {
    "NUTS3": "DE269",
    "CODE": "97909"
  },
  {
    "NUTS3": "DE269",
    "CODE": "97906"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97843"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97859"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97840"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97836"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97852"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97907"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97816"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97848"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97846"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97839"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97773"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97892"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97845"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97828"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97778"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97851"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97839"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97839"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97855"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97839"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97839"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97775"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97737"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97791"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97788"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97849"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97794"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97785"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97837"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97854"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97842"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97753"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97857"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97834"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97782"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97225"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97780"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97783"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97267"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97776"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97289"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97282"
  },
  {
    "NUTS3": "DE26A",
    "CODE": "97450"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97535"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97440"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97502"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97490"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97505"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97534"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97523"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97464"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97493"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97537"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97456"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97506"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97520"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97509"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97532"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97469"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97525"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97526"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97453"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97508"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97447"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97529"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97511"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97488"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97499"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97516"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97497"
  },
  {
    "NUTS3": "DE26B",
    "CODE": "97513"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97277"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97264"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97292"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97280"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97237"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97259"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97274"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97297"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97295"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97265"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97270"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97249"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97244"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97268"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97271"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97299"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97250"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97276"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97291"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97204"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97234"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97256"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97261"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97209"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97232"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97283"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97222"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97285"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97255"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97236"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97253"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97286"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97243"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97218"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97294"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97199"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97246"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97230"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97262"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97228"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97288"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97239"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97273"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97241"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97252"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97279"
  },
  {
    "NUTS3": "DE26C",
    "CODE": "97247"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86199"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86156"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86157"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86179"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86154"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86159"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86152"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86150"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86153"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86169"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86161"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86167"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86165"
  },
  {
    "NUTS3": "DE271",
    "CODE": "86163"
  },
  {
    "NUTS3": "DE272",
    "CODE": "87600"
  },
  {
    "NUTS3": "DE274",
    "CODE": "87700"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86447"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86508"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86444"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86511"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86504"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86674"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86316"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86415"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86438"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86554"
  },
  {
    "NUTS3": "DE275",
    "CODE": "82297"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86574"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86453"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86510"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86551"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86573"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86568"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86495"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86570"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86559"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86577"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86556"
  },
  {
    "NUTS3": "DE275",
    "CODE": "86576"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86424"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86441"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86450"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86868"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86877"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86863"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86850"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86866"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86465"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86872"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86514"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86497"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86845"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86494"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86500"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86830"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86459"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86399"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86486"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86477"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86853"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86856"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86465"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86485"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86420"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86482"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86368"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86456"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86356"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86517"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86405"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86678"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86707"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86462"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86695"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86836"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86391"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86507"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86679"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86343"
  },
  {
    "NUTS3": "DE276",
    "CODE": "86672"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89428"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89447"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89423"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89441"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89429"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89431"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89437"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89415"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89446"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89426"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89344"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89407"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89435"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89353"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89438"
  },
  {
    "NUTS3": "DE277",
    "CODE": "86657"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89440"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89420"
  },
  {
    "NUTS3": "DE277",
    "CODE": "86637"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89434"
  },
  {
    "NUTS3": "DE277",
    "CODE": "89443"
  },
  {
    "NUTS3": "DE277",
    "CODE": "86647"
  },
  {
    "NUTS3": "DE277",
    "CODE": "86502"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89340"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89346"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89335"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89347"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89312"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89359"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89367"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89352"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86488"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86519"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86491"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86489"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89362"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86480"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86476"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89358"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89364"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89331"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86381"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89355"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89349"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89350"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89343"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86505"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86513"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89356"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89365"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86470"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86483"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89368"
  },
  {
    "NUTS3": "DE278",
    "CODE": "89361"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86473"
  },
  {
    "NUTS3": "DE278",
    "CODE": "86479"
  },
  {
    "NUTS3": "DE27A",
    "CODE": "88149"
  },
  {
    "NUTS3": "DE27A",
    "CODE": "88142"
  },
  {
    "NUTS3": "DE27A",
    "CODE": "88131"
  },
  {
    "NUTS3": "DE27A",
    "CODE": "88138"
  },
  {
    "NUTS3": "DE27A",
    "CODE": "88145"
  },
  {
    "NUTS3": "DE27A",
    "CODE": "88175"
  },
  {
    "NUTS3": "DE27A",
    "CODE": "88178"
  },
  {
    "NUTS3": "DE27A",
    "CODE": "88161"
  },
  {
    "NUTS3": "DE27A",
    "CODE": "88171"
  },
  {
    "NUTS3": "DE27A",
    "CODE": "88179"
  },
  {
    "NUTS3": "DE27A",
    "CODE": "88167"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87496"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87671"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87634"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87647"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87653"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87484"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87459"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87657"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87648"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87654"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87650"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87494"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87616"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87660"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87674"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87648"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87637"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87663"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87666"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87629"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87640"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87659"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87668"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87656"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "86860"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87665"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87672"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87675"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87669"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87651"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "86807"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87677"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87679"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "86869"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87645"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "86862"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87662"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "87642"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "86983"
  },
  {
    "NUTS3": "DE27B",
    "CODE": "86875"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87763"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87764"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87758"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87740"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87751"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87748"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87773"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87789"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87767"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87737"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87730"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87734"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87785"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87779"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87766"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87727"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87760"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "86498"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87752"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87787"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87761"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87743"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87749"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87781"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87784"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87770"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87724"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87736"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87755"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87746"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87776"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87733"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87739"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87769"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87754"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87757"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87778"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87772"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87782"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87719"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87742"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87775"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "87745"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "86874"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "86871"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "86865"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "86825"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "86842"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "86833"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "86879"
  },
  {
    "NUTS3": "DE27C",
    "CODE": "86854"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86742"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86735"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86739"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86757"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86720"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86748"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86745"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86747"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86756"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86741"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86736"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86753"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86732"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86738"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86751"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86759"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86754"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86733"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86744"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86655"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86660"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86750"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86650"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86685"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86609"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86709"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86681"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86687"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86690"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86700"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86663"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86653"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86675"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86682"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86698"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86688"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86694"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86641"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86692"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86704"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86684"
  },
  {
    "NUTS3": "DE27D",
    "CODE": "86703"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87534"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87538"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87547"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87452"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87480"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87561"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87544"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87474"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87509"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87487"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87448"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87463"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87527"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87549"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87545"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87493"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87477"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87490"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87471"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87541"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87466"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87497"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87488"
  },
  {
    "NUTS3": "DE27E",
    "CODE": "87499"
  },
  {
    "NUTS3": "DE408",
    "CODE": "14715"
  },
  {
    "NUTS3": "DE408",
    "CODE": "14712"
  },
  {
    "NUTS3": "DE408",
    "CODE": "14728"
  },
  {
    "NUTS3": "DE408",
    "CODE": "14727"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16866"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16845"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16845"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16909"
  },
  {
    "NUTS3": "DE40F",
    "CODE": "19309"
  },
  {
    "NUTS3": "DE40F",
    "CODE": "19357"
  },
  {
    "NUTS3": "DE40F",
    "CODE": "19322"
  },
  {
    "NUTS3": "DE40F",
    "CODE": "19348"
  },
  {
    "NUTS3": "DE40F",
    "CODE": "19348"
  },
  {
    "NUTS3": "DE40F",
    "CODE": "19322"
  },
  {
    "NUTS3": "DE40F",
    "CODE": "19336"
  },
  {
    "NUTS3": "DE40F",
    "CODE": "16928"
  },
  {
    "NUTS3": "DE40F",
    "CODE": "19339"
  },
  {
    "NUTS3": "DE40F",
    "CODE": "16949"
  },
  {
    "NUTS3": "DE40F",
    "CODE": "16945"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28755"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28757"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28719"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28759"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28197"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28237"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28717"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28259"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28239"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28217"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28199"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28219"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28357"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28201"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28195"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28277"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28215"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28203"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28209"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28279"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28359"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28213"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28211"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28205"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28207"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28329"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28309"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28327"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28307"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28355"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28329"
  },
  {
    "NUTS3": "DE501",
    "CODE": "28325"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22559"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22587"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21129"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22589"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21147"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21149"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22609"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22549"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22547"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22607"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22605"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22523"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22609"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22525"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21079"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22761"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22457"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22763"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21075"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22527"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21077"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22459"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22765"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22767"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22455"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22453"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20457"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22457"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22769"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22529"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20257"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21107"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22453"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20255"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20259"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20359"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21073"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22453"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22335"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20253"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20357"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22767"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20144"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20251"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20459"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20355"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20146"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20249"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20149"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20354"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22297"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22415"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20148"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22419"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20095"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22299"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21109"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22301"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20099"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20539"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22417"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22085"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22303"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20097"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22087"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22339"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22337"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22083"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22415"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20535"
  },
  {
    "NUTS3": "DE600",
    "CODE": "20537"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22081"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22089"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22307"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22305"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22309"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21037"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22399"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22049"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22177"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22391"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22041"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22113"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22179"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22397"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22043"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22111"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22175"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22047"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22119"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22395"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22393"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22159"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21035"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22045"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22117"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22889"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21033"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22147"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22115"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22149"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22359"
  },
  {
    "NUTS3": "DE600",
    "CODE": "22143"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21031"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21039"
  },
  {
    "NUTS3": "DE600",
    "CODE": "21029"
  },
  {
    "NUTS3": "DE711",
    "CODE": "64295"
  },
  {
    "NUTS3": "DE711",
    "CODE": "64293"
  },
  {
    "NUTS3": "DE711",
    "CODE": "64297"
  },
  {
    "NUTS3": "DE711",
    "CODE": "64291"
  },
  {
    "NUTS3": "DE711",
    "CODE": "64285"
  },
  {
    "NUTS3": "DE711",
    "CODE": "64283"
  },
  {
    "NUTS3": "DE711",
    "CODE": "64289"
  },
  {
    "NUTS3": "DE711",
    "CODE": "64287"
  },
  {
    "NUTS3": "DE712",
    "CODE": "65934"
  },
  {
    "NUTS3": "DE712",
    "CODE": "65933"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60488"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60489"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60439"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60528"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60486"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60326"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60438"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60327"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60487"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60437"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60431"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60325"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60433"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60308"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60323"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60596"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60598"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60329"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60320"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60322"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60306"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60311"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60313"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60310"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60435"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60594"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60318"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60316"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60599"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60389"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60314"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60385"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60386"
  },
  {
    "NUTS3": "DE712",
    "CODE": "60388"
  },
  {
    "NUTS3": "DE713",
    "CODE": "63069"
  },
  {
    "NUTS3": "DE713",
    "CODE": "63067"
  },
  {
    "NUTS3": "DE713",
    "CODE": "63065"
  },
  {
    "NUTS3": "DE713",
    "CODE": "63071"
  },
  {
    "NUTS3": "DE713",
    "CODE": "63071"
  },
  {
    "NUTS3": "DE713",
    "CODE": "63071"
  },
  {
    "NUTS3": "DE713",
    "CODE": "63075"
  },
  {
    "NUTS3": "DE713",
    "CODE": "63073"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64673"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64646"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64686"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64646"
  },
  {
    "NUTS3": "DE715",
    "CODE": "69488"
  },
  {
    "NUTS3": "DE715",
    "CODE": "69509"
  },
  {
    "NUTS3": "DE715",
    "CODE": "69517"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64668"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64658"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64678"
  },
  {
    "NUTS3": "DE715",
    "CODE": "69518"
  },
  {
    "NUTS3": "DE715",
    "CODE": "69483"
  },
  {
    "NUTS3": "DE715",
    "CODE": "69239"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64689"
  },
  {
    "NUTS3": "DE715",
    "CODE": "64757"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61381"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61348"
  },
  {
    "NUTS3": "DE718",
    "CODE": "61352"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63477"
  },
  {
    "NUTS3": "DE719",
    "CODE": "61138"
  },
  {
    "NUTS3": "DE719",
    "CODE": "61137"
  },
  {
    "NUTS3": "DE719",
    "CODE": "61130"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63454"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63456"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63486"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63452"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63450"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63457"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63546"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63538"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63543"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63526"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63517"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63505"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63549"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63594"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63584"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63579"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63571"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63589"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63607"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63636"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63599"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63633"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63619"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63628"
  },
  {
    "NUTS3": "DE719",
    "CODE": "36396"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63637"
  },
  {
    "NUTS3": "DE719",
    "CODE": "63639"
  },
  {
    "NUTS3": "DE719",
    "CODE": "36381"
  },
  {
    "NUTS3": "DE719",
    "CODE": "36391"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64385"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64407"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64395"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64756"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64760"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64753"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64732"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64739"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64720"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64711"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64747"
  },
  {
    "NUTS3": "DE71B",
    "CODE": "64750"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61191"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61239"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61169"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "35516"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61184"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61118"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61231"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "35519"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61206"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61200"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61194"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61203"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61197"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "61209"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "63674"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "63667"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "63691"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "63694"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "63695"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "63683"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "63654"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "63697"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "63699"
  },
  {
    "NUTS3": "DE71E",
    "CODE": "63688"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35398"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35452"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35435"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35440"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35457"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35392"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35390"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35396"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35394"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35415"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35460"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35418"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35423"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35463"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35469"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35410"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35447"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35466"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35305"
  },
  {
    "NUTS3": "DE721",
    "CODE": "35321"
  },
  {
    "NUTS3": "DE722",
    "CODE": "35582"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35083"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35117"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35094"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35041"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35096"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35112"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35043"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35037"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35091"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35085"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35039"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35282"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35274"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35287"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35288"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35260"
  },
  {
    "NUTS3": "DE724",
    "CODE": "35279"
  },
  {
    "NUTS3": "DE731",
    "CODE": "34131"
  },
  {
    "NUTS3": "DE731",
    "CODE": "34132"
  },
  {
    "NUTS3": "DE731",
    "CODE": "34128"
  },
  {
    "NUTS3": "DE731",
    "CODE": "34130"
  },
  {
    "NUTS3": "DE731",
    "CODE": "34134"
  },
  {
    "NUTS3": "DE731",
    "CODE": "34119"
  },
  {
    "NUTS3": "DE731",
    "CODE": "34121"
  },
  {
    "NUTS3": "DE731",
    "CODE": "34127"
  },
  {
    "NUTS3": "DE731",
    "CODE": "34117"
  },
  {
    "NUTS3": "DE731",
    "CODE": "34123"
  },
  {
    "NUTS3": "DE731",
    "CODE": "34125"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36154"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36119"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36364"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36137"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36103"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36041"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36151"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36148"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36088"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36039"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36124"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36043"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36037"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36100"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36093"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36132"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36157"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36145"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36160"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36167"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36129"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36163"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36169"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36115"
  },
  {
    "NUTS3": "DE732",
    "CODE": "36142"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36287"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36286"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36275"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36272"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36251"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36211"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36166"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36199"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36282"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36179"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36277"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36219"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36289"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36217"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36214"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36284"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36266"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36269"
  },
  {
    "NUTS3": "DE733",
    "CODE": "36208"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34466"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34311"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34479"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34289"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34308"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34396"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34289"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34270"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34379"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34317"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34369"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34388"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34225"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34393"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34292"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34385"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34314"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34246"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34376"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34277"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34233"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34399"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34320"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34253"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34399"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34266"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34399"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34260"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34359"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34298"
  },
  {
    "NUTS3": "DE734",
    "CODE": "34329"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34630"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34632"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34596"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34613"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34628"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34599"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34560"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34582"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34637"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34621"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34305"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34590"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34626"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34281"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34633"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34295"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34576"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34587"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34639"
  },
  {
    "NUTS3": "DE735",
    "CODE": "36280"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34323"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34593"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34302"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34327"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34212"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34326"
  },
  {
    "NUTS3": "DE735",
    "CODE": "34286"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37235"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37216"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37217"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37247"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37284"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37247"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37218"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37213"
  },
  {
    "NUTS3": "DE737",
    "CODE": "36205"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37215"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37290"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37249"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37242"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37297"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37214"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37287"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37269"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37296"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37276"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37293"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37299"
  },
  {
    "NUTS3": "DE737",
    "CODE": "37281"
  },
  {
    "NUTS3": "DE803",
    "CODE": "18069"
  },
  {
    "NUTS3": "DE803",
    "CODE": "18119"
  },
  {
    "NUTS3": "DE803",
    "CODE": "18106"
  },
  {
    "NUTS3": "DE803",
    "CODE": "18109"
  },
  {
    "NUTS3": "DE803",
    "CODE": "18057"
  },
  {
    "NUTS3": "DE803",
    "CODE": "18147"
  },
  {
    "NUTS3": "DE803",
    "CODE": "18055"
  },
  {
    "NUTS3": "DE803",
    "CODE": "18146"
  },
  {
    "NUTS3": "DE804",
    "CODE": "19057"
  },
  {
    "NUTS3": "DE804",
    "CODE": "19061"
  },
  {
    "NUTS3": "DE804",
    "CODE": "19055"
  },
  {
    "NUTS3": "DE804",
    "CODE": "19059"
  },
  {
    "NUTS3": "DE804",
    "CODE": "19053"
  },
  {
    "NUTS3": "DE804",
    "CODE": "19055"
  },
  {
    "NUTS3": "DE804",
    "CODE": "19063"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17209"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17214"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17213"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18230"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18233"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18225"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18236"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18246"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18209"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18239"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18249"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18211"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18211"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18198"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18276"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18107"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18258"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18059"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18196"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18273"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18299"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18292"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18182"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18181"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18184"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18279"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18190"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "18195"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18347"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18311"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18337"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19258"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19246"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19260"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19243"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19249"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19230"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19303"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19073"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19288"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19294"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19075"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19077"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19300"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19086"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19067"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19079"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19065"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19306"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19089"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19412"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19372"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19374"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19370"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19406"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19376"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19372"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19386"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19399"
  },
  {
    "NUTS3": "DE80O",
    "CODE": "19395"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38112"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38116"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38120"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38122"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38110"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38114"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38118"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38124"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38100"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38106"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38102"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38108"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38126"
  },
  {
    "NUTS3": "DE911",
    "CODE": "38104"
  },
  {
    "NUTS3": "DE912",
    "CODE": "38228"
  },
  {
    "NUTS3": "DE912",
    "CODE": "38229"
  },
  {
    "NUTS3": "DE912",
    "CODE": "38259"
  },
  {
    "NUTS3": "DE912",
    "CODE": "38226"
  },
  {
    "NUTS3": "DE912",
    "CODE": "38239"
  },
  {
    "NUTS3": "DE913",
    "CODE": "38442"
  },
  {
    "NUTS3": "DE913",
    "CODE": "38444"
  },
  {
    "NUTS3": "DE913",
    "CODE": "38440"
  },
  {
    "NUTS3": "DE913",
    "CODE": "38448"
  },
  {
    "NUTS3": "DE913",
    "CODE": "38446"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38723"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38729"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38685"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38678"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38709"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38704"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38644"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38707"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38640"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38642"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38642"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38690"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38642"
  },
  {
    "NUTS3": "DE916",
    "CODE": "37444"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38644"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38644"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38667"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38644"
  },
  {
    "NUTS3": "DE916",
    "CODE": "38700"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38165"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38154"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38384"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38375"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38154"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38382"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38387"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38381"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38464"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38376"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38373"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38461"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38368"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38364"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38378"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38154"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38458"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38462"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38350"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38379"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38459"
  },
  {
    "NUTS3": "DE917",
    "CODE": "38372"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37194"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37170"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37586"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37170"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37186"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37181"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37574"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37176"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37154"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37581"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37589"
  },
  {
    "NUTS3": "DE918",
    "CODE": "37191"
  },
  {
    "NUTS3": "DE91A",
    "CODE": "31249"
  },
  {
    "NUTS3": "DE91A",
    "CODE": "31226"
  },
  {
    "NUTS3": "DE91A",
    "CODE": "31241"
  },
  {
    "NUTS3": "DE91A",
    "CODE": "31228"
  },
  {
    "NUTS3": "DE91A",
    "CODE": "31234"
  },
  {
    "NUTS3": "DE91A",
    "CODE": "31246"
  },
  {
    "NUTS3": "DE91A",
    "CODE": "31224"
  },
  {
    "NUTS3": "DE91A",
    "CODE": "38268"
  },
  {
    "NUTS3": "DE91A",
    "CODE": "38176"
  },
  {
    "NUTS3": "DE91A",
    "CODE": "38159"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38271"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38272"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38279"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38277"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38274"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38275"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38312"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38304"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38315"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38302"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38300"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38321"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38324"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38162"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38173"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38319"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38329"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38322"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38170"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38327"
  },
  {
    "NUTS3": "DE91B",
    "CODE": "38325"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "34355"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "34346"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37127"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37139"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37124"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37079"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37133"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37120"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37081"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37083"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37073"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37077"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37075"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37130"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37085"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37085"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37136"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37434"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37520"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37115"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37199"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37539"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37197"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37197"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37412"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37431"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37445"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37412"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37441"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31855"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31840"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31812"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31787"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31860"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31789"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31848"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31785"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31789"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31789"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31789"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31863"
  },
  {
    "NUTS3": "DE923",
    "CODE": "31020"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31008"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31089"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31036"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31171"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31028"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31061"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31157"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31079"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31180"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31084"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31139"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31199"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31079"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31195"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31137"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31191"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31177"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31134"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31084"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31135"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31141"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31162"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31174"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31167"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31188"
  },
  {
    "NUTS3": "DE925",
    "CODE": "31185"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37647"
  },
  {
    "NUTS3": "DE926",
    "CODE": "31868"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37649"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37697"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37691"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37699"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37619"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37603"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37639"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37640"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37691"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37627"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37620"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37643"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37642"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37632"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37633"
  },
  {
    "NUTS3": "DE926",
    "CODE": "37635"
  },
  {
    "NUTS3": "DE926",
    "CODE": "31073"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31603"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31600"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31606"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31595"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31604"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31592"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31621"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31613"
  },
  {
    "NUTS3": "DE927",
    "CODE": "27318"
  },
  {
    "NUTS3": "DE927",
    "CODE": "27333"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31618"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31619"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31608"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31633"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31628"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31629"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31609"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31547"
  },
  {
    "NUTS3": "DE927",
    "CODE": "27324"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31582"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31623"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31632"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31626"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31627"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31622"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31638"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31636"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31634"
  },
  {
    "NUTS3": "DE927",
    "CODE": "31637"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31675"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31737"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31711"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31691"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31715"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31707"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31708"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31693"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31683"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31719"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31712"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31710"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31655"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31749"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31688"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31718"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31717"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31556"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31714"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31553"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31702"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31552"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31700"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31698"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31558"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31699"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31867"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31542"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31559"
  },
  {
    "NUTS3": "DE928",
    "CODE": "31555"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29308"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29323"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29303"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29313"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29352"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29225"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29320"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29223"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29229"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29227"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29336"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29328"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29221"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29339"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29342"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29348"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29358"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29361"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29356"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29345"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29359"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29331"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29355"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29353"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29364"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29364"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29351"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29362"
  },
  {
    "NUTS3": "DE931",
    "CODE": "29364"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27476"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27478"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27619"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27474"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27616"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27472"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27624"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21776"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21765"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21775"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21762"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21763"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21772"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21785"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21782"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21769"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21770"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21789"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21781"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21745"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21787"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21756"
  },
  {
    "NUTS3": "DE932",
    "CODE": "21755"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21646"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21258"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21255"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21649"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21279"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21647"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21259"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21629"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21261"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21244"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21244"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21256"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21224"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21274"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21266"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21271"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21218"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21228"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21227"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21439"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21217"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21272"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21220"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21438"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21376"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21442"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21435"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21423"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21445"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21441"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21444"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21444"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21423"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21436"
  },
  {
    "NUTS3": "DE933",
    "CODE": "21395"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29473"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29465"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29499"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29459"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29490"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29496"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29456"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29468"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29481"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29482"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29479"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29487"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29451"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29462"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29439"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29472"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29476"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29488"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29497"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29494"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29484"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29485"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29491"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29471"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29475"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29478"
  },
  {
    "NUTS3": "DE934",
    "CODE": "29493"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21385"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21388"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21394"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21449"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21423"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21391"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21358"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21386"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21391"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21409"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21360"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21447"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21357"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21339"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21406"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21335"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21382"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21337"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21407"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21365"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21403"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21380"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21379"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21397"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21522"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21400"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21398"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21401"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21354"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21368"
  },
  {
    "NUTS3": "DE935",
    "CODE": "19273"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21369"
  },
  {
    "NUTS3": "DE935",
    "CODE": "21371"
  },
  {
    "NUTS3": "DE936",
    "CODE": "28755"
  },
  {
    "NUTS3": "DE936",
    "CODE": "27711"
  },
  {
    "NUTS3": "DE936",
    "CODE": "27721"
  },
  {
    "NUTS3": "DE936",
    "CODE": "27729"
  },
  {
    "NUTS3": "DE936",
    "CODE": "28865"
  },
  {
    "NUTS3": "DE936",
    "CODE": "27726"
  },
  {
    "NUTS3": "DE936",
    "CODE": "28879"
  },
  {
    "NUTS3": "DE938",
    "CODE": "27336"
  },
  {
    "NUTS3": "DE938",
    "CODE": "29664"
  },
  {
    "NUTS3": "DE938",
    "CODE": "29693"
  },
  {
    "NUTS3": "DE938",
    "CODE": "29690"
  },
  {
    "NUTS3": "DE938",
    "CODE": "29699"
  },
  {
    "NUTS3": "DE938",
    "CODE": "29643"
  },
  {
    "NUTS3": "DE938",
    "CODE": "29640"
  },
  {
    "NUTS3": "DE938",
    "CODE": "29683"
  },
  {
    "NUTS3": "DE938",
    "CODE": "29614"
  },
  {
    "NUTS3": "DE938",
    "CODE": "29649"
  },
  {
    "NUTS3": "DE938",
    "CODE": "29646"
  },
  {
    "NUTS3": "DE938",
    "CODE": "29633"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21730"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21734"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21726"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21727"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21732"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21712"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21709"
  },
  {
    "NUTS3": "DE939",
    "CODE": "27449"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21706"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21729"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21710"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21737"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21714"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21717"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21698"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21702"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21684"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21683"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21682"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21680"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21723"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21739"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21640"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21644"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21641"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21643"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21720"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21723"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21614"
  },
  {
    "NUTS3": "DE939",
    "CODE": "21635"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29565"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29578"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29582"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29556"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29593"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29574"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29581"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29553"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29587"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29559"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29525"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29576"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29585"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29549"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29579"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29575"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29394"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29389"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29591"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29599"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29590"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29588"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29584"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29562"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29594"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29571"
  },
  {
    "NUTS3": "DE93A",
    "CODE": "29597"
  },
  {
    "NUTS3": "DE93B",
    "CODE": "27339"
  },
  {
    "NUTS3": "DE93B",
    "CODE": "28832"
  },
  {
    "NUTS3": "DE93B",
    "CODE": "27321"
  },
  {
    "NUTS3": "DE93B",
    "CODE": "28870"
  },
  {
    "NUTS3": "DE93B",
    "CODE": "28876"
  },
  {
    "NUTS3": "DE93B",
    "CODE": "27337"
  },
  {
    "NUTS3": "DE93B",
    "CODE": "27299"
  },
  {
    "NUTS3": "DE93B",
    "CODE": "27283"
  },
  {
    "NUTS3": "DE93B",
    "CODE": "27313"
  },
  {
    "NUTS3": "DE93B",
    "CODE": "27308"
  },
  {
    "NUTS3": "DE941",
    "CODE": "27755"
  },
  {
    "NUTS3": "DE941",
    "CODE": "27753"
  },
  {
    "NUTS3": "DE941",
    "CODE": "27751"
  },
  {
    "NUTS3": "DE941",
    "CODE": "27749"
  },
  {
    "NUTS3": "DE941",
    "CODE": "27755"
  },
  {
    "NUTS3": "DE941",
    "CODE": "27755"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33609"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33729"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33699"
  },
  {
    "NUTS3": "DEA41",
    "CODE": "33719"
  },
  {
    "NUTS3": "DEA42",
    "CODE": "33758"
  },
  {
    "NUTS3": "DEA44",
    "CODE": "34414"
  },
  {
    "NUTS3": "DEA44",
    "CODE": "32839"
  },
  {
    "NUTS3": "DEA44",
    "CODE": "33014"
  },
  {
    "NUTS3": "DEA44",
    "CODE": "34439"
  },
  {
    "NUTS3": "DEA44",
    "CODE": "33039"
  },
  {
    "NUTS3": "DEA44",
    "CODE": "33034"
  },
  {
    "NUTS3": "DEA44",
    "CODE": "34434"
  },
  {
    "NUTS3": "DEA44",
    "CODE": "37696"
  },
  {
    "NUTS3": "DEA44",
    "CODE": "37688"
  },
  {
    "NUTS3": "DEA44",
    "CODE": "37671"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "33813"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "33818"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32107"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32832"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32105"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32791"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32108"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "33189"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32758"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32760"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32657"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32756"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32689"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32805"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32825"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32694"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32699"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32683"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32816"
  },
  {
    "NUTS3": "DEA45",
    "CODE": "32676"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32479"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32549"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32545"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32469"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32429"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32547"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32427"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32457"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32425"
  },
  {
    "NUTS3": "DEA46",
    "CODE": "32423"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33161"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33181"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33106"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33178"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33104"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33098"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33102"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33175"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33165"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33100"
  },
  {
    "NUTS3": "DEA47",
    "CODE": "33184"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "59964"
  },
  {
    "NUTS3": "DEA57",
    "CODE": "34431"
  },
  {
    "NUTS3": "DED44",
    "CODE": "07919"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08538"
  },
  {
    "NUTS3": "DED44",
    "CODE": "07952"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08539"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08606"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08527"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08548"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08525"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08523"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08626"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08523"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08523"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08547"
  },
  {
    "NUTS3": "DED44",
    "CODE": "07985"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08543"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08529"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08541"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08645"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08491"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08648"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08499"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08261"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08239"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08233"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08223"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08468"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08258"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08485"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08496"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08209"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08267"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08228"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08428"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08451"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08412"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08427"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08459"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04205"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04178"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04207"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04249"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04159"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04158"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04209"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04179"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04177"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04229"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04105"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04277"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04155"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04109"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04157"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04107"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04275"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04420"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04523"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04539"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04442"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04416"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04575"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04564"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04565"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04509"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04435"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04519"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06120"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06126"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06124"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06122"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06128"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06118"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06132"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06108"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06114"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06110"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06130"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06112"
  },
  {
    "NUTS3": "DEE02",
    "CODE": "06116"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39116"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39110"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39130"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39120"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39128"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39118"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39112"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39108"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39122"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39104"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39126"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39124"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39106"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39114"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39175"
  },
  {
    "NUTS3": "DEE03",
    "CODE": "39114"
  },
  {
    "NUTS3": "DEE04",
    "CODE": "29413"
  },
  {
    "NUTS3": "DEE04",
    "CODE": "38489"
  },
  {
    "NUTS3": "DEE04",
    "CODE": "29410"
  },
  {
    "NUTS3": "DEE04",
    "CODE": "38486"
  },
  {
    "NUTS3": "DEE04",
    "CODE": "29416"
  },
  {
    "NUTS3": "DEE04",
    "CODE": "39649"
  },
  {
    "NUTS3": "DEE04",
    "CODE": "39638"
  },
  {
    "NUTS3": "DEE04",
    "CODE": "39624"
  },
  {
    "NUTS3": "DEE04",
    "CODE": "39619"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "39264"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06388"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06369"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06386"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06366"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06369"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06385"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06780"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "39261"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06794"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06796"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06779"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06766"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06792"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06809"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06803"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06800"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06749"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06808"
  },
  {
    "NUTS3": "DEE05",
    "CODE": "06774"
  },
  {
    "NUTS3": "DEE06",
    "CODE": "39291"
  },
  {
    "NUTS3": "DEE06",
    "CODE": "39175"
  },
  {
    "NUTS3": "DEE06",
    "CODE": "39288"
  },
  {
    "NUTS3": "DEE06",
    "CODE": "39245"
  },
  {
    "NUTS3": "DEE06",
    "CODE": "39279"
  },
  {
    "NUTS3": "DEE06",
    "CODE": "39317"
  },
  {
    "NUTS3": "DEE06",
    "CODE": "39319"
  },
  {
    "NUTS3": "DEE06",
    "CODE": "39307"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06647"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06642"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06647"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06648"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06638"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06636"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06628"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06632"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06618"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06667"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06721"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06682"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06667"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06722"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06688"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06686"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06712"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06679"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06711"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06729"
  },
  {
    "NUTS3": "DEE08",
    "CODE": "06729"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38835"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38879"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38871"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38875"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38855"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38877"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38899"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38889"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38836"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38895"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38822"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "06502"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "06493"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38838"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38820"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "06493"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "06485"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "06484"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38829"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "38828"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "06543"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "06463"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "06458"
  },
  {
    "NUTS3": "DEE09",
    "CODE": "06333"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06536"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06537"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06493"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06493"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06536"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06536"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06526"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06528"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06343"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06456"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06542"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06542"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06528"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06313"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06333"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06308"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06295"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06311"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06347"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06317"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06268"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06268"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06268"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06279"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06198"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06179"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06246"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06249"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06193"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06255"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06242"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06259"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06217"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06258"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06237"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06188"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06184"
  },
  {
    "NUTS3": "DEE0B",
    "CODE": "06231"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "06466"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "06467"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "06469"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "06449"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39444"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "06464"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39448"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39435"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39448"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39448"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39446"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39418"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39439"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39443"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "06425"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39221"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "06420"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "06408"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "06406"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "06429"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39218"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "06429"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39240"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39217"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39114"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39114"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39114"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39114"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39217"
  },
  {
    "NUTS3": "DEE0C",
    "CODE": "39249"
  },
  {
    "NUTS3": "DEE0D",
    "CODE": "39628"
  },
  {
    "NUTS3": "DEE0D",
    "CODE": "39606"
  },
  {
    "NUTS3": "DEE0D",
    "CODE": "39629"
  },
  {
    "NUTS3": "DEE0D",
    "CODE": "39517"
  },
  {
    "NUTS3": "DEE0D",
    "CODE": "39615"
  },
  {
    "NUTS3": "DEE0D",
    "CODE": "39576"
  },
  {
    "NUTS3": "DEE0D",
    "CODE": "39579"
  },
  {
    "NUTS3": "DEE0D",
    "CODE": "39596"
  },
  {
    "NUTS3": "DEE0D",
    "CODE": "39590"
  },
  {
    "NUTS3": "DEE0D",
    "CODE": "39524"
  },
  {
    "NUTS3": "DEE0D",
    "CODE": "39539"
  },
  {
    "NUTS3": "DEF01",
    "CODE": "24941"
  },
  {
    "NUTS3": "DEF01",
    "CODE": "24939"
  },
  {
    "NUTS3": "DEF01",
    "CODE": "24937"
  },
  {
    "NUTS3": "DEF01",
    "CODE": "24943"
  },
  {
    "NUTS3": "DEF01",
    "CODE": "24944"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24109"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24113"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24111"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24106"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24145"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24114"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24116"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24118"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24159"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24103"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24105"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24143"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24148"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24147"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24146"
  },
  {
    "NUTS3": "DEF02",
    "CODE": "24149"
  },
  {
    "NUTS3": "DEF03",
    "CODE": "23560"
  },
  {
    "NUTS3": "DEF03",
    "CODE": "23556"
  },
  {
    "NUTS3": "DEF03",
    "CODE": "23558"
  },
  {
    "NUTS3": "DEF03",
    "CODE": "23554"
  },
  {
    "NUTS3": "DEF03",
    "CODE": "23552"
  },
  {
    "NUTS3": "DEF03",
    "CODE": "23562"
  },
  {
    "NUTS3": "DEF03",
    "CODE": "23564"
  },
  {
    "NUTS3": "DEF03",
    "CODE": "23568"
  },
  {
    "NUTS3": "DEF03",
    "CODE": "23566"
  },
  {
    "NUTS3": "DEF03",
    "CODE": "23569"
  },
  {
    "NUTS3": "DEF03",
    "CODE": "23570"
  },
  {
    "NUTS3": "DEF04",
    "CODE": "24537"
  },
  {
    "NUTS3": "DEF04",
    "CODE": "24539"
  },
  {
    "NUTS3": "DEF04",
    "CODE": "24536"
  },
  {
    "NUTS3": "DEF04",
    "CODE": "24534"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25718"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25761"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25764"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25718"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25709"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25774"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25704"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25792"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25724"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25797"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25746"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25719"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25693"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25776"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25541"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25795"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25776"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25770"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25715"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25779"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25729"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25785"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25727"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25712"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25791"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25782"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25767"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25788"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25721"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25725"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25794"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25786"
  },
  {
    "NUTS3": "DEF05",
    "CODE": "25799"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25826"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25849"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25881"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25867"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25899"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25927"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25836"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25869"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25845"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25842"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25869"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25882"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25845"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25920"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25923"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25845"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25821"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25832"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25821"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25852"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25870"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25889"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25917"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25813"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25856"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25926"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25853"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25840"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25858"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25860"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25862"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25866"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25884"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25876"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25873"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25864"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25885"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25878"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25855"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25872"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25887"
  },
  {
    "NUTS3": "DEF07",
    "CODE": "25850"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23715"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23623"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23714"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23617"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23701"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23684"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23611"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23629"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23717"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23689"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23744"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23758"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23626"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23730"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23683"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23669"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23738"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23743"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23774"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23777"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23779"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23749"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23775"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23769"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23746"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23747"
  },
  {
    "NUTS3": "DEF08",
    "CODE": "23769"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25377"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25335"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25371"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25491"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25489"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25491"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25370"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25436"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25336"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25492"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "22880"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25364"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25488"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25365"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25337"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25355"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25482"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25373"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25497"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25421"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25495"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "22869"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25485"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25494"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25469"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25462"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25499"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25451"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "25474"
  },
  {
    "NUTS3": "DEF09",
    "CODE": "22844"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24620"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24637"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24619"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24250"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24245"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24211"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24601"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24248"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24226"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24222"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24232"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24223"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24235"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24253"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24326"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24217"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24256"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24306"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24238"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24257"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24321"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24329"
  },
  {
    "NUTS3": "DEF0A",
    "CODE": "24327"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "25557"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24799"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24806"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24799"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24805"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24800"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24797"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "25585"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "25575"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24819"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24787"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24811"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24791"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "25590"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24809"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24816"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24594"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24813"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24768"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24808"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24357"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24784"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24782"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24358"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24783"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24794"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24354"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24361"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24613"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24790"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24793"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24802"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24367"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24340"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24796"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24622"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24814"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24589"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24360"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24647"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24634"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24363"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24351"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24259"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24366"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24644"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24364"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24646"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24214"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24242"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24631"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24398"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24369"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24582"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24241"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24107"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24239"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24251"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24247"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24254"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24244"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24625"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24229"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24220"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24161"
  },
  {
    "NUTS3": "DEF0B",
    "CODE": "24119"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24969"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24980"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24994"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "25879"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24992"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24983"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24861"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24899"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24997"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24803"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24896"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24852"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24869"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24876"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24963"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24887"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24863"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24955"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24855"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24870"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24988"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24976"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24848"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24872"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24850"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24817"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24885"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24867"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24991"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24890"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24873"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24879"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24960"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24975"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24860"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24999"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24878"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24837"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24881"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24866"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24986"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24884"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24977"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24857"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24882"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24966"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24891"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24894"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24989"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24893"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24401"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24405"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24864"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24888"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24392"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24972"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24996"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24897"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24402"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24395"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24407"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24409"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24376"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24407"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24404"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25572"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25576"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25554"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25584"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25594"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25596"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25599"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25573"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25560"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25376"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25348"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25588"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25569"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25524"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25377"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25582"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25591"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25379"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25361"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25578"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25358"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25587"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25593"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25551"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25566"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25368"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25597"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25548"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25581"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "25579"
  },
  {
    "NUTS3": "DEF0E",
    "CODE": "24616"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22889"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22889"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "23863"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22949"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22145"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22885"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22113"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22941"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22926"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "21509"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "21465"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "23869"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "23843"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "23845"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22962"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22946"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22927"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22967"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22955"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22929"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22969"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22961"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22965"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22952"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22964"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22956"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "23858"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "22929"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "23619"
  },
  {
    "NUTS3": "DEF0F",
    "CODE": "23860"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99090"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99092"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99094"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99091"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99095"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99089"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99097"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99084"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99087"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99086"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99096"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99085"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99099"
  },
  {
    "NUTS3": "DEG01",
    "CODE": "99098"
  },
  {
    "NUTS3": "DEG02",
    "CODE": "07548"
  },
  {
    "NUTS3": "DEG02",
    "CODE": "07554"
  },
  {
    "NUTS3": "DEG02",
    "CODE": "07552"
  },
  {
    "NUTS3": "DEG02",
    "CODE": "07549"
  },
  {
    "NUTS3": "DEG02",
    "CODE": "07546"
  },
  {
    "NUTS3": "DEG02",
    "CODE": "07551"
  },
  {
    "NUTS3": "DEG02",
    "CODE": "07551"
  },
  {
    "NUTS3": "DEG02",
    "CODE": "07545"
  },
  {
    "NUTS3": "DEG03",
    "CODE": "07751"
  },
  {
    "NUTS3": "DEG03",
    "CODE": "07751"
  },
  {
    "NUTS3": "DEG03",
    "CODE": "07745"
  },
  {
    "NUTS3": "DEG03",
    "CODE": "07743"
  },
  {
    "NUTS3": "DEG03",
    "CODE": "07749"
  },
  {
    "NUTS3": "DEG03",
    "CODE": "07747"
  },
  {
    "NUTS3": "DEG05",
    "CODE": "99427"
  },
  {
    "NUTS3": "DEG05",
    "CODE": "99425"
  },
  {
    "NUTS3": "DEG05",
    "CODE": "99423"
  },
  {
    "NUTS3": "DEG06",
    "CODE": "37318"
  },
  {
    "NUTS3": "DEG06",
    "CODE": "37308"
  },
  {
    "NUTS3": "DEG06",
    "CODE": "37339"
  },
  {
    "NUTS3": "DEG06",
    "CODE": "37359"
  },
  {
    "NUTS3": "DEG06",
    "CODE": "37327"
  },
  {
    "NUTS3": "DEG06",
    "CODE": "37351"
  },
  {
    "NUTS3": "DEG06",
    "CODE": "37355"
  },
  {
    "NUTS3": "DEG06",
    "CODE": "37345"
  },
  {
    "NUTS3": "DEG07",
    "CODE": "99755"
  },
  {
    "NUTS3": "DEG07",
    "CODE": "99759"
  },
  {
    "NUTS3": "DEG07",
    "CODE": "99752"
  },
  {
    "NUTS3": "DEG07",
    "CODE": "99735"
  },
  {
    "NUTS3": "DEG07",
    "CODE": "99734"
  },
  {
    "NUTS3": "DEG07",
    "CODE": "99768"
  },
  {
    "NUTS3": "DEG07",
    "CODE": "99765"
  },
  {
    "NUTS3": "DEG09",
    "CODE": "99976"
  },
  {
    "NUTS3": "DEG09",
    "CODE": "99988"
  },
  {
    "NUTS3": "DEG09",
    "CODE": "99986"
  },
  {
    "NUTS3": "DEG09",
    "CODE": "99974"
  },
  {
    "NUTS3": "DEG09",
    "CODE": "99947"
  },
  {
    "NUTS3": "DEG09",
    "CODE": "99998"
  },
  {
    "NUTS3": "DEG09",
    "CODE": "99991"
  },
  {
    "NUTS3": "DEG09",
    "CODE": "99996"
  },
  {
    "NUTS3": "DEG09",
    "CODE": "99994"
  },
  {
    "NUTS3": "DEG09",
    "CODE": "99955"
  },
  {
    "NUTS3": "DEG0A",
    "CODE": "99713"
  },
  {
    "NUTS3": "DEG0A",
    "CODE": "99706"
  },
  {
    "NUTS3": "DEG0A",
    "CODE": "99718"
  },
  {
    "NUTS3": "DEG0A",
    "CODE": "99707"
  },
  {
    "NUTS3": "DEG0A",
    "CODE": "06578"
  },
  {
    "NUTS3": "DEG0A",
    "CODE": "06567"
  },
  {
    "NUTS3": "DEG0A",
    "CODE": "06577"
  },
  {
    "NUTS3": "DEG0A",
    "CODE": "06556"
  },
  {
    "NUTS3": "DEG0A",
    "CODE": "06571"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98634"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98617"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98590"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98597"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98574"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98639"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98631"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98596"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98547"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98593"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98530"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98587"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98554"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98544"
  },
  {
    "NUTS3": "DEG0B",
    "CODE": "98559"
  },
  {
    "NUTS3": "DEG0D",
    "CODE": "99189"
  },
  {
    "NUTS3": "DEG0D",
    "CODE": "99634"
  },
  {
    "NUTS3": "DEG0D",
    "CODE": "99195"
  },
  {
    "NUTS3": "DEG0D",
    "CODE": "99631"
  },
  {
    "NUTS3": "DEG0D",
    "CODE": "99638"
  },
  {
    "NUTS3": "DEG0D",
    "CODE": "99610"
  },
  {
    "NUTS3": "DEG0D",
    "CODE": "99198"
  },
  {
    "NUTS3": "DEG0D",
    "CODE": "99625"
  },
  {
    "NUTS3": "DEG0D",
    "CODE": "99628"
  },
  {
    "NUTS3": "DEG0D",
    "CODE": "99636"
  },
  {
    "NUTS3": "DEG0E",
    "CODE": "98630"
  },
  {
    "NUTS3": "DEG0E",
    "CODE": "98660"
  },
  {
    "NUTS3": "DEG0E",
    "CODE": "98646"
  },
  {
    "NUTS3": "DEG0E",
    "CODE": "98663"
  },
  {
    "NUTS3": "DEG0E",
    "CODE": "98553"
  },
  {
    "NUTS3": "DEG0E",
    "CODE": "98669"
  },
  {
    "NUTS3": "DEG0E",
    "CODE": "98673"
  },
  {
    "NUTS3": "DEG0E",
    "CODE": "98667"
  },
  {
    "NUTS3": "DEG0E",
    "CODE": "98666"
  },
  {
    "NUTS3": "DEG0E",
    "CODE": "98694"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "98559"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "99330"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "98711"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "98716"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "98693"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "99338"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "99334"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "98694"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "99310"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "98701"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "98694"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "99326"
  },
  {
    "NUTS3": "DEG0F",
    "CODE": "99102"
  },
  {
    "NUTS3": "DEG0G",
    "CODE": "99102"
  },
  {
    "NUTS3": "DEG0G",
    "CODE": "99448"
  },
  {
    "NUTS3": "DEG0G",
    "CODE": "99198"
  },
  {
    "NUTS3": "DEG0G",
    "CODE": "99428"
  },
  {
    "NUTS3": "DEG0G",
    "CODE": "99438"
  },
  {
    "NUTS3": "DEG0G",
    "CODE": "99439"
  },
  {
    "NUTS3": "DEG0G",
    "CODE": "99444"
  },
  {
    "NUTS3": "DEG0G",
    "CODE": "99441"
  },
  {
    "NUTS3": "DEG0G",
    "CODE": "99510"
  },
  {
    "NUTS3": "DEG0G",
    "CODE": "99518"
  },
  {
    "NUTS3": "DEG0H",
    "CODE": "96528"
  },
  {
    "NUTS3": "DEG0H",
    "CODE": "98724"
  },
  {
    "NUTS3": "DEG0H",
    "CODE": "96523"
  },
  {
    "NUTS3": "DEG0H",
    "CODE": "96515"
  },
  {
    "NUTS3": "DEG0H",
    "CODE": "96524"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07768"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07751"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07778"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07751"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07646"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07774"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07778"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07616"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07619"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07629"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07639"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07607"
  },
  {
    "NUTS3": "DEG0J",
    "CODE": "07613"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07387"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07343"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07368"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07389"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07381"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07366"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07356"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07929"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07389"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07924"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07806"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07907"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07926"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07922"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07819"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07927"
  },
  {
    "NUTS3": "DEG0K",
    "CODE": "07919"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07589"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07955"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07586"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07937"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07570"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07950"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07557"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07958"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07957"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07973"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07980"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07980"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07980"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07980"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07580"
  },
  {
    "NUTS3": "DEG0L",
    "CODE": "07987"
  },
  {
    "NUTS3": "DEG0M",
    "CODE": "04626"
  },
  {
    "NUTS3": "DEG0M",
    "CODE": "04617"
  },
  {
    "NUTS3": "DEG0M",
    "CODE": "04610"
  },
  {
    "NUTS3": "DEG0M",
    "CODE": "04613"
  },
  {
    "NUTS3": "DEG0M",
    "CODE": "04603"
  },
  {
    "NUTS3": "DEG0M",
    "CODE": "04639"
  },
  {
    "NUTS3": "DEG0N",
    "CODE": "99817"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "36419"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "36414"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "99837"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "36404"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "99834"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "36460"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "36452"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "36466"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "36457"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "99819"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "99830"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "36469"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "36433"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "99831"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "99819"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "36456"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "99826"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "36448"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "99848"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "99842"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "99820"
  },
  {
    "NUTS3": "DEG0P",
    "CODE": "99846"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84518"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84568"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84550"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84577"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84579"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84576"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84503"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84543"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84558"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84508"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84556"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84553"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84524"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84571"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84567"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84547"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84561"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84489"
  },
  {
    "NUTS3": "DE214",
    "CODE": "84533"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83458"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83364"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83317"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83454"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83486"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83435"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83451"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83410"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83404"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83416"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83457"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83483"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83471"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83395"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83487"
  },
  {
    "NUTS3": "DE215",
    "CODE": "83487"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84573"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84478"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84559"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84574"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84494"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84546"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84562"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84453"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84570"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84565"
  },
  {
    "NUTS3": "DE21G",
    "CODE": "84513"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83224"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83250"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83370"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83256"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83246"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83358"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83361"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83236"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83342"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83242"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83352"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "84549"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83376"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83339"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83308"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83355"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83324"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83346"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83301"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83371"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83365"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83368"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83377"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83278"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83374"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83349"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83313"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83362"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83329"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83373"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83379"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "84529"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83334"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83417"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83367"
  },
  {
    "NUTS3": "DE21M",
    "CODE": "83413"
  },
  {
    "NUTS3": "DE222",
    "CODE": "94036"
  },
  {
    "NUTS3": "DE222",
    "CODE": "94034"
  },
  {
    "NUTS3": "DE222",
    "CODE": "94032"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94569"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94563"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94562"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94560"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94447"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94527"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94574"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94505"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94526"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94469"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94533"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94486"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94554"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94539"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94550"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94557"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94571"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94577"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94491"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94530"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94551"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94547"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94541"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94508"
  },
  {
    "NUTS3": "DE224",
    "CODE": "94532"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94572"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94579"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94548"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94169"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94536"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94518"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94513"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94163"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94481"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94566"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94568"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94157"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94142"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94160"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94556"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94545"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94133"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94078"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94151"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94065"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94146"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94143"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94158"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94118"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94145"
  },
  {
    "NUTS3": "DE225",
    "CODE": "94089"
  },
  {
    "NUTS3": "DE227",
    "CODE": "84168"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94501"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94149"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94542"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94474"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94544"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94086"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94094"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94496"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94535"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94575"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94148"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94072"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94167"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94060"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94099"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94529"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94081"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94538"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94113"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94104"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94127"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94154"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94161"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94152"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94116"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94121"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94124"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94136"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94051"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94130"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94107"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94164"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94110"
  },
  {
    "NUTS3": "DE228",
    "CODE": "94139"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94267"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94262"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94234"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94250"
  },
  {
    "NUTS3": "DE229",
    "CODE": "93471"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94265"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94239"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94244"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94256"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94255"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94253"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94249"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94209"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94264"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94259"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94252"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94269"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94227"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94261"
  },
  {
    "NUTS3": "DE229",
    "CODE": "94258"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94368"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94369"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94339"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94356"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94345"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94348"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94344"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94351"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94330"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94377"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94365"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94350"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94347"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94372"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94363"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94375"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94359"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94360"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94327"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94354"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94342"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94357"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94336"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94353"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94362"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94371"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94559"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94366"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94379"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94553"
  },
  {
    "NUTS3": "DE22B",
    "CODE": "94374"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "84164"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "84180"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "84130"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "84163"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "84160"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "84177"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "94419"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "94431"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "94437"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "94405"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "94436"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "94522"
  },
  {
    "NUTS3": "DE22C",
    "CODE": "94428"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93426"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93191"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93167"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93426"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93491"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93185"
  },
  {
    "NUTS3": "DE235",
    "CODE": "92444"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93483"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93464"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93488"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93489"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93413"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93482"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93455"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93492"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93449"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93494"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93497"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93499"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93486"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93495"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93477"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93466"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93468"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93437"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93473"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93476"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93444"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93485"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93479"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93458"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93480"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93453"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93474"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93462"
  },
  {
    "NUTS3": "DE235",
    "CODE": "93470"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92693"
  },
  {
    "NUTS3": "DE237",
    "CODE": "92726"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92526"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92554"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92559"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92539"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92557"
  },
  {
    "NUTS3": "DE239",
    "CODE": "92549"
  },
  {
    "NUTS3": "DE23A",
    "CODE": "95695"
  },
  {
    "NUTS3": "DE21K",
    "CODE": "83257"
  },
  {
    "NUTS3": "DE223",
    "CODE": "94315"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84140"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84323"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84552"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84326"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84339"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84335"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84307"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84333"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84329"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "94424"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84332"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84337"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84367"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84389"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84378"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "94439"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84381"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84375"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84387"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84347"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84371"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84359"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84384"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84364"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "84385"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "94166"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "94140"
  },
  {
    "NUTS3": "DE22A",
    "CODE": "94137"
  },
  {
    "NUTS3": "DE401",
    "CODE": "14772"
  },
  {
    "NUTS3": "DE401",
    "CODE": "14776"
  },
  {
    "NUTS3": "DE401",
    "CODE": "14770"
  },
  {
    "NUTS3": "DE401",
    "CODE": "14778"
  },
  {
    "NUTS3": "DE407",
    "CODE": "04916"
  },
  {
    "NUTS3": "DE407",
    "CODE": "04895"
  },
  {
    "NUTS3": "DE407",
    "CODE": "04931"
  },
  {
    "NUTS3": "DE407",
    "CODE": "04924"
  },
  {
    "NUTS3": "DE407",
    "CODE": "04938"
  },
  {
    "NUTS3": "DE407",
    "CODE": "04936"
  },
  {
    "NUTS3": "DE407",
    "CODE": "03253"
  },
  {
    "NUTS3": "DE407",
    "CODE": "04932"
  },
  {
    "NUTS3": "DE407",
    "CODE": "04910"
  },
  {
    "NUTS3": "DE407",
    "CODE": "03249"
  },
  {
    "NUTS3": "DE407",
    "CODE": "04934"
  },
  {
    "NUTS3": "DE407",
    "CODE": "03238"
  },
  {
    "NUTS3": "DE407",
    "CODE": "04928"
  },
  {
    "NUTS3": "DE407",
    "CODE": "03246"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14778"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14798"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14806"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14797"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14823"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14822"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14550"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14929"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14547"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14542"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14548"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14554"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14552"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14558"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14558"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14532"
  },
  {
    "NUTS3": "DE40E",
    "CODE": "14513"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01156"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01157"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01169"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01139"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01187"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01187"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01159"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01189"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01067"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01129"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01109"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01069"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01127"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01217"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01097"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01069"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01127"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01099"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01108"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01219"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01307"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01239"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01309"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01277"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01237"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01257"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01326"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01279"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01257"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01259"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01324"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01465"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01465"
  },
  {
    "NUTS3": "DED21",
    "CODE": "01328"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08304"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08328"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08309"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08289"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08321"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08301"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09376"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08324"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08349"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09394"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08280"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08315"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09366"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08340"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08359"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08294"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09385"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09399"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08297"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08344"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09387"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09221"
  },
  {
    "NUTS3": "DED42",
    "CODE": "08352"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09380"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09481"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09235"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09484"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09468"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09390"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09474"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09392"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09423"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09427"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09419"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09487"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09465"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09488"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09439"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09456"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09430"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09471"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09429"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09405"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09432"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09437"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09518"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09477"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09434"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09579"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09437"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09514"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09496"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09509"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09526"
  },
  {
    "NUTS3": "DED42",
    "CODE": "09548"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06901"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06886"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06888"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06889"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06772"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06905"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06888"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06895"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06917"
  },
  {
    "NUTS3": "DEE0E",
    "CODE": "06925"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14109"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14089"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13591"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13587"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13593"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13589"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13581"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14129"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13583"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13595"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14193"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13585"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13597"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14055"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13503"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13505"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13599"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14163"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14053"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14165"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14052"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14169"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13629"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13507"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14050"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14195"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13405"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14167"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13465"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13627"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14057"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14059"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10711"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14199"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12205"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13467"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13509"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10589"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12203"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12207"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10627"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10709"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10629"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10585"
  },
  {
    "NUTS3": "DE300",
    "CODE": "14197"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10587"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10713"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13469"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13437"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13403"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12163"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12165"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10707"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10625"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10553"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12209"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13351"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10623"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10719"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10623"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12167"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10717"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12161"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10715"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12169"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12247"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13353"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10555"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12157"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10551"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12159"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13435"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10789"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10777"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13349"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10779"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13407"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10787"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12249"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10825"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10557"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13439"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10827"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12279"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10559"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10829"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10823"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10781"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13158"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10785"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13347"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12277"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10783"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12105"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13409"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12307"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12103"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13359"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12101"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10115"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12107"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13159"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10965"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13357"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10117"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13156"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10963"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13355"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12099"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12109"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10961"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10969"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12305"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13187"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10178"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10119"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10439"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10437"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10435"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12349"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10179"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12107"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13127"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10967"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12309"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13189"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12347"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10999"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10405"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12049"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12305"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12051"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10997"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10249"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12047"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10243"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10407"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12043"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12053"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10409"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13089"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13086"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12045"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12353"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13129"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12359"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12351"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12059"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12435"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12055"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12057"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12059"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10245"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13125"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13088"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10247"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10369"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12437"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10317"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13053"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12355"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12357"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10367"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10365"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13055"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13051"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12487"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10319"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12459"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12439"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10315"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13059"
  },
  {
    "NUTS3": "DE300",
    "CODE": "10318"
  },
  {
    "NUTS3": "DE300",
    "CODE": "13057"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12524"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12681"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12489"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12526"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12683"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12685"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12679"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12689"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12555"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12687"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12527"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12557"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12621"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12685"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12679"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12619"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12623"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12623"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12629"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12559"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12559"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12627"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12587"
  },
  {
    "NUTS3": "DE300",
    "CODE": "12589"
  },
  {
    "NUTS3": "DE402",
    "CODE": "03055"
  },
  {
    "NUTS3": "DE402",
    "CODE": "03044"
  },
  {
    "NUTS3": "DE402",
    "CODE": "03048"
  },
  {
    "NUTS3": "DE402",
    "CODE": "03046"
  },
  {
    "NUTS3": "DE402",
    "CODE": "03051"
  },
  {
    "NUTS3": "DE402",
    "CODE": "03054"
  },
  {
    "NUTS3": "DE402",
    "CODE": "03050"
  },
  {
    "NUTS3": "DE402",
    "CODE": "03042"
  },
  {
    "NUTS3": "DE402",
    "CODE": "03052"
  },
  {
    "NUTS3": "DE402",
    "CODE": "03053"
  },
  {
    "NUTS3": "DE403",
    "CODE": "15234"
  },
  {
    "NUTS3": "DE403",
    "CODE": "15236"
  },
  {
    "NUTS3": "DE403",
    "CODE": "15230"
  },
  {
    "NUTS3": "DE403",
    "CODE": "15232"
  },
  {
    "NUTS3": "DE404",
    "CODE": "14476"
  },
  {
    "NUTS3": "DE404",
    "CODE": "14469"
  },
  {
    "NUTS3": "DE404",
    "CODE": "14476"
  },
  {
    "NUTS3": "DE404",
    "CODE": "14471"
  },
  {
    "NUTS3": "DE404",
    "CODE": "14473"
  },
  {
    "NUTS3": "DE404",
    "CODE": "14467"
  },
  {
    "NUTS3": "DE404",
    "CODE": "14478"
  },
  {
    "NUTS3": "DE404",
    "CODE": "14482"
  },
  {
    "NUTS3": "DE404",
    "CODE": "14480"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16348"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16244"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16321"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16341"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16356"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16359"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16247"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16225"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16230"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16227"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16230"
  },
  {
    "NUTS3": "DE405",
    "CODE": "16248"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15938"
  },
  {
    "NUTS3": "DE406",
    "CODE": "12529"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15749"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15926"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15755"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15738"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15732"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15711"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15741"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15746"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15745"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15757"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15910"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15712"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15713"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15754"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15748"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15907"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15913"
  },
  {
    "NUTS3": "DE406",
    "CODE": "15868"
  },
  {
    "NUTS3": "DE408",
    "CODE": "14662"
  },
  {
    "NUTS3": "DE408",
    "CODE": "14641"
  },
  {
    "NUTS3": "DE408",
    "CODE": "14669"
  },
  {
    "NUTS3": "DE408",
    "CODE": "14656"
  },
  {
    "NUTS3": "DE408",
    "CODE": "14621"
  },
  {
    "NUTS3": "DE408",
    "CODE": "14624"
  },
  {
    "NUTS3": "DE408",
    "CODE": "14612"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15366"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15345"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15370"
  },
  {
    "NUTS3": "DE409",
    "CODE": "16259"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15562"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15378"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15344"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15345"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15377"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15374"
  },
  {
    "NUTS3": "DE409",
    "CODE": "16269"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15320"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15306"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15324"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15326"
  },
  {
    "NUTS3": "DE409",
    "CODE": "15328"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16766"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16775"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16727"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16798"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16515"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16761"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16767"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16556"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16792"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16540"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16547"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16559"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16562"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16567"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16548"
  },
  {
    "NUTS3": "DE40A",
    "CODE": "16552"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "01979"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "01990"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "01945"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "01987"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "03205"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "03222"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "01998"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "01994"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "01993"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "01983"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "01968"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "03229"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "01996"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "03226"
  },
  {
    "NUTS3": "DE40B",
    "CODE": "03103"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15566"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15537"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15569"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15528"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15859"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15864"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15517"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15526"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15518"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15518"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15848"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15236"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15299"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15518"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15890"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15295"
  },
  {
    "NUTS3": "DE40C",
    "CODE": "15898"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16868"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16818"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16837"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16833"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16816"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16831"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16827"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16818"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16818"
  },
  {
    "NUTS3": "DE40D",
    "CODE": "16835"
  },
  {
    "NUTS3": "DE40G",
    "CODE": "03096"
  },
  {
    "NUTS3": "DE40G",
    "CODE": "03116"
  },
  {
    "NUTS3": "DE40G",
    "CODE": "03099"
  },
  {
    "NUTS3": "DE40G",
    "CODE": "03119"
  },
  {
    "NUTS3": "DE40G",
    "CODE": "03130"
  },
  {
    "NUTS3": "DE40G",
    "CODE": "03185"
  },
  {
    "NUTS3": "DE40G",
    "CODE": "03058"
  },
  {
    "NUTS3": "DE40G",
    "CODE": "03172"
  },
  {
    "NUTS3": "DE40G",
    "CODE": "03197"
  },
  {
    "NUTS3": "DE40G",
    "CODE": "03149"
  },
  {
    "NUTS3": "DE40G",
    "CODE": "03159"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "14913"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "14947"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "14943"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "14959"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "14974"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "14979"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "15936"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "15837"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "15806"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "15838"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "15827"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "15831"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "15831"
  },
  {
    "NUTS3": "DE40H",
    "CODE": "15834"
  },
  {
    "NUTS3": "DE40I",
    "CODE": "17279"
  },
  {
    "NUTS3": "DE40I",
    "CODE": "17268"
  },
  {
    "NUTS3": "DE40I",
    "CODE": "17291"
  },
  {
    "NUTS3": "DE40I",
    "CODE": "17337"
  },
  {
    "NUTS3": "DE40I",
    "CODE": "16278"
  },
  {
    "NUTS3": "DE40I",
    "CODE": "16306"
  },
  {
    "NUTS3": "DE40I",
    "CODE": "17326"
  },
  {
    "NUTS3": "DE40I",
    "CODE": "16303"
  },
  {
    "NUTS3": "DE40I",
    "CODE": "16306"
  },
  {
    "NUTS3": "DE40I",
    "CODE": "16307"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17194"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17207"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17192"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17139"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17248"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17252"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17154"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17159"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17153"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17237"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17255"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17219"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17111"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17237"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17235"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17109"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17091"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17217"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17039"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17089"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17033"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17087"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17094"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17258"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17034"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17039"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17036"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17349"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17348"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17098"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17099"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17099"
  },
  {
    "NUTS3": "DE80J",
    "CODE": "17337"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "17168"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "17166"
  },
  {
    "NUTS3": "DE80K",
    "CODE": "17179"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18375"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18334"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18317"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18320"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18317"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18356"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18356"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18314"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18374"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18374"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18465"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18469"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18461"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18513"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18442"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18445"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18510"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18516"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18507"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18437"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18435"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18565"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18439"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18519"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18439"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18573"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18439"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18565"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18569"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18569"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18574"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18569"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18569"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18556"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18573"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18528"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18569"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18581"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18551"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18581"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18609"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18546"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18586"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17121"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17129"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17498"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17126"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17506"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17489"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17493"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17391"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17493"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17493"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17493"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17392"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17493"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17491"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17489"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17495"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17509"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17390"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17389"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17438"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17398"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17440"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17335"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17379"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17440"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17440"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17449"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17440"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17440"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17440"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17440"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17438"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17438"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17309"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17449"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17449"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17449"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17449"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17449"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17406"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17375"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17454"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17358"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17440"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17375"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17440"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17459"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17429"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17373"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17419"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17367"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17322"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17328"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17424"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17375"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17321"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17329"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17322"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17322"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17375"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01936"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01458"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01454"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01477"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01896"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01920"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01900"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01917"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02994"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01909"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02991"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02977"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02979"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01877"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01906"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02997"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02633"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02699"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "01904"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02999"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02625"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02627"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02681"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02692"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02689"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02694"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02627"
  },
  {
    "NUTS3": "DED2C",
    "CODE": "02733"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02959"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02943"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02736"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02742"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02708"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02730"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02782"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02953"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02727"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02906"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02794"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02799"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02739"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02779"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02791"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02957"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02796"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02894"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02957"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02747"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02943"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02957"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02763"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02956"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02797"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02785"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02748"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02923"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02788"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02829"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02899"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02929"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02827"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02828"
  },
  {
    "NUTS3": "DED2D",
    "CODE": "02826"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01616"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01594"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01591"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01623"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01683"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01619"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01587"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01589"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01665"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01609"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01612"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01561"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01662"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01558"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01689"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01640"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01445"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01468"
  },
  {
    "NUTS3": "DED2E",
    "CODE": "01471"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01723"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01737"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01774"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01762"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01738"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01738"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01705"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01744"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01734"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01776"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01773"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01768"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01728"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01731"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01809"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01778"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01825"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01816"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01819"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01796"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01833"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01847"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01829"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01824"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01848"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01814"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01844"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01855"
  },
  {
    "NUTS3": "DED2F",
    "CODE": "01855"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09224"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09117"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09247"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09117"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09228"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09116"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09114"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09123"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09122"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09119"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09120"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09113"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09112"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09125"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09111"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09126"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09131"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09130"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09127"
  },
  {
    "NUTS3": "DED41",
    "CODE": "09128"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09322"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09328"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09306"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09241"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09217"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09232"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09249"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09236"
  },
  {
    "NUTS3": "DED43",
    "CODE": "04703"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09326"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09648"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09244"
  },
  {
    "NUTS3": "DED43",
    "CODE": "04746"
  },
  {
    "NUTS3": "DED43",
    "CODE": "04736"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09577"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09661"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09669"
  },
  {
    "NUTS3": "DED43",
    "CODE": "04720"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09573"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09557"
  },
  {
    "NUTS3": "DED43",
    "CODE": "04749"
  },
  {
    "NUTS3": "DED43",
    "CODE": "04741"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09569"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09575"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09600"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09603"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09618"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09599"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09629"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09634"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09638"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09633"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09600"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09619"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09627"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09544"
  },
  {
    "NUTS3": "DED43",
    "CODE": "09623"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08236"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08262"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08248"
  },
  {
    "NUTS3": "DED44",
    "CODE": "08237"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08115"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08058"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08393"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08144"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08060"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08056"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08107"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08064"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08062"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08147"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08112"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08132"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08371"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08066"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08396"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08141"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08373"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08134"
  },
  {
    "NUTS3": "DED45",
    "CODE": "09356"
  },
  {
    "NUTS3": "DED45",
    "CODE": "09350"
  },
  {
    "NUTS3": "DED45",
    "CODE": "09337"
  },
  {
    "NUTS3": "DED45",
    "CODE": "08118"
  },
  {
    "NUTS3": "DED45",
    "CODE": "09212"
  },
  {
    "NUTS3": "DED45",
    "CODE": "09355"
  },
  {
    "NUTS3": "DED45",
    "CODE": "09353"
  },
  {
    "NUTS3": "DED45",
    "CODE": "09243"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04129"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04279"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04103"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04356"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04347"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04315"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04317"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04357"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04299"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04289"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04318"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04349"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04316"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04328"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04288"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04329"
  },
  {
    "NUTS3": "DED51",
    "CODE": "04319"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04571"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04463"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04552"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04451"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04654"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04567"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04683"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04824"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04827"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04668"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04651"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04821"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04828"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04643"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04808"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04687"
  },
  {
    "NUTS3": "DED52",
    "CODE": "04680"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04425"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04838"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04849"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04880"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04862"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04860"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04889"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04779"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04774"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04769"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04861"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04886"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04758"
  },
  {
    "NUTS3": "DED53",
    "CODE": "04874"
  },
  {
    "NUTS3": "DEG0M",
    "CODE": "04600"
  },
  {
    "NUTS3": "DEG0M",
    "CODE": "04603"
  },
  {
    "NUTS3": "DEG0M",
    "CODE": "04617"
  },
  {
    "NUTS3": "DEG0M",
    "CODE": "04618"
  },
  {
    "NUTS3": "DEG0M",
    "CODE": "04618"
  },
  {
    "NUTS3": "DE80L",
    "CODE": "18445"
  },
  {
    "NUTS3": "DE80N",
    "CODE": "17449"
  },
  {
    "NUTS3": "DE94G",
    "CODE": "26954"
  },
  {
    "NUTS3": "DEF0C",
    "CODE": "24399"
  },
  {
    "NUTS3": "DE712",
    "CODE": "65926"
  },
  {
    "NUTS3": "DE91C",
    "CODE": "37547"
  },
  {
    "NUTS3": "DEA24",
    "CODE": "51368"
  },
  {
    "NUTS3": "DEB34",
    "CODE": "67056"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27632"
  },
  {
    "NUTS3": "DE21B",
    "CODE": "85326"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27637"
  },
  {
    "NUTS3": "DE269",
    "CODE": "63784"
  },
  {
    "NUTS3": "DE139",
    "CODE": "79603"
  },
  {
    "NUTS3": "DE112",
    "CODE": "71059"
  },
  {
    "NUTS3": "DEE0A",
    "CODE": "06318"
  },
  {
    "NUTS3": "DEB3E",
    "CODE": "76742"
  },
  {
    "NUTS3": "DE932",
    "CODE": "27638"
  }
]