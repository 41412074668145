import React, { useState, useContext } from 'react'
import { GlobalContext } from '../../context/Global';
import { APIService } from '../../services'
import styles from './starrating.module.scss'
import StarRoundedIcon from '@material-ui/icons/StarRounded';

export const StarRating = () => {

    const {
        sessionID
    } = useContext(GlobalContext)

    const [rating, setRating] = useState(null)
    const [hover, setHover] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleRatingChange = async (rating) => {
        setLoading(true)
        await APIService.addRatingToSession(sessionID, rating)
        setLoading(false)
        setRating(rating)
    }

    return (
        <>
            <div className={styles.wrapper}>
                {[...Array(5)].map((star, i) => {
                    const ratingVal = i + 1
                    return (
                        <label
                            key={i}
                            className={ratingVal <= (hover || rating) ? styles.active : ''}
                            onMouseEnter={() => setHover(ratingVal)}
                            onMouseLeave={() => setHover(null)}
                        >
                            <input
                                type='radio'
                                name='rating'
                                value={ratingVal}
                                onClick={() => handleRatingChange(ratingVal)} />
                            <StarRoundedIcon />
                        </label>
                    )

                })}
            </div>
            {!loading && rating ?
                <span className={styles.thanks}>Danke für Deine Bewertung!</span>
                : ''}
        </>
    )
}
