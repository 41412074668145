import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/Global'
import styles from './emailfield.module.scss'
import { Button } from '../Buttons'
import { TextInput } from '../Inputs'
import { useDebounce } from '../../hooks'
import { validateEmail } from '../../helpers/validateEmail'
import { APIService } from '../../services'
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import { Box } from '../Box'

export const EmailField = () => {

    const {
        analysisType,
        email, setEmail,
        from,
        sessionID,
        activeTab, setActiveTab
    } = useContext(GlobalContext)

    const isTrip = analysisType === 'trip'

    const [loading, setLoading] = useState(false)
    const [validEmail, setValidEmail] = useState(true)

    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)

    const [inputEmail, setInputEmail] = useState('')
    const debouncedEmail = useDebounce(inputEmail, 250)

    useEffect(() => {
        setEmail(debouncedEmail)
    }, [debouncedEmail])

    const handleEmail = async () => {
        setShowError(false)
        const validEmail = validateEmail(email)

        if (validEmail) {
            setValidEmail(true)
            setLoading(true)

            try {
                await APIService.addEmailToSession(email, sessionID, isTrip, from)
                setLoading(false)
                setActiveTab(activeTab + 1)
            } catch (err) {
                setLoading(false)
                setError('Oops. Deine E-Mail konnte nicht hinzugefügt werden. Bitte probiere es einfach noch einmal.')
                setShowError(true)
            }

        } else {
            setValidEmail(false)
            setError('Deine E-Mail-Adresse ist leider ungültig')
            setShowError(true)
        }
    }

    return (
        <>
            {showError &&
                <Box type='error' style={{ marginTop: '1.5rem' }}>
                    {error}
                </Box>
            }

            <div className={[styles.wrapper, loading ? styles.loading : ''].join(' ')}>
                <TextInput
                    placeholder="E-Mail eingeben..."
                    onChange={(e) => setInputEmail(e.target.value)}
                    error={!validEmail}
                    style={{ flex: '1' }}
                >
                    <EmailRoundedIcon />
                </TextInput>
                <Button loading={loading} text='Senden' onClick={handleEmail}>
                    <SendRoundedIcon />
                </Button>
            </div>
        </>
    )
}
