import React, { createContext, useState, useEffect } from 'react'
import qs from 'querystring'
import { transformColor } from '../../helpers/colors'

/* URL Params for Customizations */
let params = '';

if (window.location.search) {
    try {
        params = atob(window.location.search.slice(1))
    } catch (err) {
        console.error('URL Params are invalid. Decoding failed. Custom Styles could not be applied.')
    }
}

const decodedParams = qs.decode(decodeURIComponent(params))

// const options = {
//     enableHighAccuracy: true,
//     timeout: 5000,
//     maximumAge: 0
// };

// const success = (pos) => {
//     console.log(pos)
// }

// const error = (err) => {
//     console.error(err)
// }

// navigator.geolocation.getCurrentPosition(success, error, options)

const initialOptions = {
    /* Colors */
    mainColor: decodedParams.mainColor || '#7367f0',
    mainColorLight: decodedParams.mainColorLight || '#958cf4',
    mainColorDark: decodedParams.mainColorDark || '#5547ed',

    /* Wrapper */
    verticalPadding: decodedParams.verticalPadding || '32px',
    horizontalPadding: decodedParams.horizontalPadding || '32px',

    /* Input */
    inputFontSize: decodedParams.inputFontSize || '1.1rem',
    inputBorderRadius: decodedParams.inputBorderRadius || '5px',
    inputVerticalPadding: decodedParams.inputVerticalPadding || '13px',
    inputHorizontalPadding: decodedParams.inputHorizontalPadding || '24px',

    /* Buttons */
    buttonRadius: decodedParams.buttonRadius || '5px',
}

document.documentElement.style.setProperty('--color-main', initialOptions.mainColor)
document.documentElement.style.setProperty('--color-main-light', initialOptions.mainColorLight)
document.documentElement.style.setProperty('--color-main-dark', initialOptions.mainColorDark)
document.documentElement.style.setProperty('--horizontal-padding', initialOptions.horizontalPadding)
document.documentElement.style.setProperty('--vertical-padding', initialOptions.verticalPadding)
document.documentElement.style.setProperty('--input-horizontal-padding', initialOptions.inputHorizontalPadding)
document.documentElement.style.setProperty('--input-vertical-padding', initialOptions.inputVerticalPadding)
document.documentElement.style.setProperty('--input-border-radius', initialOptions.inputBorderRadius)
document.documentElement.style.setProperty('--button-border-radius', initialOptions.buttonRadius)

/* Context */
export const ThemeContext = createContext(initialOptions)

/* Provider */
export const ThemeProvider = ({ children }) => {
    const [options, setOptions] = useState(initialOptions)

    useEffect(() => {
        document.documentElement.style.setProperty('--color-main', options.mainColor)
        document.documentElement.style.setProperty('--color-main-light', transformColor(options.mainColor, 35))
        document.documentElement.style.setProperty('--color-main-dark', transformColor(options.mainColor, -30))
        document.documentElement.style.setProperty('--horizontal-padding', options.horizontalPadding)
        document.documentElement.style.setProperty('--vertical-padding', options.verticalPadding)
        document.documentElement.style.setProperty('--input-horizontal-padding', options.inputHorizontalPadding)
        document.documentElement.style.setProperty('--input-vertical-padding', options.inputVerticalPadding)
        document.documentElement.style.setProperty('--input-border-radius', options.inputBorderRadius)
        document.documentElement.style.setProperty('--button-border-radius', options.buttonRadius)
    }, [options])

    return (
        <ThemeContext.Provider value={{
            options,
            setOptions
        }}>
            {children}
        </ThemeContext.Provider>
    )
}