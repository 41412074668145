import React, { useContext, useState, useRef, useEffect } from 'react'
import { GlobalContext } from '../../../context/Global';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import './_datepicker-override.scss';


export const DatePicker = () => {

    const {
        from, updateFrom,
        to, updateTo,
        setBodyHeight,
    } = useContext(GlobalContext)

    const datePicker = useRef(null)

    const [focusedInput, setFocusedInput] = useState(null)

    const handleDateChange = (startDate, endDate) => {
        updateFrom({ ...from, date: startDate })
        updateTo({ ...to, date: endDate })
    }

    useEffect(() => {
        if (focusedInput) {
            setTimeout(() => {
                setBodyHeight(datePicker.current.scrollHeight - 100)
            }, 10)
        } else {
            setBodyHeight(0)
        }

        return (() => setBodyHeight(0))
    }, [focusedInput])

    const matches = useMediaQuery('(max-width:684px)');

    return (
        <div ref={datePicker} className={`datepicker ${focusedInput ? 'active' : ''}`}>
            <DateRangePicker
                startDate={from.date} // momentPropTypes.momentObj or null,
                startDateId="dateFrom" // PropTypes.string.isRequired,
                endDate={to.date} // momentPropTypes.momentObj or null,
                endDateId="dateTo" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                firstDayOfWeek={1}
                startDatePlaceholderText="Abfahrt"
                endDatePlaceholderText="Rückfahrt"
                hideKeyboardShortcutsPanel={true}
                orientation={matches ? 'vertical' : 'horizontal'}
                numberOfMonths={matches ? 1 : 2}
                verticalHeight={matches ? 385 : null}
            />
        </div>
    )
}
