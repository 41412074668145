import React, { useContext } from 'react'
import styles from './backbutton.module.scss'
import { GlobalContext } from '../../../context/Global';

import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

export const BackButton = () => {

    const { activeTab, setActiveTab } = useContext(GlobalContext)

    const handleClick = () => {
        if (activeTab !== 0) {
            setActiveTab(activeTab - 1)
        }
    }

    return (
        <button className={styles.backbtn} onClick={handleClick}>
            <ChevronLeftRoundedIcon />
            <span>Zurück</span>
        </button>
    )
}
