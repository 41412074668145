import React, { useState, useContext } from 'react'
import { GlobalContext } from '../../context/Global'
import styles from './urlfield.module.scss'
import { Button } from '../Buttons'
import { TextInput } from '../Inputs'
import useOnclickOutside from 'react-cool-onclickoutside'
import LinkRoundedIcon from '@material-ui/icons/Link';
import ShareRoundedIcon from '@material-ui/icons/Share';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Box } from '../Box'

export const URLField = () => {

  const {
    tripID
  } = useContext(GlobalContext)

  const [copied, setCopied] = useState(false)
  const [showFallback, setShowFallback] = useState(false)

  const tripURL = `${process.env.REACT_APP_TRIP_URL}/${tripID}`

  const sharingText = "Hier der aktuelle Status meiner Reise!"

  const FacebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(tripURL)}`
  const TwitterURL = `http://twitter.com/intent/tweet?text=${encodeURIComponent(sharingText)}&url=${encodeURIComponent(tripURL)}`
  const WhatsAppURL = `https://wa.me/?text=${encodeURIComponent(sharingText)}%20${encodeURIComponent(tripURL)}`

  const ref = useOnclickOutside(() => {
    setShowFallback(false)
  });

  const copyToClipboard = async (e) => {
    if (e.target.tagName.toLowerCase() === 'input') {
      e.target.select()
    }

    try {
      await navigator.clipboard.writeText(tripURL)
      setCopied(true)
      setShowFallback(false)
    } catch (error) {
      console.error(error)
    }
  }

  const share = async () => {
    const shareData = {
      title: 'MeineReise',
      text: 'Hier der aktuelle Status meiner Reise!',
      url: tripURL,
    }

    try {
      await navigator.share(shareData)
      console.log('Shared successfully!')
    } catch (err) {
      setShowFallback(true)
      console.error('Sharing-Error: ' + err)
    }
  }

  return (
    <>
      <div className={styles.wrapper} style={copied ? { marginBottom: '1rem' } : {}}>
        <TextInput
          value={tripURL}
          style={{ flex: '1' }}
          onFocus={copyToClipboard}
          readOnly
        >
          <LinkRoundedIcon onClick={copyToClipboard} />
        </TextInput>
        <div className={styles.buttons}>
          <a href={tripURL} target="_blank" rel="noreferrer">
            <Button text='Zum Trip'>
              <OpenInNewRoundedIcon />
            </Button>
          </a>
          <Button onClick={share}>
            <ShareRoundedIcon />
          </Button>
          {showFallback && (
            <div ref={ref} className={styles.fallback}>
              <ul className={styles.socialIcons}>
                <li><a href={FacebookURL} target="_blank" rel="noreferrer"><FacebookIcon /></a></li>
                <li><a href={TwitterURL} target="_blank" rel="noreferrer"><TwitterIcon /></a></li>
                <li><a href={WhatsAppURL} data-action="share/whatsapp/share" target="_blank" rel="noreferrer"><WhatsAppIcon /></a></li>
                <li><span onClick={copyToClipboard}><LinkRoundedIcon /></span></li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {copied &&
        <Box type='success' style={{ padding: '1rem 2rem', marginBottom: '2.5rem' }}>
          Link kopiert
        </Box>
      }
    </>
  )
}
