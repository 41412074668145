import React from 'react'
import styles from './loader.module.scss'

export const Loader = (props) => {
  return (
    <>
      {props.variant ? (
        <div className={styles[props.variant]} style={props.style}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      ) : (
          <div className={styles.loader} style={props.style}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
    </>
  )
}
