import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './accordion.module.scss'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

const Accordion = ({ title, children, indicator, showArrow }) => {

  const [open, setOpen] = useState(false)

  return (
    <div className={styles.wrapper}>
      <div className={[
        styles.header,
        showArrow ? styles.hasArrow : '',
        open ? styles.headerOpen : ''
      ].join(' ')} onClick={() => setOpen(!open)}>
        <span className={styles.title}>{title}</span>
        {indicator && (
          <span className={[
            styles.indicator,
            indicator === 1 ? styles.green :
              indicator === 2 ? styles.yellow :
                indicator === 3 ? styles.red :
                  styles.undefined
          ].join(' ')}></span>
        )}
        {showArrow && (
          <span className={[styles.arrow, open ? styles.arrowUp : styles.arrowDown].join(' ')}>
            <KeyboardArrowDownRoundedIcon />
          </span>
        )}
      </div>
      <div className={[styles.contentWrapper, open ? styles.open : styles.closed].join(' ')}>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  )
}

Accordion.defaultProps = {
  showArrow: true
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  showArrow: PropTypes.bool,
  indicator: PropTypes.number
}

export {
  Accordion
}