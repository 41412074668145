import React, { createContext, useReducer } from 'react'
import qs from 'querystring'
import { ACTIONS } from './actions'
import GlobalReducer from './reducer'
import moment from 'moment'
import { NUTS } from '../../assets/NUTS3'
import 'moment/locale/de'
moment.locale('de')

/* URL Params for Customizations */
let params = '';

if (window.location.search) {
  try {
    params = atob(window.location.search.slice(1))
  } catch (err) {
    console.error('URL Params are invalid. Decoding failed. Custom Styles could not be applied.')
  }
}

const decodedParams = qs.decode(decodeURIComponent(params))

/* Initial State */
const initialState = {
  analysisType: decodedParams.analysisType || 'trip',

  zipData: NUTS,

  npgeoData: [],
  npgeoDataLoading: false,

  from: {
    placeID: decodedParams.placeIDFrom || '',
    zip: decodedParams.zipFrom || '',
    name: decodedParams.nameFrom || '',
    sublocality: decodedParams.sublocalityFrom || '',
    coordinates: decodedParams.coordinatesFromLat ? {
      lat: +decodedParams.coordinatesFromLat,
      lng: +decodedParams.coordinatesFromLng
    } : {},
    info: [],
    date: moment()
  },

  to: {
    placeID: decodedParams.placeIDTo || '',
    zip: decodedParams.zipTo || '',
    name: decodedParams.nameTo || '',
    sublocality: decodedParams.sublocalityTo || '',
    coordinates: decodedParams.coordinatesToLat ? {
      lat: +decodedParams.coordinatesToLat,
      lng: +decodedParams.coordinatesToLng
    } : {},
    info: [],
    date: moment().add(1, 'd')
  },

  filteredFrom: [],
  filteredTo: [],

  email: '',
  sessionID: '',
  tripID: '',

  bodyHeight: 0,
  activeTab: 0,
  errors: []
}

/* Context */
export const GlobalContext = createContext(initialState)

/* Provider */
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState)

  function setNPGEOData(data) {
    dispatch({
      type: ACTIONS.SET_NPGEO_DATA,
      payload: data
    })
  }

  function setAnalysisType(type) {
    dispatch({
      type: ACTIONS.SET_ANALYSIS_TYPE,
      payload: type
    })
  }

  function updateFrom(fromObj) {
    dispatch({
      type: ACTIONS.UPDATE_FROM,
      payload: fromObj
    })
  }

  function updateTo(toObj) {
    dispatch({
      type: ACTIONS.UPDATE_TO,
      payload: toObj
    })
  }

  function setFilteredFrom(arr) {
    dispatch({
      type: ACTIONS.SET_FILTERED_FROM,
      payload: arr
    })
  }

  function setFilteredTo(arr) {
    dispatch({
      type: ACTIONS.SET_FILTERED_TO,
      payload: arr
    })
  }

  function setEmail(email) {
    dispatch({
      type: ACTIONS.SET_EMAIL,
      payload: email
    })
  }

  function setSessionID(sessionID) {
    dispatch({
      type: ACTIONS.SET_SESSION_ID,
      payload: sessionID
    })
  }

  function setTripID(tripID) {
    dispatch({
      type: ACTIONS.SET_TRIP_ID,
      payload: tripID
    })
  }

  function setBodyHeight(height) {
    dispatch({
      type: ACTIONS.SET_BODY_HEIGHT,
      payload: height
    })
  }

  function setActiveTab(tabIndex) {
    dispatch({
      type: ACTIONS.SET_ACTIVE_TAB,
      payload: tabIndex
    })
  }

  function setErrors(err) {
    dispatch({
      type: ACTIONS.SET_ERRORS,
      payload: err
    })
  }

  return (
    <GlobalContext.Provider value={{
      analysisType: state.analysisType, setAnalysisType,

      zipData: state.zipData,

      npgeoData: state.npgeoData, setNPGEOData,
      npgeoDataLoading: state.npgeoDataLoading,

      from: state.from, updateFrom,
      to: state.to, updateTo,

      filteredFrom: state.filteredFrom, setFilteredFrom,
      filteredTo: state.filteredTo, setFilteredTo,

      email: state.email, setEmail,
      sessionID: state.sessionID, setSessionID,
      tripID: state.tripID, setTripID,

      bodyHeight: state.bodyHeight, setBodyHeight,
      activeTab: state.activeTab, setActiveTab,
      errors: state.errors, setErrors,
    }}>
      {children}
    </GlobalContext.Provider>
  )
}