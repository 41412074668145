import React from 'react';
import './App.scss';
import { initAnalytics } from './helpers/initAnalytics';
import { GlobalProvider, ThemeProvider } from './context/';
import { MainWrapper } from './components/MainWrapper';
import { Tabs } from './tabs';

initAnalytics()

function App() {
  return (
    <ThemeProvider>
      <GlobalProvider>
        <MainWrapper>
          <Tabs />
        </MainWrapper>
      </GlobalProvider>
    </ThemeProvider>
  );
}

export default App;