import React, { useContext, useState } from 'react'
import { GlobalContext } from '../../context/Global';
import styles from './geolocation.module.scss'

import { getGeocode } from 'use-places-autocomplete';
import MyLocationRoundedIcon from '@material-ui/icons/MyLocationRounded';


export const GeoLocation = (props) => {

  const {
    from, updateFrom,
    to, updateTo,
    setErrors
  } = useContext(GlobalContext)

  const [loading, setLoading] = useState(false)

  const success = async (position) => {
    const latlng = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }

    const params = {
      location: latlng
    }

    const resFromGeocode = await getGeocode(params)

    for (const item of resFromGeocode) {
      if (item.types.includes('postal_code')) {
        let name = item.formatted_address
        if (!isNaN(item.formatted_address.split(' ')[0])) {
          name = item.formatted_address.split(' ').slice(1).join(' ')
        }

        if (props.direction === 'from') {
          updateFrom({
            ...from,
            placeID: item.place_id,
            zip: item.address_components[0].short_name,
            name,
            coordinates: latlng
          })
        } else {
          updateTo({
            ...to,
            placeID: item.place_id,
            zip: item.address_components[0].short_name,
            name,
            coordinates: latlng
          })
        }
      }
    }

    setErrors([])
    setLoading(false)
  }

  function error(error) {
    const supportURL = 'https://support.google.com/maps/answer/2839911';
    const errors = {
      1: `Standort konnte nicht ermittelt werden. Zugriff wurde verweigert. Vergewissere Dich, dass dieser Browser <a href="${supportURL}" target="_blank" rel="noreferrer">Zugriff auf die Ortungsdienste</a> Deines Gerätes hat.`,
      2: `Standort konnte nicht ermittelt werden. Position nicht verfügbar. Vergewissere Dich, dass dieser Browser <a href="${supportURL}" target="_blank" rel="noreferrer">Zugriff auf die Ortungsdienste</a> Deines Gerätes hat.`,
      3: `Standort konnte nicht ermittelt werden. Zeitlimit für die Standortermittlung ist abgelaufen.`
    }
    setLoading(false)
    console.error('Error getting Position:', errors[error.code])
    setErrors([errors[error.code]])
  }

  const options = {
    enableHighAccuracy: true,
    timeout: 7 * 1000,
    maximumAge: 0
  }

  const getGeolocation = () => {
    if ('geolocation' in navigator) {
      setLoading(true)
      navigator.geolocation.getCurrentPosition(success, error, options)
    } else {
      console.error('No Geolocation available in Navigator')
      setErrors(['Standort konnte nicht ermittelt werden'])
    }
  }

  return (
    <div
      onClick={getGeolocation}
      className={[
        styles.icon,
        loading ? styles.loading : '',
        props.className
      ].join(' ')}>
      <MyLocationRoundedIcon />
    </div>
  )
}
