/**
 * @name APIService
 * ----------------
 * @desc This service deals with third party sources like the RKI API or our DB
 * @todo Cleanup
 */

import axios from 'axios'

export default class APIService {
  static addTrip = async (analysisType, from, to, filteredFrom, filteredTo, setSessionID, setTripID) => {
    return new Promise((resolve, reject) => {
      (async () => {
        const params = {
          apiKey: process.env.REACT_APP_API_KEY
        }

        let data = {}

        if (analysisType === 'trip') {
          data = {
            from: {
              address: {
                placeID: from.placeID,
                zip: from.zip,
                name: from.name,
                sublocality: from.sublocality,
                coordinates: from.coordinates
              },
              date: from.date.toISOString(),
              rki: filteredFrom[0].attributes
            },
            to: {
              address: {
                placeID: to.placeID,
                zip: to.zip,
                name: to.name,
                sublocality: to.sublocality,
                coordinates: to.coordinates
              },
              date: to.date.toISOString(),
              rki: filteredTo[0].attributes
            }
          }
        } else {
          data = {
            from: {
              address: {
                placeID: from.placeID,
                zip: from.zip,
                name: from.name,
                sublocality: from.sublocality,
                coordinates: from.coordinates
              },
              date: from.date.toISOString(),
              rki: filteredFrom[0].attributes
            }
          }
        }

        try {
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/trips`, data, { params: params, timeout: 5000 })
          setSessionID(res.data._id)
          setTripID(res.data.tripID)
          resolve(res)
        } catch (err) {
          console.log(err)
          reject(err)
        }
      })()
    })
  }

  static getPartnerInfo = async () => {
    return new Promise((resolve, reject) => {
      (async () => {
        const params = {
          apiKey: process.env.REACT_APP_API_KEY
        }

        try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/partners`, { params: params })
          resolve(res)
        } catch (err) {
          console.log(err)
          reject(err)
        }
      })()
    })
  }

  static getPartnerInfoById = async (addressRefID, state, county) => {
    return new Promise((resolve, reject) => {
      (async () => {
        const params = {
          apiKey: process.env.REACT_APP_API_KEY,
          addressRefID,
          state,
          county
        }

        try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/partners`, { params: params })
          resolve(res)
        } catch (err) {
          console.log(err)
          reject(err)
        }
      })()
    })
  }

  static addEmailToSession = async (email, sessionID, isTrip, from) => {
    return new Promise((resolve, reject) => {
      (async () => {
        const params = {
          apiKey: process.env.REACT_APP_API_KEY
        }
        let data = {
          email: {
            address: email
          }
        }

        if (!isTrip) {
          data = {
            ...data,
            from: {
              date: from.date.add(30, 'd').toISOString()
            }
          }
        }

        try {
          const res = await axios.patch(`${process.env.REACT_APP_API_URL}/trips/${sessionID}`,
            data,
            {
              params: params,
              timeout: 5000
            })
          resolve(res)
        } catch (err) {
          console.log(err)
          reject(err)
        }
      })()
    })
  }

  static addRatingToSession = async (sessionID, rating) => {
    return new Promise((resolve, reject) => {
      (async () => {
        const params = {
          apiKey: process.env.REACT_APP_API_KEY
        }
        const data = {
          feedback: {
            stars: rating || -1
          }
        }

        try {
          const res = await axios.patch(`${process.env.REACT_APP_API_URL}/trips/${sessionID}`, data, { params: params })
          resolve(res)
        } catch (err) {
          console.log(err)
          reject(err)
        }
      })()
    })
  }

  static getRKIData = async () => {
    return new Promise((resolve, reject) => {
      (async () => {
        const NPGEO_API_URL = 'https://services7.arcgis.com/mOBPykOjAyBO2ZKk/arcgis/rest/services/RKI_Landkreisdaten/FeatureServer/0/query?where=1%3D1&outFields=*&outSR=4326&returnGeometry=false&f=json'
        try {
          const res = await axios.get(NPGEO_API_URL, { timeout: 5000 })
          let fixedRes = []
          res.data.features.forEach(item => {
            if (!item.attributes.NUTS) {
              item.attributes.NUTS = 'DE300'
            }

            fixedRes.push(item)
          })
          resolve(fixedRes)
        } catch (err) {
          console.log(err)
          reject(err)
        }
      })()
    })
  }
}