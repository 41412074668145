import React from 'react'
import styles from './button.module.scss'
import { Loader } from '../../Loader'

export const Button = (props) => {

  return (
    <button
      className={[
        styles.button,
        props.children ? styles.hasIcon : '',
        props.loading ? styles.loading : '',
        props.text?.length > 0 ? styles.hasText : ''
      ].join(' ')}
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled || props.loading}
      title={props.title}
    >
      <span>
        {props.children}
        {props.text}
      </span>
      {props.loading ?
        <div className={styles.loader}>
          <Loader />
        </div>
        : ''}
    </button>
  )
}