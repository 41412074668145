import React, { useContext } from 'react'
import { GlobalContext } from '../../../context/Global';

import { Button } from '../Button';
import moment from 'moment';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';

export const ResetButton = (props) => {

  const {
    setAnalysisType,
    updateFrom,
    updateTo,
    setEmail,
    setSessionID,
    setTripID,
    setActiveTab,
    setFilteredFrom,
    setFilteredTo,
  } = useContext(GlobalContext)

  const handleClick = () => {
    setEmail('')
    setSessionID('')
    setTripID('')
    setActiveTab(0)
    setAnalysisType('trip')
    updateFrom({
      placeID: '',
      zip: '',
      name: '',
      sublocality: '',
      coordinates: {},
      date: moment(),
    })
    updateTo({
      placeID: '',
      zip: '',
      name: '',
      sublocality: '',
      coordinates: {},
      date: moment().add(1, 'd'),
    })
    setFilteredFrom([])
    setFilteredTo([])
  }

  return (
    <Button text='Neue Suche' style={props.style} onClick={handleClick}>
      <ReplayRoundedIcon />
    </Button>
  )
}
