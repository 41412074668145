import React, { useContext, useState } from 'react'
import { GlobalContext } from '../../context/Global'
import styles from './tabresult.module.scss'

import { Errors } from '../../components/Errors'
import { ResultBox } from '../../components/ResultBox'
import { BackButton, ResetButton } from '../../components/Buttons'

import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { EmailField } from '../../components/EmailField'
import { URLField } from '../../components/URLField'
import { Legend } from '../../components/Legend'
import { PartnerInfo } from '../../components/PartnerInfo'
import { TourismInfo } from '../../components/TourismInfo'

export const TabResult = () => {

  const {
    analysisType,
    from,
    to,
    filteredFrom,
    filteredTo,
    tripID
  } = useContext(GlobalContext)

  const isTrip = analysisType === 'trip'
  const tripURL = `${process.env.REACT_APP_TRIP_URL}/${tripID}`

  const [showInstructions, setShowInstructions] = useState(false)

  return (
    <>
      <BackButton />
      <h2>
        {isTrip ? 'Dein aktueller Reisestatus' : 'Aktueller Status'}
      </h2>

      <Errors />

      <Legend />

      <div className={styles.wrapper}>
        <div className={styles.result}>
          {filteredFrom.map((location, i) =>
            <ResultBox key={i} data={{ ...location.attributes, location: from }} />
          )}
        </div>
        {isTrip ?
          <>
            <div className={styles.arrowTo}>
              <ArrowForwardRoundedIcon />
            </div>
            <div className={styles.result}>
              {filteredTo.map((location, i) =>
                <ResultBox key={i} data={{ ...location.attributes, location: to }} />
              )}
            </div>
          </>
          : ''}
      </div>

      <span className={styles.copyright}>
        &copy; {new Date().getFullYear()} – Die Daten basieren auf den Fallzahlen des <a href="https://www.rki.de/" target="_blank" rel="noreferrer">RKI</a> und werden mithilfe der Schnittstellen von <a href="https://www.esri.de/" target="_blank" rel="noreferrer">ESRI</a> genutzt
            </span>

      <PartnerInfo />

      <h3>Einschränkungen &amp; Lockerungen</h3>
      <p>Diese Daten entnehmen wir dem <a href="https://tourismus-wegweiser.de/" target="_blank" rel="noreferrer">Infoangebot</a> des Kompetenzzentrums Tourismus des Bundes.</p>
      <TourismInfo />

      <h3>Link zum Trip</h3>
      <p>Der folgende Link beinhaltet Deinen Trip mit immer aktuellen Informationen. Du kannst sowohl den Reisecheck als auch Deinen Trip auch als <span className={styles.inlineButton} onClick={() => setShowInstructions(!showInstructions)}>PWA</span> (für Anleitung klicken) installieren.
        {showInstructions && (
          <div className={styles.instructions} style={{ marginTop: '0.5rem' }}>
            <p style={{ marginBottom: '0.35rem' }}>Öffne zunächst den Link <a href={tripURL} target="_blank" rel="noreferrer"><b>Zum Trip</b></a>:</p>
            <p><b>iOS</b>: <span className={[styles.inlineIcon, styles.rotatedIcon].join(' ')}><ExitToAppIcon /></span> <b>&rarr;</b> Zum Home-Bildschirm <span className={styles.inlineIcon}><AddBoxOutlinedIcon /></span></p>
            <p><b>Android</b>: PopUp bestätigen</p>
            <p><b>Mac/PC</b>: <span className={styles.inlineIcon}><AddCircleOutlineRoundedIcon /></span> Symbol neben der Adressleiste</p>
            <br />
            <p><b>Info</b>:<br />PWAs werden nicht von allen Browsern und Endgeräten unterstützt. Sollte Dein Gerät oder Dein Browser PWAs nicht unterstützen kannst Du Deinen <a href={tripURL} target="_blank" rel="noreferrer">Trip-Link</a> alternativ auch einfach als Favorit speichern.</p>
          </div>
        )}
      </p>
      <URLField />

      <h3>{isTrip ? 'Updates zu Deiner Reise erhalten?' : 'Updates erhalten'}</h3>
      <p>Du möchtest über Änderungen auf dem Laufenden gehalten werden? Dann kannst Du hier jetzt Deine E-Mail hinterlegen, und wir informieren Dich, sobald es zu relevanten Änderungen kommt!</p>
      <EmailField />

      <div style={{ margin: '2rem auto', display: 'flex' }}>
        <ResetButton style={{ flex: '1' }} />
      </div>
    </>
  )
}
