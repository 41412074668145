import ReactGA from 'react-ga'

export const initAnalytics = () => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);

    if (window.location) {

        let referrer = undefined;

        if (document.referrer) {
            referrer = encodeURIComponent(document.referrer)
        }

        if (window.location.ancestorOrigins) {
            referrer = encodeURIComponent(window.location.ancestorOrigins[0])
        }

        if (referrer !== undefined) {
            ReactGA.pageview(`${window.location.href}?referrer=${referrer}`)
        } else {
            ReactGA.pageview(window.location.href)
        }
    }
};