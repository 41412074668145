import React from 'react'
import styles from './switchbutton.module.scss'
import SwapHorizRoundedIcon from '@material-ui/icons/SwapHorizRounded';

export const SwitchButton = (props) => {

    return (
        <button
            disabled={props.disabled}
            className={styles.button}
            onClick={props.onClick}
        >
            <SwapHorizRoundedIcon />
        </button>
    )
}