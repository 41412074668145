import React, { useContext } from 'react'
import { GlobalContext } from '../../context/Global'
import styles from './partnerinfo.module.scss'

export const PartnerInfo = () => {

    const {
        analysisType,
        from,
        to,
        filteredFrom,
        filteredTo
    } = useContext(GlobalContext)

    const isTrip = analysisType === 'trip'
    const fromCases = filteredFrom[0]?.attributes.cases7_per_100k
    const toCases = filteredTo[0]?.attributes.cases7_per_100k

    return (
        (from.info.length > 0 || (to.info.length > 0 && isTrip)) && (
            <div className={styles.wrapper}>
                <h3 className={styles.title}>Weitere Informationen</h3>

                {from.info.length > 0 && (
                    <div className={styles.info}>
                        {isTrip && (
                            <h4 className={styles.infolocation}>
                                {from.name.split(',')[0]}:
                            </h4>
                        )}
                        {from.info.map(infos => (
                            infos.map((info, i) => {
                                if ((fromCases >= info.from) && (fromCases < info.to)) {
                                    return (
                                        <p className={styles.singleinfo} key={i}>
                                            {info.message}
                                        </p>
                                    )
                                }
                                return null
                            })
                        ))}
                    </div>
                )}

                {isTrip && to.info.length > 0 && (
                    <div className={styles.info}>
                        <h4 className={styles.infolocation}>
                            {to.name.split(',')[0]}:
                        </h4>
                        {to.info.map(infos => (
                            infos.map((info, i) => {
                                if ((toCases >= info.from) && (toCases < info.to)) {
                                    return (
                                        <p className={styles.singleinfo} key={i}>
                                            {info.message}
                                        </p>
                                    )
                                }
                                return null
                            })
                        ))}
                    </div>
                )}
            </div>
        )
    )
}
