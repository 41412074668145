import React from 'react'
import styles from './tourisminfo.module.scss'
import { Accordion } from '../Accordion'

export const Info = ({ data }) => {
  return (
    data.map((state, i) => {
      return (
        <div key={i} className={styles.state}>
          <Accordion title={state["Bundesland"]}>
            <div className={styles.infoContent}>
              <div className={styles.overview}>
                <Accordion title="Überblick">
                  <div
                    className={styles.overviewContent}
                    dangerouslySetInnerHTML={{ __html: state["Überblick"] }}
                  ></div>
                </Accordion>
              </div>
              <div className={styles.restrictions}>
                <Accordion title="Regionale Einschränkungen">
                  <div
                    className={styles.restrictionsContent}
                    dangerouslySetInnerHTML={{ __html: state["Regionale Einschränkungen"] }}
                  ></div>
                </Accordion>
              </div>

              <div className={styles.tourismInfos}>

                {Object.keys(state.allgemein).map((offer, i) => {
                  return (
                    <div key={i} className={styles.tourismInfo}>
                      <Accordion title={offer} indicator={+state.allgemein[offer].ampel} showArrow={false}>
                        <div className={styles.tourismInfoContent}>
                          <div>
                            <span className={styles.tourismInfoTitle}>Information</span>
                            <div dangerouslySetInnerHTML={{ __html: state.allgemein[offer].text }}></div>
                            <span className={[
                              styles.indicator,
                              +state.allgemein[offer].ampel === 1 ? styles.green :
                                +state.allgemein[offer].ampel === 2 ? styles.yellow :
                                  +state.allgemein[offer].ampel === 3 ? styles.red :
                                    styles.undefined
                            ].join(' ')}>
                            </span>
                          </div>
                        </div>
                      </Accordion>
                    </div>
                  )
                })}

                {state.tourismus.map((offer, i) => {
                  const offerCategory = Object.keys(offer)[0]
                  return (
                    <div key={i} className={styles.tourismInfo}>
                      <Accordion title={offerCategory} indicator={+offer[offerCategory]["Öffnung und Zugang"].ampel} showArrow={false}>
                        <div className={styles.tourismInfoContent}>
                          <div>
                            <span className={styles.tourismInfoTitle}>Öffnung und Zugang</span>
                            <div dangerouslySetInnerHTML={{ __html: offer[offerCategory]["Öffnung und Zugang"].text }}></div>
                            <span className={[
                              styles.indicator,
                              +offer[offerCategory]["Öffnung und Zugang"].ampel === 1 ? styles.green :
                                +offer[offerCategory]["Öffnung und Zugang"].ampel === 2 ? styles.yellow :
                                  +offer[offerCategory]["Öffnung und Zugang"].ampel === 3 ? styles.red :
                                    styles.undefined
                            ].join(' ')}>
                            </span>
                          </div>
                          <div>
                            <span className={styles.tourismInfoTitle}>Aufenthalt und Hygiene</span>
                            <div dangerouslySetInnerHTML={{ __html: offer[offerCategory]["Aufenthalt und Hygiene"].text }}></div>
                            <span className={[
                              styles.indicator,
                              +offer[offerCategory]["Aufenthalt und Hygiene"].ampel === 1 ? styles.green :
                                +offer[offerCategory]["Aufenthalt und Hygiene"].ampel === 2 ? styles.yellow :
                                  +offer[offerCategory]["Aufenthalt und Hygiene"].ampel === 3 ? styles.red :
                                    styles.undefined
                            ].join(' ')}></span>
                          </div>
                        </div>
                        {offer[offerCategory]["Weitere Informationen"].length > 0 && (
                          <div className={styles.additionalInfo}>
                            <span className={styles.additionalTitle}>Weitere Informationen</span>
                            <div dangerouslySetInnerHTML={{ __html: offer[offerCategory]["Weitere Informationen"] }}></div>
                          </div>
                        )}
                      </Accordion>
                    </div>
                  )
                })}

              </div>
            </div>
          </Accordion>
        </div>
      )
    })
  )
}
