import React from 'react'
import generalstyles from '../input.module.scss'
// import styles from './textinput.module.scss'

export const TextInput = (props) => {

  return (
    <div style={props.style} className={[generalstyles.inputwrapper, props.error ? generalstyles.error : '', props.children ? generalstyles.hasIcon : ''].join(' ')}>
      <input
        className={generalstyles.input}
        type="text"
        disabled={props.disabled}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onFocus={props.onFocus}
        value={props.value}
      />
      {props.children}
    </div>
  )
}