import React from 'react'
import styles from './box.module.scss'

export const Box = (props) => {

    return (
        <>
            {props.html ?
                <div dangerouslySetInnerHTML={{ __html: props.html }} className={styles[props.type]} style={props.style}></div>
                :
                props.type ?
                    <div className={styles[props.type]} style={props.style}>
                        {props.children}
                    </div>
                    :
                    <div className={styles.box} style={props.style}>
                        {props.children}
                    </div>
            }
        </>
    )
}
