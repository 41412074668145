import React, { useContext } from 'react'
import { GlobalContext } from '../context/Global'
import { TabInputs } from './TabInputs'
import { TabResult } from './TabResult'
import { TabFeedback } from './TabFeedback'

export const Tabs = () => {

    const { activeTab } = useContext(GlobalContext)

    const tabs = [
        <TabInputs key={0} />,
        <TabResult key={1} />,
        <TabFeedback key={2} />,
    ]

    return (
        <div>
            {tabs.map((tab, i) => {
                if (i === activeTab) {
                    return tab
                }
                return false;
            })}
        </div>
    )
}
