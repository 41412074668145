import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/Global'
import styles from './legend.module.scss'

export const Legend = () => {

    const {
        analysisType,
        filteredFrom,
        filteredTo
    } = useContext(GlobalContext)

    const isTrip = analysisType === 'trip'

    const dataFrom = filteredFrom[0].attributes
    const incidenceFrom = dataFrom.cases7_per_100k

    const dataTo = filteredTo[0]?.attributes
    const incidenceTo = dataTo?.cases7_per_100k

    const [activeBars, setActiveBars] = useState([])

    useEffect(() => {
        let activeBars = []
        if (incidenceFrom < 35 || (incidenceTo < 35 && isTrip)) {
            activeBars.push('green')
        }
        if ((incidenceFrom >= 35 && incidenceFrom < 50) || (incidenceTo >= 35 && incidenceTo < 50 && isTrip)) {
            activeBars.push('yellow')
        }
        if ((incidenceFrom >= 50 && incidenceFrom < 100) || (incidenceTo >= 50 && incidenceTo < 100 && isTrip)) {
            activeBars.push('red')
        }
        if (incidenceFrom > 100 || (incidenceTo > 100 && isTrip)) {
            activeBars.push('darkred')
        }
        setActiveBars(activeBars)
    }, [filteredFrom, filteredTo])

    return (
        <div className={styles.wrapper} style={{ padding: isTrip ? '1rem 0' : '1rem 0 0' }}>
            <div className={styles.until100}>
                <span className={[styles.bar, styles.green, activeBars.includes('green') ? styles.active : ''].join(' ')}>0 - 35</span>
                <span className={[styles.bar, styles.yellow, activeBars.includes('yellow') ? styles.active : ''].join(' ')}>35 - 50</span>
                <span className={[styles.bar, styles.red, activeBars.includes('red') ? styles.active : ''].join(' ')}>50 - 100</span>
                {incidenceFrom < 100 &&
                    <>
                        <div className={styles.indicator} style={{
                            left: `${incidenceFrom}%`
                        }}></div>
                        <div className={styles.countyFrom} style={{
                            left: `${incidenceFrom}%`
                        }}>{dataFrom.county}</div>
                    </>
                }
                {incidenceTo < 100 && isTrip &&
                    <>
                        <div className={styles.indicator} style={{
                            left: `${incidenceTo}%`
                        }}></div>
                        <div className={styles.countyTo} style={{
                            left: `${incidenceTo}%`
                        }}>{dataTo.county}</div>
                    </>
                }
            </div>

            <div className={styles.above100}>
                <span className={[styles.bar, styles.darkred, activeBars.includes('darkred') ? styles.active : ''].join(' ')}>100+</span>
                {incidenceFrom >= 100 &&
                    <div className={styles.countyFrom} style={{
                        left: '50%'
                    }}>{dataFrom.county}</div>
                }
                {incidenceTo >= 100 && isTrip &&
                    <div className={styles.countyTo} style={{
                        left: '50%'
                    }}>{dataTo.county}</div>
                }
            </div>

        </div>
    )
}
