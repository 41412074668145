import React, { useContext } from 'react'
import styles from './tabs.module.scss'

import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';
import MyLocationRoundedIcon from '@material-ui/icons/MyLocationRounded';
import { GlobalContext } from '../../context/Global';

export const Tabs = () => {

  const { analysisType, setAnalysisType } = useContext(GlobalContext)

  return (
    <div className={styles.tabs}>
      <div className={[styles.tab, analysisType === 'trip' ? styles.active : ''].join(' ')} onClick={() => setAnalysisType('trip')}>
        <ExploreRoundedIcon />
        <span>Reise</span>
      </div>
      <div className={[styles.tab, analysisType === 'live' ? styles.active : ''].join(' ')} onClick={() => setAnalysisType('live')}>
        <MyLocationRoundedIcon />
        <span>Standort</span>
      </div>
    </div>
  )
}
