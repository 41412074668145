import React, { useContext } from 'react'
import { GlobalContext } from '../../context/Global'
import styles from './tabfeedback.module.scss'
import { BackButton } from '../../components/Buttons'
import { StarRating } from '../../components/StarRating'
import { ResetButton } from '../../components/Buttons'

export const TabFeedback = () => {

    const {
        analysisType,
        email
    } = useContext(GlobalContext)

    const isTrip = analysisType === 'trip'

    return (
        <>
            <BackButton />
            <div className={styles.infotext}>
                <h2>Fast fertig!</h2>
                <p>{isTrip ? 'Deine Reise' : 'Dein Standort'} ist nun bei uns hinterlegt. Bevor wir Dich jedoch informieren dürfen, müsstest Du Deine E-Mail-Adresse ({email}) vorher bestätigen. Du solltest hierfür in Kürze eine Bestätigunsmail von uns erhalten!</p>
            </div>

            <div className={styles.feedback}>
                <h3>Gib uns Dein Feedback</h3>
                <StarRating />
            </div>

            <div className={styles.reset}>
                <ResetButton />
            </div>
        </>
    )
}
